import React, { useEffect, useState } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { Close, AccessTime } from '@mui/icons-material'
import api from '../../plugins/api'
import * as Sentry from '@sentry/react'

const NotificationBar = () => {
  const [messages, setMessages] = useState([])

  useEffect(() => {
    const fetchMessages = () => {
      api
        .get(`/v1/notification`)
        .then((response) => {
          if (Array.isArray(response.data.notifications))
            setMessages(response.data.notifications)
        })
        .catch((error) => {
          Sentry.captureException(error)
        })
    }

    fetchMessages()
  }, [])

  const handleRemoveMessage = (index) => {
    setMessages((prevMessages) => prevMessages.filter((_, i) => i !== index))
  }

  return (
    <Box
      id="notification-container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.2rem'
      }}
    >
      {messages.map((notification, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.1rem 0.5em',
            backgroundColor: '#cdd716'
          }}
        >
          <AccessTime fontSize="small" sx={{ color: '#000' }} />
          <Typography sx={{ color: '#000', fontSize: '0.9em' }}>
            {notification.message}
          </Typography>
          <IconButton onClick={() => handleRemoveMessage(index)}>
            <Close fontSize="small" sx={{ color: '#000' }} />
          </IconButton>
        </Box>
      ))}
    </Box>
  )
}

export default NotificationBar
