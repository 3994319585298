import React, { useState, useEffect, useRef } from "react";
import assistantsBanner from "./images/assistants.png";
import "./AiAssistant.css";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Input,
  TextArea,
  Select,
  Confirm,
  Checkbox,
} from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";
import { FaEdit } from "react-icons/fa";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { TbWorld } from "react-icons/tb";
import companyLogo from "./images/TD SYNNEX_Logo_Aqua-White.png";
import { useMsal } from "@azure/msal-react"
import { toast } from "react-toastify"

import {
  Checkbox as CheckboxMU,
  Typography,
  FormControlLabel,
  Divider,
  Box,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material"

import Markdown from "react-markdown";
import { AiAssistantKnowledgeManager } from "./AiAssistantKnowledgeManager";
import { SharepointDataIngester } from "./SharepointDataIngester";
import { SharePointFileBrowser } from "./SharepointFileBrowser";
import ExternalAccessSection from "./AiAssistant/ExternalAccessSection";
import useStore from "../hooks/useStore";

const steps = [
  "Define prompts & model",
  "Choose tools & upload knowledge",
  "Define conversation Starters",
  "Share & publish",
];

const commandsJsonData = {
  commands: [
    {
      name: "/import",
      description: `
**Opens a pop-up to import long-term knowledge (PDF, PPTX, DOCX, CSV, TXT or code files).** 
- The Subject is required and it should describe the file's content. 
- Learn More Links is optional. Inform a URL where any user can access the original file, such as a Sharepoint link or a web page.
`,
      parameters: [
        {
          name: "File",
          type: "file",
          description: "The file to import",
          required: true,
        },

        {
          name: "Subject",
          type: "textarea",
          description: "What is this file about?",
          maxSize: 300,
          required: true,
        },
        {
          name: "LearnMoreLinks",
          type: "string",
          description: "Links to get more information",
          required: false,
        },
        // {
        //   "name": "assistantId",
        //   "type": "hidden",
        //   "description": "The code should update the value key with the assistantId",
        //   "required": false,
        //   value: "assistantId placeholder"
        // }
      ],
    },
    {
      name: "/import-website",
      description:
        "Opens a pop-up to import long-term knowledge from a website's URL",
      parameters: [
        {
          name: "URL",
          type: "string",
          description: "Website's URL",
          required: true,
        },
        {
          name: "Subject",
          type: "string",
          maxSize: 300,
          description: "What is this file about?",
          required: true,
        },
      ],
    },
  ],
};

const models = [
  "gpt-4o",
  // 'gpt-4-turbo',
  "gpt-4o-mini",
  "o3-mini",
  "gemini-1.5-pro",
  "gemini-2.0-flash",
  "gemini-2.0-flash-lite",
  "gemini-2.5-pro (experimental)",
  "default",
];

const tools = [
  "get_knowledge",
  "generatePowerPoint",
  "fetch_website_content",
  "create_image",
  "createMemory",
  "websearch",
  "speak",
  "calculate",
  "generateAndExecutePythonCode",
  "switchToAiAssistant",
  "askFinancialIq",
  "queryKenshoVector",
  "queryKenshoVectorHelp",
];

const assistantsEditorWelcomeMessage = (appName) => (
  `
# Welcome to the AI Assistants Editor
Have you seen the blue dropdown list of AI assistants in the main ${appName} window? As a user, you are empowered to create and edit more AI Assistants just like those. Everything is data-driven! An AI Assistant is a conversational agent that can be used to answer questions, provide information, and interact with users in a conversational manner, and you are in control of creating and editing them, right here, in this tool. Just click on Add or edit one of the assitants on the left. You cannot edit assistants created by other people, but you can save them as new assistants and edit them as you wish.
You can also share your AI Assistant with other users, and you can publish it to the ${appName} marketplace.
## Tools and Knowledge
Assistants created in ${appName} have access to TD SYNNEX tools, like accessing knowledge, fetching data from websites, creating Power Point presentations with the TD SYNNEX template, based on the conversation, or creating images. We are also adding a tool that will allow the AI to memorize something when the user asks. As we expand those tools, the possibilities with new assistants will grow to integrate them with our systems and data.

Knowledge is the data that the AI Assistant uses to answer questions. You can upload knowledge files, and you can also fetch knowledge from the internet. The knowledge is organized by assistant, so if you have a new assistant, you will need to upload or fetch the knowledge that the assistant will use to answer questions. The knowledge you upload is stored in a special database that is optimized for natural language searches. You can upload files in PDF, PPTX, DOCX, CSV, TXT, or code files, and this list will keep growing. You are responsible, as a user, for making sure the knowledge you upload is accurate and up-to-date. As you share you assistant with other users, they will be able to use the knowledge you uploaded, so be mindful of the information you share and use the right type of sharing method accordingly.
### "Save as" feature vs Knowledge
The "Save As" feature will not carry the knowledge over to the new assistant. You will need to upload the knowledge again. This is a security feature to prevent sensitive information from being shared with the wrong people.
## Conversation Starters
Conversation starters are the initial prompts that the AI Assistant will use to start a conversation with the user. You can define as many conversation starters as you want, and you can also define the model that will be used to generate the response.
## Sharing and Publishing
You can share your AI Assistant with other users by adding their AAD group ID or their user principal name (UPN). You can also publish your AI Assistant to the ${appName} marketplace, so that other users can subscribe to it as needed.
## Marketplace
The ${appName} marketplace is a place where users can find and subscribe to AI Assistants created by other users. The AI assistants shared via the marketplace are visible to all co-workers at TD SYNNEX, so don't use this feature if you need to control access to the knowledge shared through the assistant.
## Global assistants
Some assistants are flagged as global. This means that all ${appName} users can use them, and they cannot be deleted.
## Using the assistants
Just select it from the blue dropdown in the main ${appName} window and start using it.
## Next Step
Select an assistant from the list to edit it, or click the "Add" button to create a new assistant.`,
  `
# Streamline Your AI Experience with ${appName}

Unlock the power to craft and tailor AI Assistants in ${appName}'s main window. Simply select "Add" or modify an existing assistant from the left panel. While you can't alter others' creations, you have the flexibility to clone and customize them to suit your needs. Share your unique AI Assistant with peers or showcase it in the ${appName} marketplace.

## Advanced Tools at Your Fingertips
Leverage ${appName}'s suite of TD SYNNEX tools to enhance your AI Assistants. Tap into a wealth of knowledge, pull data from the web, generate branded presentations, and create vivid images. With upcoming features to improve memory functions, your assistants will integrate seamlessly with our evolving systems and databases.

## Knowledge Management Made Easy
Infuse your AI Assistants with the intelligence they need by uploading relevant knowledge files or sourcing directly from the internet. Our specialized database ensures your content is primed for natural language processing. Keep your uploads precise and current—your shared knowledge empowers other users.

### Separate Knowledge for Security
For security, the "Save As" function requires you to re-upload knowledge to your new assistant, safeguarding sensitive information.

## Custom Conversations
Design engaging conversation starters and select the best response model to deliver dynamic interactions.

## Seamless Sharing and Visibility
Distribute your AI Assistant among colleagues using their AAD group ID or UPN, or publish it for broader access on the ${appName} marketplace. Remember, marketplace visibility extends to all TD SYNNEX co-workers, so choose wisely based on your privacy needs.

## Global Reach
Global assistants are accessible to every ${appName} user and are a permanent offering.

## Interactive Access
Activate an assistant from the blue dropdown menu in ${appName} and begin the interaction.

## Get Started
To edit or craft a new AI Assistant, pick one from the list or hit "Add."
`
);

// function UploadFileForm({onFileSubmit, onCancel}) {
//   // include a subject field and a learnMoreLinks field
//   const [subject, setSubject] = useState('');
//   const [learnMoreLinks, setLearnMoreLinks] = useState('');
//   const [file, setFile] = useState(null);

//   return (
//     <div className="overlay" tabIndex="-1">
//       <div className="modal-popup">
//         <Form onSubmit={onFileSubmit}>
//         <Form.Field
//             control={Input}
//             type="file"
//             label="File"
//             onChange={(e) => setFile(e.target.files[0])}
//           />
//           <Form.Field
//             control={Input}
//             label="Subject"
//             value={subject}
//             onChange={(e) => setSubject(e.target.value)}
//           />
//           <Form.Field
//             control={Input}
//             label="Learn More Links"
//             value={learnMoreLinks}
//             onChange={(e) => setLearnMoreLinks(e.target.value)}
//           />
//           <Button primary type="submit">Upload</Button>
//           <Button type="button" onClick={onCancel} color="brown">Cancel</Button>
//         </Form>
//       </div>
//     </div>
//   );
// }

// _______ _______ _______________________________________ _      ________________    _      _________________________
// (  ___  (  ____ (  ____ \__   __(  ____ \__   __(  ___  ( (    /\__   __(  ____ \  ( \     \__   __(  ____ \__   __/
// | (   ) | (    \| (    \/  ) (  | (    \/  ) (  | (   ) |  \  ( |  ) (  | (    \/  | (        ) (  | (    \/  ) (
// | (___) | (_____| (_____   | |  | (_____   | |  | (___) |   \ | |  | |  | (_____   | |        | |  | (_____   | |
// |  ___  (_____  (_____  )  | |  (_____  )  | |  |  ___  | (\ \) |  | |  (_____  )  | |        | |  (_____  )  | |
// | (   ) |     ) |     ) |  | |        ) |  | |  | (   ) | | \   |  | |        ) |  | |        | |        ) |  | |
// | )   ( /\____) /\____) ___) (__/\____) |  | |  | )   ( | )  \  |  | |  /\____) |  | (____/___) (__/\____) |  | |
// |/     \\_______\_______\_______\_______)  )_(  |/     \|/    )_)  )_(  \_______)  (_______\_______\_______)  )_(

// ascii art created in https://www.patorjk.com/software/taag/#p=testall&h=3&f=Graffiti&t=Assistants%20List

export function AiAssistant({
  aiAssistantsList,
  activeAiAssistant,
  socket,
  onClose,
  onChangeAssistant,
  onClickUploadButton,
  userRoles,

  filesAndFolderChain,
  sharepointTenantName,
  sharepointSitePath,
  onBrowseSharepoint,
  onFileAction,
  onSaveSpIngestionPlan,
  onOpenSpIngestionPlan,
  onGetSavedSpIngestionPlans,
  ingesterPlan,
  ingesterPlansList,
}) {
  const [aiAssistants, setAiAssistants] = useState(aiAssistantsList);
  const [showEditAssistant, setShowEditAssistant] = useState(false);
  const [selectedAssistant, setSelectedAssistant] = useState(activeAiAssistant);
  // const [commandJSON, setCommandJSON] = useState("/import");
  // const [showCommandsPopup, setShowCommandsPopup] = useState(false);
  const [anyUnsavedChanges, setAnyUnsavedChanges] = useState(false);
  const [openConfirmDiscardChangesPopup, setOpenConfirmDiscardChangesPopup] =
    useState(false);
  const [
    nextActionAfterDiscardingChanges,
    setNextActionAfterDiscardingChanges,
  ] = useState({ nextAction: "none", data: null });
  const [ingesterPlanFiles, setIngesterPlanFiles] = useState([]);
  const appName = useStore((state) => state.appName);

  useEffect(() => {
    setAiAssistants(aiAssistantsList);
  }, [aiAssistantsList]);

  useEffect(() => {
    socket.on("ingester-plan-files", ({ filesMetadata }) => {
      const filesList = filesMetadata.map((file) => {
        if (file.needsUpdate !== "deleted") {
          return {
            id: file.id,
            folderPath: file.folderPath,
            webUrl: file.webUrl,
            filename: file.name,
            subject: file.subject ? file.subject : "",
            lastModified: `${file.lastModifiedDateTime} by ${file.lastModifiedBy.user.displayName}`,
            lastModifiedDateTime: file.lastModifiedDateTime,
            needsUpdate: file.needsUpdate,
            size: formatFileSize(file.size),
            parentReference: file.parentReference,
            actions: "",
          };
        } else {
          // deleted
          return {
            id: file.id,
            filename: file.originalFilename,
            subject: file.subject ? file.subject : "",
            needsUpdate: file.needsUpdate,
          };
        }
      });
      setIngesterPlanFiles(filesList);
    });
    function formatFileSize(bytes, decimalPoint) {
      if (bytes === 0) return "0 Bytes";
      var k = 1024,
        dm = decimalPoint || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    socket.on("ingester-plan-files-comparison", ({ comparison }) => {
      const updatedFilesList = ingesterPlanFiles.map((file) => {
        const fileComparison = comparison.find((comp) => comp.id === file.id);
        if (fileComparison) {
          file.needsUpdate = fileComparison.needsUpdate;
        }
        return file;
      });
      setIngesterPlanFiles(updatedFilesList);
    });

    socket.on("knowledge-base-updated", ({ assistantId }) => {
      // refresh the AI Editor
      if (selectedAssistant.id === assistantId) {
        socket.emit("get-assistant-kb-metadata", {
          assistantId: selectedAssistant.id,
        });
        // in order to refresh the <EditAiAssistant> component, we need to change the assistantData
        const newAssistantData = { ...selectedAssistant };
        newAssistantData.lastUpdated = new Date().toISOString();
        setSelectedAssistant(newAssistantData);
      }
    });

    return () => {
      socket.off("ingester-plan-files");
      socket.off("ingester-plan-files-comparison");
      socket.off("knowledge-base-updated");
    };
  }, []);

  const handleAddAiAssistant = () => {
    const newAssistant = {
      id: "new",
      name: "New AI Assistant",
      systemPrompt: "",
      reminder: "",
      defaultModel: "gpt-4o-mini",
      productDependency: "none",
      tools: [],
      conversationStarters: [],
      isOwner: true,
      isGlobal: false,
    };

    if (anyUnsavedChanges) {
      setNextActionAfterDiscardingChanges({
        nextAction: "add",
        data: newAssistant,
      });
      setOpenConfirmDiscardChangesPopup(true);
      return;
    }

    setSelectedAssistant(newAssistant);

    // add only if there is no assistant with id "new"
    if (aiAssistants.find((assistant) => assistant.id === "new")) {
      return;
    }
    setAiAssistants([newAssistant, ...aiAssistants]);
    setShowEditAssistant(true);
  };

  const handleEditAiAssistant = (assistant) => {
    if (anyUnsavedChanges) {
      setNextActionAfterDiscardingChanges({
        nextAction: "edit",
        data: assistant,
      });
      setOpenConfirmDiscardChangesPopup(true);
      return;
    }
    socket.emit("ai-assistant-select", { assistantId: assistant.id });
    setSelectedAssistant(assistant);
    setShowEditAssistant(true);
  };

  const handleChangeAssistant = (data) => {
    // send data to App.js
    if (data.action === "delete") {
      setAiAssistants(
        aiAssistants.filter((assistant) => assistant.id !== data.assistant.id)
      );
      setShowEditAssistant(false);
      onChangeAssistant(data);
    }
    if (data.action === "add") {
      // update the name in the last item in the assistants list
      const lastAssistant = aiAssistants[aiAssistants.length - 1];
      lastAssistant.name = data.assistant.name;
      setAiAssistants((oldAiAssistants) => aiAssistants);
      onChangeAssistant(data);
    }
    if (data.action === "update") {
      onChangeAssistant(data);
    }
    setShowEditAssistant(false);
  };

  const handleSaveAs = () => {
    // create a new assistant with the same data as the selected assistant
    const newAssistant = { ...selectedAssistant };
    newAssistant.id = "new";
    newAssistant.name = "New AI Assistant";
    newAssistant.isGlobal = false;
    newAssistant.isOwner = true;

    delete newAssistant.owners;
    if (newAssistant.shares !== undefined) {
      delete newAssistant.shares;
    }
    setSelectedAssistant(newAssistant);
    setAiAssistants([newAssistant, ...aiAssistants]);
    setShowEditAssistant(true);
  };

  const handleUploadFile = () => {
    onClickUploadButton(commandsJsonData.commands[0], selectedAssistant.id); // let App.js handle the file upload
  };

  const handleUploadWebsite = () => {
    onClickUploadButton(commandsJsonData.commands[1], selectedAssistant.id);
  };

  const handleAiAssistantsListClose = () => {
    if (anyUnsavedChanges) {
      setNextActionAfterDiscardingChanges({ nextAction: "close", data: null });
      setOpenConfirmDiscardChangesPopup(true);
    } else {
      onClose();
    }
  };

  const handleEditorClose = () => {
    // called from the EditAiAssistant component (already confirmed)
    setShowEditAssistant(false);
  };

  const handleGoBackToEditing = () => {
    setOpenConfirmDiscardChangesPopup(false);
  };

  const handleDiscardChangesConfirmed = () => {
    setAnyUnsavedChanges(false);
    setShowEditAssistant(false);
    setOpenConfirmDiscardChangesPopup(false);
    if (nextActionAfterDiscardingChanges.nextAction === "edit") {
      setSelectedAssistant(nextActionAfterDiscardingChanges.data);
      setShowEditAssistant(true);
      setNextActionAfterDiscardingChanges({ nextAction: "none", data: null });
    } else if (nextActionAfterDiscardingChanges.nextAction === "close") {
      onClose();
      setNextActionAfterDiscardingChanges({ nextAction: "none", data: null });
    }
    if (nextActionAfterDiscardingChanges.nextAction === "add") {
      setSelectedAssistant(nextActionAfterDiscardingChanges.data);
      if (aiAssistants.find((assistant) => assistant.id === "new")) {
        return;
      }
      setAiAssistants([nextActionAfterDiscardingChanges.data, ...aiAssistants]);
      setShowEditAssistant(true);
      setNextActionAfterDiscardingChanges({ nextAction: "none", data: null });
    }
  };

  return (
    <div className="full-screen">
      <div className="top-tdsynnex-bar">
        <div className="company-logo-landing">
          <img
            src={companyLogo}
            alt="TD SYNNEX logo"
            style={{ width: "auto", height: "30px" }}
          />
        </div>
      </div>
      <div className="ai-assistants-list-container">
        <Container>
          {/* <AIAssistantSteps /> */}
          <Grid columns={16}>
            <Grid.Column width="sixteen">
              <Header
                as="h3"
                textAlign="center"
                className="ai-assistants-list-header-container"
              >
                <div>Assistants</div>
                <br></br>
                <div className="ai-assistants-buttons">
                  <Button primary onClick={() => handleAddAiAssistant()}>
                    Add
                  </Button>
                  <Button type="button" onClick={handleAiAssistantsListClose}>
                    Close
                  </Button>
                </div>
              </Header>

              <div className="ai-assistants-list">
                {aiAssistants.length === 0 && (
                  <label>No AI Assistants found.</label>
                )}
                {aiAssistants.length > 0 &&
                  aiAssistants.map((assistant) => (
                    <div
                      key={assistant.id}
                      onClick={() => handleEditAiAssistant(assistant)}
                      className={`ai-assistant ${
                        selectedAssistant.id === assistant.id ? "selected" : ""
                      }`}
                    >
                      <div className="ai-assistant-text">
                        {assistant.isGlobal && <TbWorld color="blue" />}
                        <FaWandMagicSparkles /> {assistant.name}{" "}
                      </div>
                      <Button
                        className="assistant-edit-button"
                        onClick={() => handleEditAiAssistant(assistant)}
                      >
                        <FaEdit />
                      </Button>
                    </div>
                  ))}
              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
      <div
        className={`ai-assistant-editor-container ${
          !showEditAssistant && "modifier"
        }`}
      >
        {!showEditAssistant && (
          <div>
            <img
              src={assistantsBanner}
              alt="AI Assistants"
              className="ai-assistants-banner"
            />
            {/* add a button Open ${currentAiAssistant.name} */}
            <div>
              <Button
                className="ai-assistant-text"
                onClick={() =>
                  handleEditAiAssistant(
                    aiAssistants.find(
                      (assistant) => assistant.id === selectedAssistant.id
                    )
                  )
                }
              >{`Edit ${activeAiAssistant.name}`}</Button>
              or click on an assistant on the left to edit it.
            </div>
            <div className="welcome-message">
              <Markdown>{assistantsEditorWelcomeMessage(appName)}</Markdown>
            </div>
          </div>
        )}
        {showEditAssistant && (
          <EditAiAssistant
            onClose={handleEditorClose}
            aiAssistantData={selectedAssistant}
            onChangeAssistant={handleChangeAssistant}
            handleUploadFile={handleUploadFile}
            handleUploadWebsite={handleUploadWebsite}
            setAnyUnsavedChanges={setAnyUnsavedChanges}
            userRoles={userRoles}
            handleSaveAs={handleSaveAs}
            socket={socket}
            onBrowseSharepoint={onBrowseSharepoint}
            filesAndFolderChain={filesAndFolderChain}
            sharepointTenantName={sharepointTenantName}
            sharepointSitePath={sharepointSitePath}
            onFileAction={onFileAction}
            onSaveSpIngestionPlan={onSaveSpIngestionPlan}
            onOpenSpIngestionPlan={onOpenSpIngestionPlan}
            onGetSavedSpIngestionPlans={onGetSavedSpIngestionPlans}
            ingesterPlan={ingesterPlan}
            ingesterPlansList={ingesterPlansList}
            ingesterPlanFiles={ingesterPlanFiles}
          />
        )}
      </div>
      <Confirm
        header={"Discard Changes"}
        content={
          "You have modified this assistant. Are you sure you want to discard the changes?"
        }
        open={openConfirmDiscardChangesPopup}
        onCancel={handleGoBackToEditing}
        onConfirm={handleDiscardChangesConfirmed}
        cancelButton="Go Back to Editing"
        confirmButton="Yes, discard the changes"
      />
    </div>
  );
}

export default function HorizontalLinearAlternativeLabelStepper({
  step,
  onClickStep,
}) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} onClick={() => onClickStep(index)} className="step">
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

//  ________________   _______ ______ ________________________ _______
// (  ___  \__   __/  (  ____ (  __  \\__   __\__   __(  ___  (  ____ )
// | (   ) |  ) (     | (    \| (  \  )  ) (     ) (  | (   ) | (    )|
// | (___) |  | |     | (__   | |   ) |  | |     | |  | |   | | (____)|
// |  ___  |  | |     |  __)  | |   | |  | |     | |  | |   | |     __)
// | (   ) |  | |     | (     | |   ) |  | |     | |  | |   | | (\ (
// | )   ( ___) (___  | (____/| (__/  ___) (___  | |  | (___) | ) \ \__
// |/     \\_______/  (_______(______/\_______/  )_(  (_______|/   \__/

// ascii art created in https://www.patorjk.com/software/taag/#p=testall&h=3&f=Graffiti&t=Assistants%20List

export function EditAiAssistant({
  aiAssistantData,
  onClose,
  onChangeAssistant,
  handleUploadFile,
  handleUploadWebsite,
  setAnyUnsavedChanges,
  userRoles,
  handleSaveAs,
  socket,
  // SharePoint stuff:
  filesAndFolderChain,
  sharepointTenantName,
  sharepointSitePath,
  onBrowseSharepoint,
  onFileAction,
  onSaveSpIngestionPlan,
  onOpenSpIngestionPlan,
  onGetSavedSpIngestionPlans,
  ingesterPlan,
  ingesterPlansList,
  ingesterPlanFiles,
}) {
  const [assistantData, setAssistantData] = useState(aiAssistantData);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmDiscardChanges, setOpenConfirmDiscardChanges] =
    useState(false);
  const [error, setError] = useState({});
  const [isMarketplaceChecked, setIsMarketplaceChecked] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [visibleStep, setVisibleStep] = useState(0);

  const stepRefs = useRef(steps.map(() => React.createRef()));
  const [showSharePointFilesPicker, setShowSharePointFilesPicker] =
    useState(false);
  const [showDataIngester, setShowDataIngester] = useState(false);
  const [containsKnowledgeFiles, setContainsKnowledgeFiles] = useState(false);
  const [myIngesterPlanFiles, setMyIngesterPlanFiles] = useState([]);
  const [validApiJsonOutput, setValidApiJsonOutput] = useState(true);
  const [userAuthData, setUserAuthData] = useState(null)

  const { accounts } = useMsal()
  const appName = useStore((state) => state.appName);

  useEffect(() => {
    if (accounts.length > 0) {
      const user = accounts[0]
      setUserAuthData({
        name: user.name,
        upn: user.username,
      })
    }
  }, [accounts])

  const scrollToStep = (index) => {
    stepRefs.current[index].current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setVisibleStep(0);
    if (assistantData.systemPrompt.length > 5) {
      setVisibleStep(1);
      if (assistantData.tools.length > 0) {
        setVisibleStep(2);
        if (assistantData.conversationStarters?.length > 0) {
          setVisibleStep(3);
          if (
            assistantData.isGlobal ||
            (assistantData.shares !== undefined &&
              Object.keys(assistantData.shares).length > 0)
          ) {
            setVisibleStep(4);
          }
        }
      }
    }
    if (aiAssistantData.id !== "new") {
      // socket.emit('ai-assistant-select', {assistantId: aiAssistantData.id});
      // disabled the line above because it is being called every time the user changes any single character
    }
    setShowSharePointFilesPicker(false);
    setShowDataIngester(false);
  }, [
    assistantData.systemPrompt,
    assistantData.tools,
    assistantData.conversationStarters,
    assistantData.isGlobal,
    aiAssistantData.id,
    aiAssistantData.isOwner,
  ]);

  useEffect(() => {
    setAssistantData(aiAssistantData);
    if (aiAssistantData.shares?.marketplace) {
      setIsMarketplaceChecked(true);
    } else {
      setIsMarketplaceChecked(false);
    }

    //business rules related to isReadOnly
    if (
      !aiAssistantData.isOwner ||
      (aiAssistantData.isGlobal && !userRoles.includes("aiAssistantsAdmin"))
    ) {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }

    updateVisibleStep();
  }, [aiAssistantData]);

  useEffect(() => {
    setMyIngesterPlanFiles(ingesterPlanFiles);
  }, [ingesterPlanFiles]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAssistantData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setAnyUnsavedChanges(true);
    // check if assistantData.shares?.apiJsonOutput is a valid JSON
    if (name === "apiJsonOutput") {
      if (value === "") {
        setValidApiJsonOutput(true);
        return;
      }
      try {
        JSON.parse(value);
        setValidApiJsonOutput(true);
      } catch (error) {
        setValidApiJsonOutput(false);
      }
    }
  };

  const handleConversationStarterChange = (index, field, value) => {
    setAnyUnsavedChanges(true);
    setAssistantData((prevState) => {
      const conversationStarters = [...prevState.conversationStarters];
      conversationStarters[index][field] = value;
      return {
        ...prevState,
        conversationStarters,
      };
    });

    updateVisibleStep();
  };

  const handleAddConversationStarter = () => {
    setAnyUnsavedChanges(true);
    setAssistantData((prevState) => ({
      ...prevState,
      conversationStarters: [
        ...prevState.conversationStarters,
        {
          title: "",
          text: "",
          model: "default",
        },
      ],
    }));
  };

  const handleRemoveConversationStarter = (index) => {
    setAnyUnsavedChanges(true);
    setAssistantData((prevState) => {
      const conversationStarters = [...prevState.conversationStarters];
      conversationStarters.splice(index, 1);
      return {
        ...prevState,
        conversationStarters,
      };
    });
  };

  const handleOpenDeleteConfirm = () => {
    setOpenConfirmDelete(true);
  };

  const handleCancelConfirm = () => {
    // Go Back to Editing
    setOpenConfirmDelete(false);
    setOpenConfirmDiscardChanges(false);
  };

  const handleDeleteAssistant = () => {
    setAnyUnsavedChanges(false);
    onChangeAssistant({ action: "delete", assistant: assistantData });
    setOpenConfirmDelete(false);
  };

  const handleDiscardChangesConfirmed = () => {
    setAnyUnsavedChanges(false);
    setAssistantData(aiAssistantData);
    onClose();
  };

  //--------------------------------------------------------------------------------
  // SUBMIT FORM
  //--------------------------------------------------------------------------------

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!assistantData.name || !assistantData.systemPrompt) {
      setError({
        name: !assistantData.name ? true : false,
        systemPrompt: !assistantData.systemPrompt ? true : false,
      });
      return;
    }

    if (isInvalidEmailsInSharedAssistant(assistantData.shares?.users)) {
      toast.error(
        "One or more user UPNs are invalid email addresses. Please correct them and try again."
      );
      return;
    }

    if (assistantData.id != "new" && assistantData.createdBy === undefined) {
      assistantData.createdBy = assistantData.owners[0];
    }

    // Perform necessary actions with the updated assistantData
    // if the assistantData.id is "new", then it is a new assistant. Create an id from datetime and uuid 4
    if (assistantData.id === "new") {
      // assistantData.id = `${new Date().getTime()}-${uuidv4()}`;
      onChangeAssistant({ action: "add", assistant: assistantData });
    } else {
      onChangeAssistant({ action: "update", assistant: assistantData });
    }
    setAnyUnsavedChanges(false);
  };

  const isInvalidEmailsInSharedAssistant = (users) => {
    if (!users || users.length === 0) {
      return false;
    }

    // Validate email addresses
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = users.filter((user) => !regex.test(user.userUPN));

    return invalidEmails && invalidEmails.length > 0;
  };

  const handleTogglePublicAssistant = () => {
    setAnyUnsavedChanges(true);
    setAssistantData((prevState) => {
      const updatedState = { ...prevState };
      if (updatedState.hasOwnProperty("isGlobal")) {
        updatedState.isGlobal = !updatedState.isGlobal;
      } else {
        updatedState.isGlobal = true; // Set a default value if the key does not exist
      }
      return updatedState;
    });
  };

  const handleCloseButtonClick = () => {
    if (aiAssistantData !== assistantData) {
      setOpenConfirmDiscardChanges(true);
    } else {
      onClose();
    }
  };

  const addAADGroup = () => {
    const newAssistantData = { ...assistantData };
    // add the shares and the shares.AADGroups if they don't exist
    if (!newAssistantData.shares) {
      newAssistantData.shares = {};
    }
    if (!newAssistantData.shares.AADGroups) {
      newAssistantData.shares.AADGroups = [];
    }
    newAssistantData.shares.AADGroups.push({ groupId: "" });
    setAssistantData(newAssistantData);
  };

  const addUserUPN = () => {
    const newAssistantData = { ...assistantData };
    if (!newAssistantData.shares) {
      newAssistantData.shares = {};
    }
    if (!newAssistantData.shares.users) {
      newAssistantData.shares.users = [];
    }
    const newUsers = [...(assistantData.shares?.users || []), { userUPN: "" }];
    setAssistantData({
      ...assistantData,
      shares: { ...assistantData.shares, users: newUsers },
    });
  };

  const updateAADGroupId = (index, value) => {
    setAnyUnsavedChanges(true);
    const newGroups = assistantData.shares.AADGroups.map((group, i) =>
      i === index ? { ...group, groupId: value } : group
    );
    setAssistantData({
      ...assistantData,
      shares: { ...assistantData.shares, AADGroups: newGroups },
    });
  };

  const updateUserUPN = (index, value) => {
    const erros = isValidUpdateUserUPN(assistantData, value);
    if (erros.length > 0) {
      assistantData.shares.users[index].userUPN = "";
      setAssistantData({ ...assistantData });
      toast.error(erros.join("\n"));
      return;
    }

    setAnyUnsavedChanges(true);
    const newUsers = assistantData.shares.users.map((user, i) =>
      i === index ? { ...user, userUPN: value } : user
    );
    setAssistantData({
      ...assistantData,
      shares: { ...assistantData.shares, users: newUsers },
    });
  };

  const isValidUpdateUserUPN = (assistantData, value) => {
    let messages = []

    if (value.toUpperCase() == userAuthData.upn.toUpperCase())
      messages.push("You cannot add yourself as a user.")

    if (
      assistantData.shares.users
        .map((x) => x.userUPN.toUpperCase())
        .includes(value.toUpperCase())
    )
      messages.push("This user is already added.")

    return messages
  };

  const updateOwnerUserUPN = (index, value) => {
    try {
      setAnyUnsavedChanges(true)

      const userUpn = assistantData.shares.users[index].userUPN
      if (value) {
        setAssistantData({
          ...assistantData,
          owners: [...assistantData.owners, userUpn],
        })
      } else {
        const updatedOwners = assistantData.owners.filter(
          (owner) => owner.toUpperCase() !== userUpn.toUpperCase()
        )
        setAssistantData({
          ...assistantData,
          owners: updatedOwners,
        })
      }
    } catch (error) {
      toast.error("Error updating owner UPN")
    }
  };

  const removeAADGroup = (index) => {
    setAnyUnsavedChanges(true);
    const newGroups = [...assistantData.shares.AADGroups];
    newGroups.splice(index, 1);
    setAssistantData({
      ...assistantData,
      shares: { ...assistantData.shares, AADGroups: newGroups },
    });
  };

  const removeUserUPN = (index) => {
    try {
      setAnyUnsavedChanges(true)
      const newUsers = [...assistantData.shares.users]
      newUsers.splice(index, 1)

      const shares = { ...assistantData.shares, users: newUsers }
      let owners = [...assistantData.owners]

      // if the user is an owner, remove it from the owners list
      const upn = assistantData.shares.users[index]?.userUPN
      if (owners?.includes(upn)) {
        owners = owners.filter((owner) => owner !== upn)
      }

      setAssistantData({
        ...assistantData,
        shares: shares,
        owners: owners,
      })
    } catch (error) {
      toast.error("Error removing user UPN")
    }
  };

  const handleToggleShareThroughMarketplace = () => {
    if (assistantData.isGlobal) {
      setAssistantData({
        ...assistantData,
        shares: { ...assistantData.shares, marketplace: false },
      });
      setIsMarketplaceChecked(false);
      return;
    }
    setAssistantData({
      ...assistantData,
      shares: { ...assistantData.shares, marketplace: !isMarketplaceChecked },
    });
    setIsMarketplaceChecked(!isMarketplaceChecked);
  };

  const handleManageSharePointDataSources = () => {
    setShowSharePointFilesPicker(true);
  };

  const handleIngestSharePointFiles = () => {
    setShowDataIngester(true);
  };

  const handleSelectIngestionPlan = (ingestionPlanKeyName) => {
    socket.emit("get-ingester-plan-files", {
      ingestionPlanKeyName,
      assistantId: assistantData.id,
    });
  };

  const handleIncrementalIngestClick = () => {
    socket.emit("ingest-sharepoint-files-incremental", {
      assistantId: assistantData.id,
      ingesterPlanFiles: ingesterPlanFiles,
    });
  };

  const fileEntry = {
    folderPath: "testFolderPath",
    filename: "testFilename",
    subject: "testSubject",
    lastModified: "testLastModifiedfff\nv",
    lastModifiedBy: "testLastModifiedBy",
    needsUpdate: "testNeedsUpdate",
    size: "testSize",
  };

  const handleUpdateFileDescription = (assistantId, files) => {
    const newIngesterPlanFiles = [...ingesterPlanFiles];
    const updatedFiles = files.map(({ fileId, description }) => {
      // update ingesterPlanFiles
      // ingesterPlanFiles.forEach((file) => {
      //   if (file.id === fileId) {
      //     file.subject = description;
      //   }

      // });
      return { fileId, subject: description };
    });
    newIngesterPlanFiles.forEach((file) => {
      const updatedFile = updatedFiles.find((f) => f.fileId === file.id);
      if (updatedFile) {
        if (
          file.subject !== updatedFile.subject &&
          file.needsUpdate !== "deleted" &&
          file.needsUpdate !== "not supported"
        ) {
          file.subject = updatedFile.subject;
          file.needsUpdate = "updated";
        }
      }
    });
    setMyIngesterPlanFiles(newIngesterPlanFiles);

    socket.emit("upsert-sharepoint-temp-enrich-map", {
      assistantId,
      files: updatedFiles,
    });
  };

  const handleFilesRefresh = (filesCount) => {
    // if there are files in the KnowledgeBase, the delete button should be disabled
    if (filesCount > 0) {
      setContainsKnowledgeFiles(true);
    } else {
      setContainsKnowledgeFiles(false);
    }
  };

  const handleMagicFill = () => {
    const changedFiles = [];
    for (const file of ingesterPlanFiles) {
      // remove the extension from the filename

      let description;
      if (file.filename.includes(".")) {
        description = file.filename.split(".").slice(0, -1).join(".");
      } else {
        description = file.filename;
      }
      // replace underscores with spaces
      description = description.replace(/_/g, " ");
      // capitalize the first letter
      description = description.charAt(0).toUpperCase() + description.slice(1);
      // set the description
      if (file.subject === "") {
        changedFiles.push({ fileId: file.id, description });
      }
    }
    handleUpdateFileDescription(assistantData.id, changedFiles);
  };

  return (
    <div>
      {showSharePointFilesPicker ? (
        <div>
          <SharePointFileBrowser
            onBrowseSharepoint={onBrowseSharepoint}
            filesAndFolderChain={filesAndFolderChain}
            sharepointTenantName={sharepointTenantName}
            sharepointSitePath={sharepointSitePath}
            onFileAction={onFileAction}
            onSaveSpIngestionPlan={onSaveSpIngestionPlan}
            onOpenSpIngestionPlan={onOpenSpIngestionPlan}
            onGetSavedSpIngestionPlans={onGetSavedSpIngestionPlans}
            ingesterPlan={ingesterPlan}
            ingesterPlansList={ingesterPlansList}
            onClose={() => setShowSharePointFilesPicker(false)}
          />
        </div>
      ) : showDataIngester ? (
        <div>
          <SharepointDataIngester
            assistantId={aiAssistantData.id}
            assistantName={aiAssistantData.name}
            knowledgeBaseType={"SharePoint"}
            filesList={myIngesterPlanFiles}
            handleUpdateFileDescription={handleUpdateFileDescription}
            handleIncrementalIngest={handleIncrementalIngestClick}
            handleFullIngest={() => {}}
            onMagicFill={handleMagicFill}
            ingesterPlan={ingesterPlan}
            ingesterPlansList={ingesterPlansList}
            onSelectIngestionPlan={handleSelectIngestionPlan}
            onClose={() => setShowDataIngester(false)}
          />
        </div>
      ) : (
        <>
          <div className="steps-container">
            <HorizontalLinearAlternativeLabelStepper
              step={visibleStep}
              onClickStep={scrollToStep}
            />
          </div>
          <div className="ai-assistant-container2">
            <Container>
              {/* <AIAssistantSteps /> */}
              <Grid centered columns={1}>
                <Grid.Column>
                  <Header as="h2" textAlign="center">
                    Edit AI Assistant Data
                  </Header>

                  <Form onSubmit={handleSubmit}>
                    <div key={0} ref={stepRefs.current[0]}>
                      <Form.Field required error={error.name}>
                        <label>Your AI Assistant's Name</label>
                        <Input
                          name="name"
                          value={assistantData.name}
                          onChange={handleInputChange}
                          required
                          placeholder="Enter the name of the AI Assistant..."
                          readOnly={isReadOnly}
                        />
                        {assistantData.isGlobal && (
                          <span>
                            <TbWorld color="blue" /> This assistant is flagged
                            as global. All LARA users can use it. It cannot be
                            deleted.
                          </span>
                        )}
                        {assistantData.isGlobal &&
                          !userRoles.includes("aiAssistantsAdmin") && (
                            <p style={{ color: "red" }}>
                              You don't have the access rights to edit this
                              assistant (aiAssistantsAdmin role not found in
                              user profile). This information is Read-Only.
                            </p>
                          )}
                        {containsKnowledgeFiles && (
                          <p style={{ color: "gray" }}>
                            This assistant has knowledge files. It cannot be
                            deleted.
                          </p>
                        )}
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {!(
                            assistantData.isGlobal &&
                            !userRoles.includes("aiAssistantsAdmin")
                          ) &&
                            assistantData.isOwner && (
                              <Button primary type="submit">
                                Save Changes
                              </Button>
                            )}
                          {!assistantData.isGlobal && assistantData.isOwner && (
                            <Button
                              type="button"
                              onClick={handleOpenDeleteConfirm}
                              disabled={containsKnowledgeFiles}
                              color="red"
                            >
                              Delete Assistant
                            </Button>
                          )}
                          <Button type="button" onClick={handleSaveAs}>
                            Save As
                          </Button>
                          {userRoles.includes("aiAssistantsAdmin") && (
                            <Button
                              type="button"
                              onClick={handleTogglePublicAssistant}
                              color="purple"
                            >
                              {assistantData.isGlobal
                                ? "Remove from Global Assistants"
                                : "Convert to Global Assistant "}{" "}
                              <TbWorld />
                            </Button>
                          )}
                        </div>
                      </Form.Field>
                      {Object.values(error).includes(true) && (
                        <p style={{ color: "red" }}>
                          One or more fields had validation errors. Please
                          review them and try saving again. Some fields are
                          mandatory.
                        </p>
                      )}

                      <Form.Field required error={error.systemPrompt}>
                        <label>System Prompt ("You are ...")</label>
                        <TextareaAutosize
                          name="systemPrompt"
                          value={assistantData.systemPrompt}
                          onChange={handleInputChange}
                          style={{
                            backgroundColor: "rgba(248, 246, 243)",
                            resize: "none",
                          }}
                          required
                          readOnly={isReadOnly}
                          minRows={6}
                          maxRows={20}
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>
                          Reminder (it is always included as the last message to
                          the AI)
                        </label>
                        <TextArea
                          name="reminder"
                          value={assistantData.reminder}
                          onChange={handleInputChange}
                          style={{
                            backgroundColor: "rgba(248, 246, 243)",
                            resize: "none",
                          }}
                          readOnly={isReadOnly}
                        />
                      </Form.Field>

                      <Form.Field required>
                        <label>Default Model</label>
                        <Select
                          className="dropdown-select"
                          name="defaultModel"
                          options={models
                            .filter((model) => model !== "default")
                            .map((model) => ({
                              key: model,
                              value: model,
                              text: model,
                            }))}
                          value={assistantData.defaultModel}
                          onChange={(event, { value }) =>
                            handleInputChange({
                              target: { name: "defaultModel", value },
                            })
                          }
                          readOnly={isReadOnly}
                          disabled={isReadOnly}
                        />
                      </Form.Field>
                    </div>
                    <div key={1} ref={stepRefs.current[1]}>
                      {/* <Form.Field>
                    <label>Only available if these products are activated (optional)</label>
                    <Select
                      name="productDependency"
                      options={products.map((product) => ({
                        key: product,
                        value: product,
                        text: product,
                      }))}
                      value={assistantData.productDependency}
                      onChange={(event, { value }) =>
                        handleInputChange({ target: { name: 'productDependency', value } })
                      }
                      multiple
                      readOnly={isReadOnly}
                      disabled={isReadOnly}
                    />
                  </Form.Field>  */}

                      <Form.Field>
                        <label>
                          Tools (note: get_knowledge required for Knowledge
                          Bases to work)
                        </label>
                        <Select
                          name="tools"
                          options={tools.map((tool) => ({
                            key: tool,
                            value: tool,
                            text: tool,
                          }))}
                          value={assistantData.tools}
                          onChange={(event, { value }) =>
                            handleInputChange({
                              target: { name: "tools", value },
                            })
                          }
                          multiple
                          readOnly={isReadOnly}
                          disabled={isReadOnly}
                        />
                      </Form.Field>
                      {assistantData.id !== "new" && (
                        <Form.Field>
                          <label>Knowledge Bases</label>
                          <AiAssistantKnowledgeManager
                            assistantId={assistantData.id}
                            socket={socket}
                            onFilesRefresh={handleFilesRefresh}
                            isOwner={assistantData.isOwner}
                          />
                          {!(
                            assistantData.isGlobal &&
                            !userRoles.includes("aiAssistantsAdmin")
                          ) && (
                            <div className="assistant-upload-buttons">
                              <Button
                                type="button"
                                onClick={() => handleUploadFile()}
                              >
                                Upload File
                              </Button>
                              <Button
                                type="button"
                                onClick={() => handleUploadWebsite()}
                              >
                                Upload Website
                              </Button>
                              <Button
                                type="button"
                                onClick={() =>
                                  handleManageSharePointDataSources()
                                }
                              >
                                Manage SharePoint DataSources
                              </Button>
                              <Button
                                type="button"
                                onClick={() => handleIngestSharePointFiles()}
                              >
                                Ingest SharePoint Files
                              </Button>
                            </div>
                          )}
                        </Form.Field>
                      )}
                      {assistantData.id === "new" && (
                        <div>
                          Please save the assistant before adding Knowledge
                          Files.
                        </div>
                      )}
                    </div>
                    <div key={2} ref={stepRefs.current[2]}>
                      <div className="ui divider"></div>
                      <Form.Field>
                        <h3>Conversation Starters</h3>
                        {assistantData.conversationStarters?.map(
                          (starter, index) => (
                            <div
                              key={index}
                              className="conversation-starter-container"
                            >
                              <div className="conversation-starter-title">
                                <Input
                                  label="Name"
                                  value={starter.title}
                                  onChange={(e) =>
                                    handleConversationStarterChange(
                                      index,
                                      "title",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Type a short name (optional)..."
                                  readOnly={isReadOnly}
                                />

                                <Button
                                  type="button"
                                  onClick={() =>
                                    handleRemoveConversationStarter(index)
                                  }
                                  color="red"
                                  disabled={isReadOnly}
                                >
                                  Remove
                                </Button>
                              </div>

                              <TextareaAutosize
                                placeholder="Enter a conversation starter prompt (mandatory)..."
                                value={starter.text}
                                onChange={(e) =>
                                  handleConversationStarterChange(
                                    index,
                                    "text",
                                    e.target.value
                                  )
                                }
                                className="conversation-starter-text"
                                style={{
                                  backgroundColor: "rgba(248, 246, 243)",
                                  resize: "none",
                                }}
                                readOnly={isReadOnly}
                                minRows={4}
                                maxRows={20}
                                required
                              />

                              <Select
                                className="dropdown-select"
                                label="Model"
                                options={models.map((model) => ({
                                  key: model,
                                  value: model,
                                  text: model,
                                }))}
                                value={starter.model}
                                onChange={(event, { value }) =>
                                  handleConversationStarterChange(
                                    index,
                                    "model",
                                    value
                                  )
                                }
                                readOnly={isReadOnly}
                                disabled={isReadOnly}
                              />
                            </div>
                          )
                        )}
                        {!(
                          assistantData.isGlobal &&
                          !userRoles.includes("aiAssistantsAdmin")
                        ) &&
                          assistantData.isOwner && (
                            <Button
                              type="button"
                              onClick={handleAddConversationStarter}
                            >
                              Add Conversation Starter
                            </Button>
                          )}
                      </Form.Field>
                    </div>
                    <div key={3} ref={stepRefs.current[3]}>
                      <div className="ui divider"></div>
                      {aiAssistantData.isOwner && (
                        <Box p={2} sx={{ border: "1px solid grey" }}>
                          <ExternalAccessSection
                            assistantData={aiAssistantData}
                            isReadOnly={isReadOnly}
                            updateAssistant={setAssistantData}
                          ></ExternalAccessSection>

                          <Form.Field error={error.systemPrompt}>
                            <label>
                              API JSON Output spec{" "}
                              <span
                                className={`valid-json ${
                                  validApiJsonOutput ? "" : "invalid"
                                }`}
                              >
                                {validApiJsonOutput ? "Valid" : "Invalid JSON"}
                              </span>
                            </label>
                            <div className="explanation">
                              When defined, the call to this assistant's API
                              will return a JSON object as specified here.
                            </div>
                            <TextareaAutosize
                              name="apiJsonOutput"
                              value={assistantData.apiJsonOutput}
                              onChange={handleInputChange}
                              style={{
                                backgroundColor: "rgba(248, 246, 243)",
                                resize: "none",
                              }}
                              readOnly={isReadOnly}
                              minRows={4}
                              maxRows={20}
                              placeholder="Enter the JSON output of the API or leave blank..."
                            />
                          </Form.Field>
                        </Box>
                      )}
                      <Form.Field>
                        <label>
                          Share with Azure AD groups (recommended if sharing
                          with a large group)
                        </label>
                        {assistantData.shares?.AADGroups &&
                          assistantData.shares?.AADGroups.map(
                            (group, index) => (
                              <div
                                key={index}
                                className="conversation-starter-title"
                              >
                                <Input
                                  label="AAD Group ID"
                                  value={group.groupId}
                                  placeholder="Enter the Azure AD group ID..."
                                  onChange={(e) =>
                                    updateAADGroupId(index, e.target.value)
                                  }
                                  readOnly={isReadOnly}
                                />

                                <Button
                                  type="button"
                                  onClick={() => removeAADGroup(index)}
                                  color="red"
                                  disabled={isReadOnly}
                                >
                                  Remove
                                </Button>
                              </div>
                            )
                          )}

                        <Button
                          type="button"
                          onClick={addAADGroup}
                          disabled={isReadOnly}
                        >
                          Add AAD Group
                        </Button>
                      </Form.Field>

                      <Form.Field>
                        <label>
                          Share with other users (use when sharing with a few
                          people)
                        </label>

                        <Box p={2} sx={{ border: "1px solid grey" }}>
                          <div>Shared Users</div>
                          <div className="explanation">
                            Sharing an assistant with another user allows them
                            to also have access and use it.
                          </div>

                          {assistantData.shares?.users &&
                            assistantData.shares?.users.map((user, index) => (
                              <div
                                key={index}
                              >
                                <div className="conversation-starter-title">
                                  <Input
                                    label="Lifetime ID or UPN"
                                    value={user.userUPN}
                                    placeholder="Enter the user's Lifetime ID (a.k.a. UPN)..."
                                    onChange={(e) =>
                                      updateUserUPN(index, e.target.value)
                                    }
                                    readOnly={isReadOnly}
                                  />

                                  <Button
                                    type="button"
                                    onClick={() => removeUserUPN(index)}
                                    color="red"
                                    disabled={isReadOnly}
                                  >
                                    Remove
                                  </Button>
                                </div>

                                <div>
                                  <FormControlLabel
                                    sx={{ marginTop: "2px", paddingTop: "2px" }}
                                    control={
                                      <CheckboxMU
                                        disabled={isReadOnly}
                                        checked={assistantData?.owners?.includes(
                                          user.userUPN
                                        )}
                                        onChange={(e) => {
                                          updateOwnerUserUPN(
                                            index,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    }
                                    label={
                                      <div>
                                        <Typography
                                          variant="body1"
                                          fontWeight="bold"
                                        >
                                          Is an owner ?
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="textSecondary"
                                        >
                                          With this option, the user will have
                                          full control over the Assistant.
                                        </Typography>
                                      </div>
                                    }
                                  />
                                </div>

                                {index <
                                  assistantData.shares?.users.length - 1 && (
                                  <Divider
                                    sx={{ marginTop: 1, marginBottom: 1 }}
                                  />
                                )}
                              </div>
                            ))}
                          <Button
                            type="button"
                            onClick={addUserUPN}
                            disabled={isReadOnly}
                          >
                            Add User
                          </Button>
                        </Box>
                      </Form.Field>
                      <Form.Field>
                        <label>
                          Share on Marketplace (self-service global share)
                        </label>
                        <Checkbox
                          className="toggle"
                          name="marketplace"
                          checked={isMarketplaceChecked}
                          onChange={handleToggleShareThroughMarketplace}
                          disabled={isReadOnly}
                        />
                      </Form.Field>
                      {assistantData.shares?.marketplace && (
                        <p style={{ color: "purple" }}>
                          This assistant is shared on the Marketplace. It can be
                          used by all other {appName} users. This includes
                          knowledge bases.
                        </p>
                      )}
                      {(assistantData.shares?.AADGroups?.length > 0 ||
                        assistantData.shares?.users?.length > 0) && (
                        <p style={{ color: "red" }}>
                          ALERT: Do not publish the assistant in the Marketshare
                          if you want to restrict access to this AI Assistant.
                        </p>
                      )}
                      {assistantData.isGlobal && (
                        <p style={{ color: "purple" }}>
                          This assistant is flagged as global, so all {appName}{" "}
                          users can see it by default. It cannot be published on
                          the marketplace.
                        </p>
                      )}
                    </div>

                    <div className="ui divider"></div>
                    {!(
                      assistantData.isGlobal &&
                      !userRoles.includes("aiAssistantsAdmin")
                    ) &&
                      assistantData.isOwner && (
                        <Button primary type="submit">
                          Save Changes
                        </Button>
                      )}
                    <Button type="button" onClick={handleCloseButtonClick}>
                      Close
                    </Button>
                    {Object.values(error).includes(true) && (
                      <p style={{ color: "red" }}>
                        One or more fields above had validation errors. Please
                        review them and try saving again.
                      </p>
                    )}

                    <div style={{ paddingTop: "30px" }}>
                      <label>Assistant Data</label>
                      Assistant Shortcut URL:{" "}
                      {window.location.origin +
                        "/?assistant=" +
                        assistantData.id}{" "}
                      <br />
                      <Button
                        type="button"
                        onClick={() =>
                          navigator.clipboard.writeText(
                            window.location.origin +
                              "/?assistant=" +
                              assistantData.id
                          )
                        }
                      >
                        Copy URL
                      </Button>
                      <p />
                      <TextArea
                        style={{
                          height: "400px",
                          backgroundColor: "rgba(248, 246, 243)",
                          resize: "none",
                        }}
                        value={JSON.stringify(assistantData, null, 2)}
                        readOnly
                      />
                    </div>
                  </Form>
                  <Confirm
                    header={"Delete AI Assistant"}
                    open={openConfirmDelete}
                    onCancel={handleCancelConfirm}
                    onConfirm={handleDeleteAssistant}
                    cancelButton="Go Back to Editing"
                    confirmButton="Yes, delete it"
                  />
                  <Confirm
                    header={"Discard Changes"}
                    content={
                      "You have modified this assistant. Are you sure you want to discard the changes?"
                    }
                    open={openConfirmDiscardChanges}
                    onCancel={handleCancelConfirm}
                    onConfirm={handleDiscardChangesConfirmed}
                    cancelButton="Go Back to Editing"
                    confirmButton="Yes, discard the changes"
                  />
                </Grid.Column>
              </Grid>
            </Container>
          </div>
        </>
      )}
    </div>
  );

  function updateVisibleStep() {
    setVisibleStep(0);
    if (aiAssistantData.systemPrompt.length > 5) {
      setVisibleStep(1);
      if (aiAssistantData.tools.length > 0) {
        setVisibleStep(2);
        if (aiAssistantData.conversationStarters?.length > 0) {
          setVisibleStep(3);
          if (
            aiAssistantData.isGlobal ||
            (aiAssistantData.shares !== undefined &&
              Object.keys(aiAssistantData.shares).length > 0)
          ) {
            setVisibleStep(4);
          }
        }
      }
    }
  }
}
