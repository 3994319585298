import { useForm, Controller } from "react-hook-form";
import './CommandsForm.css'
import React, { useState, useEffect, useRef } from "react";
import Markdown from "react-markdown";
import { Tooltip } from "react-tooltip";


// commandsJsonData is defined in MessageInput.js


export default function CommandsForm({
  commandJSON,
  commandParametersHandler,
  commandFormKeyDownHandler,
  prompts,
  aboutMe
}) {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const [selectedPrompt, setSelectedPrompt] = useState("");

  const onSubmit = (data) => {
    // Perform actions with the form data here
    // Call the commandParametersHandler function with the form data
    commandParametersHandler(data);
  };

  const firstFormFieldRef = useRef(null);

  useEffect(() => {
    if (firstFormFieldRef.current) {
      firstFormFieldRef.current.focus();
    }
  }, []);

  

  // cancel handler
  const cancelHandler = () => {
    // same as pressing the ESC key
    setIsModalOpen(false);
    commandFormKeyDownHandler({ keyCode: 27 });
  };

  return (
    <div className="overlay" tabIndex="-1">
      {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
      <form onSubmit={handleSubmit(onSubmit)} className="commands-form">
        {/* Render command name and description */}
        <h3>{commandJSON.name}</h3>
        <div className="command-description">
          <Markdown>{commandJSON.description}</Markdown>
        </div>
        {/* Render command parameters if available */}
        {commandJSON.parameters &&
          commandJSON.parameters.map((parameter, paramIndex) => (
            <div key={paramIndex}>
              {/* Render input fields based on parameter type */}
              {parameter.type === "file" ? (
                <Controller
                  control={control}
                  name={`parameters[${paramIndex}].value`}
                  render={({ field: { value, onChange, ...field } }) => (
                    <input
                      {...field}
                      type="file"
                      onChange={(e) => {
                        onChange(e.target.files[0]);
                      }}
                      onKeyDown={commandFormKeyDownHandler}
                      ref={paramIndex === 0 ? firstFormFieldRef : null}
                    />
                  )}
                />
              ) : parameter.type === "select[prompts]" ? (
                <Controller
                  name={`parameters[${paramIndex}].value`}
                  control={control}
                  rules={{ required: parameter.required }}
                  render={({ field }) => (
                    <select {...field} onKeyDown={commandFormKeyDownHandler} ref={paramIndex === 0 ? firstFormFieldRef : null}>
                      <option value="">Select {`${commandJSON.parameters[paramIndex].name}`}</option>
                      {prompts.map((prompt, index) => (
                        <option key={index} value={prompt}>
                          {prompt}
                        </option>
                      ))}
                    </select>
                  )}
                />
              ) : parameter.enum !== undefined && parameter.type === "string" ? (
                <Controller
                  name={`parameters[${paramIndex}].value`}
                  control={control}
                  rules={{ required: parameter.required }}
                  render={({ field }) => (
                    <select {...field} onKeyDown={commandFormKeyDownHandler} ref={paramIndex === 0 ? firstFormFieldRef : null}>
                      <option value="">Select {`${commandJSON.parameters[paramIndex].name}`}</option>
                      {parameter.enum.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  )}
                />
           ) : parameter.type === "textarea" ? (
                <Controller
                  name={`parameters[${paramIndex}].value`}
                  control={control}
                  rules={{ required: parameter.required }}
                  render={({ field }) => (
                    <textarea
                      {...field}
                      placeholder={parameter.name}
                      onKeyDown={commandFormKeyDownHandler}
                      ref={paramIndex === 0 ? firstFormFieldRef : null}
                      defaultValue={parameter.value}
                    />
                  )}
                />
              // ) : parameter.type === "hidden" ? (
              //   <Controller
              //     name={`parameters[${paramIndex}].value`}
              //     control={control}
              //     rules={{ required: parameter.required }}
              //     render={({ field }) => (
              //     <input
              //       {...field}
              //       type="hidden"
              //       value={parameter.value}
              //     />
              //     )}
              //   />
                ) : (
                  <>
                  <Tooltip anchorSelect=".info-logo" place="top" style={{"zIndex":12}}>
                    {parameter.description.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                    ))}
                  </Tooltip>
                  <Controller
                    name={`parameters.${paramIndex}.value`}
                    control={control}
                    rules={{ required: parameter.required }}
                    render={({ field }) => (
                    <div className="command-text-input">
                      
                      <input 
                      {...field}
                      type="text"
                            // use maxSize to limit the number of characters allowed in the input field
                            // it is optional
                            maxLength={parameter.maxSize}
                            placeholder={parameter.name}
                            onKeyDown={commandFormKeyDownHandler}
                            ref={paramIndex === 0 ? firstFormFieldRef : null}
                          />
                          <div className="info-logo">ℹ</div>
                        </div>
                      )}
                    />
                </>
              )}
              {/* Render error message if parameter is required */}
              {errors.parameters &&
                errors.parameters[paramIndex] &&
                errors.parameters[paramIndex].value && (
                  <span>{parameter.name} is required</span>
                )}
            </div>
          ))}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <input type="submit" onKeyDown={commandFormKeyDownHandler}/>
            <button type="button" onClick={cancelHandler} className="slash-form-cancel" tabIndex={0}>Cancel</button>
          </div>
          
        </form>
    </div>
  );
}


