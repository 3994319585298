import React, { useEffect, useState } from "react";
import { Button, Confirm } from "semantic-ui-react";
import styles from "./ExternalAccessSection.module.css";
import AddExternalAccessForm from "./AddExternalAccessForm";
import api from "../../plugins/api";
import { toast } from "react-toastify";
import { CircularProgress, Box } from "@mui/material";

export default function ExternalAccessSection({ assistantData, isReadOnly }) {
  const [externalAccessList, setExternalAccessList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [assistantToRemove, setAssistantToRemove] = useState({});
  const [isLoadingGetApiKey, setIsLoadingGetApiKey] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setExternalAccessList([]);
      setIsLoadingGetApiKey(true);
      api
        .get(`v1/assistant/${assistantData.id}/apikey`)
        .then((response) => setExternalAccessList(response.data?.data || []))
        .catch((error) => toast.error(error.message))
        .finally(() => setIsLoadingGetApiKey(false));
    }
    fetchData();
  }, [isModalOpen, assistantData.id]);

  const removeKeyHandler = () => {
    if (Object.keys(assistantToRemove).length == 0) {
      toast.info("None api key found to be deleted");
      return;
    }

    api
      .delete(`v1/assistant/${assistantData.id}/apikey/${assistantToRemove.id}`)
      .then(() => {
        setExternalAccessList((accessList) =>
          accessList.filter((_, i) => i !== assistantToRemove.index)
        );
        toast.success("Api Key successfully removed!");
      })
      .catch((error) => toast.error(error.message));
  };

  const addKeyHandler = () => {
    setIsModalOpen(true);
  };

  const modalCloseHandler = () => {
    setIsModalOpen(false);
  };

  const handleCancelConfirm = () => {
    setOpenConfirmDelete(false);
    setAssistantToRemove({});
  };

  const handleDeleteAssistant = () => {
    setOpenConfirmDelete(false);
    removeKeyHandler();
  };

  const handleOpenDeleteConfirm = (index, id) => {
    setAssistantToRemove({ index, id });
    setOpenConfirmDelete(true);
  };

  return (
    <div className={styles["api-keys-container"]}>
      <div>API Keys</div>
      <div className="explanation">
        Sharing an assistant as an API allows developers to integrate this LARA
        Assistant into their own applications.
      </div>
      {isLoadingGetApiKey && (
        <Box sx={{ display: "flex", margin: 1 }}>
          <CircularProgress size={30} />
        </Box>
      )}
      <div className={styles["external-access-list"]}>
        {externalAccessList.map((access, index) => {
          const key = `access-${access.apiKey}${index}`;
          return (
            <div key={key} className={styles["external-access-item"]}>
              <div className={styles["external-access-field"]}>
                <strong>Client Name:</strong> {access.clientName}
              </div>
              <div className={styles["external-access-field"]}>
                <strong>API Key:</strong> {access.apiKey}
              </div>
              <div className={styles["external-access-field"]}>
                <strong>Expiration Date:</strong> {access.expirationDate}
              </div>
              <div className={styles["external-access-field"]}>
                <Button
                  type="button"
                  color="red"
                  // onClick={() => removeKeyHandler(index, access.id)}
                  onClick={() => handleOpenDeleteConfirm(index, access.id)}
                >
                  Remove
                </Button>
              </div>
            </div>
          );
        })}
      </div>

      <Button
        type="button"
        onClick={addKeyHandler}
        disabled={isReadOnly || assistantData.id === "new"}
        style={{ marginTop: "4px" }}
      >
        Add API Key
      </Button>

      {isModalOpen && (
        <AddExternalAccessForm
          open={isModalOpen}
          onClose={modalCloseHandler}
          assistantData={assistantData}
        />
      )}

      <Confirm
        header={"Delete AI Assistant API Key"}
        open={openConfirmDelete}
        onCancel={handleCancelConfirm}
        onConfirm={handleDeleteAssistant}
        cancelButton="Cancel"
        confirmButton="Yes, delete it"
        size="tiny"
      />
    </div>
  );
}
