import { useState } from 'react'
import { Card, Typography, Button, Box, useTheme } from '@mui/material'
import useMsalStore from '../../hooks/msalStore'
import { Description, Download } from '@mui/icons-material'
import { toast } from 'react-toastify'

export default function BasicCard({ fileName, fileUrl }) {
  const { getMsalConfigurations } = useMsalStore()
  const [loading, setLoading] = useState(false)
  const theme = useTheme()

  const handleDownload = async () => {
    try {
      setLoading(true)
      const msalConfigurations = await getMsalConfigurations()

      const response = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${msalConfigurations.accessToken}`
        }
      })

      if (!response.ok)
        throw new Error('Failed while trying to download the file')

      const blob = await response.blob()
      const regex = /(?<=\/file\/)([^\/]+)(?=\/[^\/]*$)/
      const match = fileUrl.match(regex)
      fileName = match ? match[0] : 'downloaded-file'

      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()

      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (error) {
      toast.error('Failed while trying to download the file')
      console.error('Failed while trying to download the file:', error)
    }

    setLoading(false)
  }

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        gap: 2,
        boxShadow: theme.shadows[3],
        borderRadius: '12px',
        backgroundColor: '#3d414d',
        maxWidth: '100%',
        overflow: 'hidden',
        border: '1px solid rgb(137, 137, 141, 0.5)'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: 70,
          minHeight: 75,
          backgroundColor: '#2b2d33',
          borderRadius: '8px'
        }}
      >
        <Description sx={{ fontSize: 40, color: '#fff' }} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center'
        }}
      >
        <Typography
          noWrap
          sx={{
            color: '#fff',
            fontSize: 16,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginTop: '0 !important'
          }}
        >
          {fileName.replace('Download', '')}
        </Typography>

        <Button
          size="small"
          onClick={handleDownload}
          variant="outlined"
          startIcon={<Download />}
          loading={loading}
          sx={{
            marginTop: 1,
            alignSelf: 'flex-start',
            color: '#ccc',
            borderColor: '#ccc',
            ':hover': {
              backgroundColor: '#2b2d33',
              borderColor: '#ccc',
              color: theme.palette.primary.contrastText
            }
          }}
        >
          Download
        </Button>
      </Box>
    </Card>
  )
}
