import { msalConfig } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);

const initializeMSAL = async () => {
  try {
    await msalInstance.initialize();
  } catch (error) {
    // TODO
    // Should we take any action if you have any problems with MSAL initialization?
    // Failed to initialize MSAL:
  }
}

export { msalInstance, initializeMSAL };
