import { useState, useEffect } from 'react';  
import './ToggleButton.css';  
import { Tooltip } from 'react-tooltip';

function ToggleButton({ onToggleHandler, defaultModel }) {  
    const models = [  
        // { name: 'GPT-3.5-turbo', id: 1, max_tokens: 4096, cost: 'low', intelligence: 'very good – general use' },  
        // { name: 'GPT-3.5-turbo-16k', id: 1, max_tokens: 16384, cost: 'low', intelligence: 'Very good. The most used model. Knowledge up to Sep/2021.' },  
        // { name: 'GPT-4', id: 3, max_tokens: 8192, cost: 'high (30x GPT-3.5-turbo)', intelligence: 'great – solve complex problems' },
        // { name: 'GPT-4-32k', id: 2, max_tokens: 32000, cost: 'high (60x GPT-3.5-turbo)', intelligence: 'great – solve complex problems' },
        // { name: 'GPT-4-turbo', id: 3, max_tokens: 128000, cost: 'high (20x GPT-3.5-turbo)', intelligence: 'great – solve complex problems, largest memory, fresh knowledge (Apr/2023).' },
        { name: 'GPT-4o-mini', id: 1, max_tokens: 64000, cost: 'lowest', intelligence: 'Very good – solve problems, great memory, knowledge cutoff Sep/2023, multi-modal.' },
        { name: 'GPT-4o', id: 3, max_tokens: 128000, cost: 'high (17x gtp-4o-mini)', intelligence: 'Greatest – solve complex problems, largest memory, knowledge cutoff Sep/2023, multi-modal.' },
        { name: 'Gemini-1.5-Pro', id: 4, max_tokens: 1000000, cost: 'very high (34x GPT-4o-mini)', intelligence: 'great, with multi-modal capabilities (text, audio, images, video)' },
        { name: 'Gemini-2.0-Flash', id: 5, max_tokens: 1000000, cost: 'moderate', intelligence: 'great, with multi-modal capabilities (text, audio, images, video) and realtime streaming.' },
        { name: 'Gemini-2.0-Flash-Lite', id: 6, max_tokens: 1000000, cost: 'low', intelligence: 'great, with real time streaming' },
        { name: 'Gemini-2.5-Pro (Experimental)', id: 7, max_tokens: 1000000, cost: 'free', intelligence: 'reason over complex problems, tackle difficult code, math and STEM problems, long context.' },
        // { name: 'o1-mini', id: 5, max_tokens: 128000, cost: 'high', intelligence: 'Advanced reasoning (text only).' },
        // { name: 'o1-preview', id: 6, max_tokens: 128000, cost: 'super high', intelligence: 'Most advanced reasoning (text only).' },
        { name: 'o1', id: 9, max_tokens: 128000, cost: 'highest', intelligence: 'Advanced reasoning (text only).' },
        { name: 'o3-mini', id: 9, max_tokens: 64000, cost: 'high', intelligence: 'Efficient reasoning with moderate memory (text only).' },

    ];
    const [toggle, setToggle] = useState(0);  

    useEffect(() => {
        const defaultModelIndex = models.findIndex((model) => model.name?.toLowerCase() === defaultModel?.toLowerCase());
        if (defaultModelIndex !== -1) {
            setToggle(defaultModelIndex);
            onToggleHandler(models[defaultModelIndex].name);
        }
        
    }, [defaultModel]);

    const handleToggle = () => {  
        const nextToggle = (toggle + 1) % models.length;  
        setToggle(nextToggle);  
        onToggleHandler(models[nextToggle].name);  
    };  
    return (
        <div className="toggle-button-area">
            <button 
                className={`toggle-button ${
                    models[toggle].name.startsWith('GPT-4') 
                        ? 'chartreuse' 
                        : models[toggle].name.startsWith('o1') || models[toggle].name.startsWith('o3-mini') 
                        ? 'purple' 
                        : ''
                }`}
                onClick={handleToggle}
            >
            {models[toggle].name}
            </button>
            <Tooltip anchorSelect='.toggle-button' place="right" style={{"zIndex":12, "backgroundColor": "#1F728B"}}>
            <div className="model-toggle-button-container">
                <i>Click to toggle between models</i>
                
                <h4>{models[toggle].name}</h4>
                <strong>{models[toggle].intelligence}</strong>
                <br />
                Max tokens: {models[toggle].max_tokens.toLocaleString()}
                <br />
                More tokens = more short-term memory
                <br />
                Cost: {models[toggle].cost}
                
                </div>
            </Tooltip>

            <div className="toggle-button__model_details">
                <strong>Click to toggle between models</strong>
                
                <h3>{models[toggle].name}</h3>
                
                Max tokens: {models[toggle].max_tokens.toLocaleString()}
                <br />
                More tokens = more short-term memory
                <br />
                Cost: {models[toggle].cost}
                <br /> 
                <strong>{models[toggle].intelligence}</strong>
            </div>
        </div>  
    );  
}  

export default ToggleButton;  