import './MessagesArea.css'
import logo from './images/AI3-192.png'
import maeAssistLogo from './images/mae_assist.jpg'
import { React, useState, useEffect, useRef, useCallback } from 'react'
import Markdown from 'react-markdown'
import ThumbsButtons from './ThumbsButtons'
//import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus as myStyle } from 'react-syntax-highlighter/dist/esm/styles/prism'
import LoadingSpinner from './LoadingSpinner'
//import langdetect from "langdetect";
import { FaVolumeUp } from 'react-icons/fa'
// import { FaRegCopy } from "react-icons/fa6";
//import { MarkdownToHtml } from './MarkdownToHtml';
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import remarkGfm from 'remark-gfm'
import { GrCopy } from 'react-icons/gr'
import { FaRegCopy, FaTrashAlt } from 'react-icons/fa'
import { set } from 'react-hook-form'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css' // Import KaTeX CSS for styling
import useStore from '../hooks/useStore'
import IconSpinner, { IconSpinner2 } from './UI/Icons'
import { marked } from 'marked'
import Card from '../components/UI/Card'
import Base64ImageViewer from '../components/UI/Base64ImageViewer'

function TextToSpeech({ text, handleSpeak }) {
  const handleClick = () => {
    // const value = new SpeechSynthesisUtterance(text);
    // const voices = speechSynthesis.getVoices();
    // //const detectedLanguage = langdetect.detect(text);
    // const selectedVoice = voices.find((voice) => voice.lang.startsWith('en-US'));

    // if (selectedVoice) {
    //   value.voice = selectedVoice;
    // }

    // value.rate = 0.9; // Adjust the rate as needed
    // value.pitch = 1.2; // Adjust the pitch as needed
    // window.speechSynthesis.speak(value);

    handleSpeak(text)
  }
  return (
    <button onClick={handleClick} className="speak-button">
      <FaVolumeUp size={15} />
    </button>
  )
}

function CodeBlock({ codeString, language }) {
  const [isClicked, setIsClicked] = useState(false)

  function handleClick() {
    if (!isClicked) {
      setIsClicked(true)
    }
    navigator.clipboard.writeText(codeString)
  }

  return (
    <>
      <div className="code-header">
        <span>{language}</span>
        {!isClicked && (
          <div className="copy-icon-button" onClick={handleClick}>
            Copy <FaRegCopy color="white" className="copy-icon" />
          </div>
        )}
        {/* <svg onClick={handleClick} className="copy-icon" stroke="gray" fill="transparent" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em">
            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" fill="transparent"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1" fill="transparent"></rect>
          </svg>} */}

        {isClicked && (
          <div className="code-header-copy">
            <div className="copy-icon-button" onClick={handleClick}>
              Copied
              <svg
                onClick={handleClick}
                className="copy-icon"
                height="1em"
                width="1em"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#ffffff"
                fill="transparent"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" fill="transparent" />
              </svg>
            </div>
          </div>
        )}
      </div>
      <SyntaxHighlighter
        language={language}
        style={myStyle}
        className="code-body"
      >
        {codeString}
      </SyntaxHighlighter>
    </>
  )
}
function fixMarkdownImage(text) {
  if (!text) {
    return text
  }
  //returns the reviewed Markdown text with a new line after each Markdown image.
  const regex = /!\[.*?\]\((.*?)\)/g
  const matches = text.match(regex)

  if (matches) {
    return text.replace(regex, (match) => {
      return match + '\n'
    })
  } else {
    return text
  }
}

function Message({
  message,
  darkMode,
  onThumbsFeedback,
  isLoading,
  handleSpeak,
  userPhoto,
  firstName,
  lastName,
  onUserMessageDelete
}) {
  const [loadingSpinner, setLoadingSpinner] = useState(false)
  const [hiddenAiIcon, setHiddenAiIcon] = useState(false)
  const [myMarkdown, setMyMarkdown] = useState('')
  const appName = useStore((state) => state.appName)

  if (!userPhoto || userPhoto === undefined) {
    let first = firstName ? firstName[0] : ''
    let last = lastName ? lastName[0] : ''

    const initials = (first + last).toUpperCase()
    userPhoto = `https://ui-avatars.com/api/?name=${initials}&background=random`
  }

  useEffect(() => {
    if (isLoading) {
      setLoadingSpinner(true)
    } else {
      setLoadingSpinner(false)
      // remove ``` from the end of the message
      if (myMarkdown.endsWith('\n```')) {
        setMyMarkdown(myMarkdown.slice(0, -4))
      }
    }
  }, [isLoading])

  useEffect(() => {
    // count how many instances of ``` are in the message.
    const count = (message.text?.match(/```/g) || []).length
    // if the count is odd, add a ``` at the end of the message
    if (count % 2 === 1) {
      setMyMarkdown(fixMarkdownImage(message.text) + '\n```')
    } else {
      setMyMarkdown(fixMarkdownImage(message.text))
    }
  }, [message.text])

  useEffect(() => {
    if (window.innerWidth < 768) {
      setHiddenAiIcon(true)
    }
    const handleResize = () => {
      // Check the window width and set the default collapse state accordingly
      if (window.innerWidth < 768) {
        setHiddenAiIcon(true)
      } else {
        setHiddenAiIcon(false)
      }
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // const renderers = {
  //   table: ({children}) => <table>{children}</table>,
  //   tableHead: ({children}) => <thead>{children}</thead>,
  //   tableBody: ({children}) => <tbody>{children}</tbody>,
  //   tableRow: ({children}) => <tr>{children}</tr>,
  //   tableCell: ({isHeader, children}) => isHeader ? <th>{children}</th> : <td>{children}</td>
  // };

  // const markdownText = message.text;
  // const markdownText = myMarkdown;
  const markdownText = myMarkdown
    ?.replace(/\n(\s+)```/g, '\n```')
    .replace(/^```/g, '\n```')

  /*--------------------------------------------*/
  /* the code below splits text and code blocks */
  /*--------------------------------------------*/
  const codeBlockRegex = /((?<=\n|^)```\w*\n[\s\S]*?\n```)/g

  let splitMarkdown = []
  let match
  let lastIndex = 0

  while ((match = codeBlockRegex.exec(markdownText)) !== null) {
    const matchedText = match[0]
    const index = match.index

    if (index !== 0) {
      const text = markdownText.substring(lastIndex, index)
      splitMarkdown.push(text)
    }

    splitMarkdown.push(matchedText)
    lastIndex = index + matchedText.length
  }

  if (lastIndex < markdownText?.length) {
    const text = markdownText.substring(lastIndex)
    splitMarkdown.push(text)
  }
  /*--------------------------------------------*/
  // const treatedMessage will use regex to find \n that is not preceded by \n or by two spaces and will replace it by two spaces and \n. This is to correct some issues with Markdown rendering.
  function treatedMessage(message) {
    const regex = /(?<!\n)(?<! {2})\n/g
    let result = message.replace(regex, '  \n')
    // let result = message;

    // detect Markdown images and replace them with html images, limiting the size to 256x256
    // const imageRegex = /!\[.*\]\((.*)\)/g
    // const imagemMatches = result.match(imageRegex)
    // if (imagemMatches?.length) {
    // }
    // result = result.replace(
    //   imageRegex,
    //   '<div class="markdown-image"><img src="$1" alt="Markdown image" /><div>'
    // )

    // detect non-image Markdown links and add target="_blank" to make them open in a separate tab
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g
    const matches = result.match(linkRegex)
    if (matches?.length === 2) {
      result = result.replace(matches[0], '')
    }

    // result = fixMarkdownImage(result)

    return result
  }

  const convertMarkdownToHTML = (markdown) => {
    return marked(markdown)
  }

  async function copyToClipboard(markdownText, htmlText) {
    try {
      // Create a new ClipboardItem with both plain text (Markdown) and HTML formats
      const clipboardItem = new ClipboardItem({
        'text/plain': new Blob([markdownText], { type: 'text/plain' }), // Markdown as plain text
        'text/html': new Blob([htmlText], { type: 'text/html' }) // HTML format
      })

      // Write the ClipboardItem to the clipboard
      await navigator.clipboard.write([clipboardItem])
    } catch (err) {
      toast.error('Failed to copy to clipboard')
    }
  }

  const copyAiResponse = () => {
    const text = message.text
    // navigator.clipboard.writeText(text);
    copyToClipboard(text, marked(text))
  }

  // const LinkRenderer = ({ href, children }) => {
  //   // safely open the link in a new tab
  //   if (href !== undefined && href.startsWith("http")) {
  //     return (

  //       <a href={href} target="_blank" rel="noopener noreferrer">
  //         {children}
  //       </a>
  //     );
  //   } else {
  //     return <a href={`${href}`}>{children}</a>;
  //   }
  // };

  const RenderDownloadCard = useCallback((children, href) => {
    return <Card fileName={children} fileUrl={href} />
  }, [])

  const LinkRenderer = ({ href, children }) => {
    const appEnv = process.env.REACT_APP_API_URL
    if (href !== undefined && href.startsWith(appEnv)) {
      return RenderDownloadCard(children, href)
    } else {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )
    }
  }

  const ImageRenderer = useCallback(({ src, alt }) => {
    const appEnv = process.env.REACT_APP_API_URL
    if (src !== undefined && src.startsWith(appEnv)) {
      return <Base64ImageViewer fileUrl={src} altText={alt} />
    } else {
      return <img src={`${src}`} alt={alt} />
    }
  }, [])

  const handleUserMessageDelete = () => {
    // user messages don't have the messageId property, so let's scan for previous assistant messages
    onUserMessageDelete(message)
  }

  return (
    <>
      {hiddenAiIcon && message.type === 'ai' && (
        <div
          className={`message-titlebar-grid ${darkMode ? 'dark-mode' : ''}`}
          style={
            message.type === 'ai'
              ? { backgroundColor: darkMode ? '#32333d' : 'whitesmoke' }
              : {}
          }
        >
          <div
            className="collapsed-AI-icon"
            style={{ backgroundColor: darkMode ? '#32333d' : 'whitesmoke' }}
          >
            <img
              src={appName === 'Mae Assist' ? maeAssistLogo : logo}
              alt="AI icon"
              className="AI-chat-logo-small"
            />{' '}
            {appName}
          </div>
          <div className="copy-button-container" onClick={copyAiResponse}>
            <FaRegCopy />
          </div>
          <ThumbsButtons
            onThumbsFeedback={onThumbsFeedback}
            messageId={message.messageId}
            sentiment={message.feedback?.sentiment}
            darkMode={darkMode}
          />
        </div>
      )}

      {hiddenAiIcon && message.type === 'user' && (
        <div
          className={`message-titlebar-grid ${darkMode ? 'dark-mode' : ''}`}
          style={
            message.type === 'ai'
              ? { backgroundColor: darkMode ? '#32333d' : 'whitesmoke' }
              : {}
          }
        >
          <div className="collapsed-AI-icon">
            <img src={userPhoto} className="AI-chat-logo-small" /> {firstName}
          </div>
        </div>
      )}
      <div
        className={`message-grid ${darkMode ? 'dark-mode' : ''} ${
          hiddenAiIcon ? 'collapsed' : ''
        }`}
        style={
          message.type === 'ai'
            ? { backgroundColor: darkMode ? '#32333d' : 'whitesmoke' }
            : {}
        }
      >
        {message.type === 'ai' && !hiddenAiIcon && (
          <div className="AI-chat-logo-container">
            <img
              src={appName === 'Mae Assist' ? maeAssistLogo : logo}
              alt="AI icon"
              className="AI-chat-logo"
            />
          </div>
        )}
        {message.type === 'ai' && hiddenAiIcon && (
          <div className="AI-chat-logo-container">
            {/* leave this space empty */}
          </div>
        )}

        {message.type === 'user' && !hiddenAiIcon && (
          <div key={9999997} className="AI-chat-logo-container">
            {/* leave this space empty */}
            {message.type === 'user' && (
              <div className="user-photo-48x48">
                <img src={userPhoto} />
              </div>
            )}
          </div>
        )}
        {message.type === 'user' && hiddenAiIcon && (
          <div className="AI-chat-logo-container">
            {/* leave this space empty */}
          </div>
        )}
        {(message.type === 'user' || message.type === 'ai') && (
          <div className="message-content">
            {
              // extract al code blocks from message.text and render them with CodeBlock. Let's make sure that ``` is always followed by a new line
              splitMarkdown.map((text, i) => {
                if (i % 2 === 0) {
                  return (
                    <div key={i}>
                      {/* {loadingSpinner && <div className="loading-spinner" />} */}
                      <Markdown
                        remarkPlugins={[remarkGfm, remarkMath]}
                        rehypePlugins={[rehypeRaw, rehypeSanitize]}
                        components={{
                          a: LinkRenderer,
                          img: ImageRenderer
                        }}
                      >
                        {treatedMessage(text)}
                      </Markdown>
                      {/* {loadingSpinner && <div>Thinking <IconSpinner2 className="thinking"/></div>}  */}
                      <div>&nbsp;</div>
                      <TextToSpeech
                        text={treatedMessage(text)}
                        handleSpeak={handleSpeak}
                      />
                    </div>
                  )
                } else {
                  //eliminate the first three characters (```) and the last three characters (```) and use the first line to determine the language
                  text = text.slice(3, -3)

                  let language = text.split('\n')[0]
                  if (language === '') {
                    language = ''
                  }
                  text = text.split('\n').slice(1).join('\n')
                  return (
                    <CodeBlock key={i} codeString={text} language={language} />
                  )
                }
              })
            }
          </div>
        )}

        {message.type === 'ai' && !hiddenAiIcon && (
          <>
            <div className="copy-button-container" onClick={copyAiResponse}>
              <FaRegCopy />
            </div>
            <ThumbsButtons
              onThumbsFeedback={onThumbsFeedback}
              messageId={message.messageId}
              sentiment={message.feedback?.sentiment}
              comment={message.feedback?.comment}
            />
          </>
        )}

        {message.type !== 'ai' && (
          <>
            <div
              className="copy-button-container-user-message"
              onClick={copyAiResponse}
            >
              <FaRegCopy />
            </div>
            {message.messageId > 2 && (
              <div
                className="delete-icon-button-container"
                onClick={handleUserMessageDelete}
              >
                <FaTrashAlt />
              </div>
            )}
          </>
        )}
        <div></div>
        <div></div>
        <div>
          {loadingSpinner && (
            <div className="thinking">
              Thinking&nbsp;
              <IconSpinner2 />
            </div>
          )}
          <br />
          <br />
        </div>
      </div>
    </>
  )
}

export function MessagesArea({
  darkMode,
  messages,
  onThumbsFeedback,
  isLoading,
  handleSpeak,
  onScroll,
  initIsContentAtBottom,
  userPhoto,
  firstName,
  lastName,
  handleUserMessageDelete
}) {
  const [isContentAtBottom, setIsContentAtBottom] = useState(
    initIsContentAtBottom
  )

  const messagesContainerRef = useRef(null)

  function handleScroll() {
    const messagesContainer = messagesContainerRef.current
    if (messagesContainer) {
      const isAtBottom =
        messagesContainer.scrollHeight -
          messagesContainer.scrollTop -
          messagesContainer.clientHeight <=
        10
      if (isAtBottom !== messagesContainerRef.isContentAtBottom) {
        onScroll(isAtBottom)
      }
      setIsContentAtBottom(isAtBottom)
    }
  }

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current
    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    handleScroll()
  }, [messages])

  return (
    <div
      className="messages-container"
      id="messages-container"
      ref={messagesContainerRef}
    >
      {messages.map((message, i) => (
        <Message
          key={i}
          message={message}
          darkMode={darkMode}
          onThumbsFeedback={onThumbsFeedback}
          isLoading={isLoading && i === messages.length - 1}
          handleSpeak={handleSpeak}
          userPhoto={userPhoto}
          firstName={firstName}
          lastName={lastName}
          onUserMessageDelete={handleUserMessageDelete}
        />
      ))}
    </div>
  )
}
