import React, { useState, useEffect } from 'react';
import './PromptStudio.css';
import { IoCloseSharp } from "react-icons/io5";
import Dropdown from 'react-bootstrap/Dropdown';



const roles = [
  'a tech-savvy digital marketer',
  'an insightful market researcher',
  'a strategic business analyst',
  'a dynamic event organizer',
  'a tech savvy SEO specialist',
  'a versatile project engineer',
  'an insightful marketing analyst',
  'a React developer assistant',
  'a Java developer assistant',
  'a C# developer assistant',
  'a Python developer assistant',
  'a Node.js developer assistant'
];


const tones = [
  'professional',
  'friendly',
  'helpful',
  'inspiring',
  'motivating',
  'encouraging',
  'supportive',
  'informative',
  'educational',
  'persuasive',
  'convincing',
  'exciting',
  'entertaining',
  'funny',
  'humorous',
  'cutting-edge'
];

const writingStyles = [
  'blog post',
  'article',
  'press release',
  'product description',
  'product review',
  'email',
  'newsletter',
  'social media post',
  'professional',
  'informative',
  'educational',
  'descriptive',
  'persuasive',
  'storytelling',
  'humorous',
  'casual',
  'conversational'
];

const outputFormats = [
  'table',
  'list',
  'summary',
  'plain text',
  'JSON',
  'HTML',
  'CSV',
  'Markdown',
  'Image',
  'Power Point',
  'Spreadsheet'
];


export function PromptStudio({ onTestPrompt, onClose, onSaveConversationStarter, onGetAiCompletion }) {
  const [prompt, setPrompt] = useState('');
  const [selectedModel, setSelectedModel] = useState('default');
  const [role, setRole] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [tone, setTone] = useState('');
  const [requirements, setRequirements] = useState('');
  const [tasks, setTasks] = useState('');
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [writingStyle, setWritingStyle] = useState('');
  const [outputFormat, setOutputFormat] = useState('');
  const [reminder, setReminder] = useState('');
  const [knowledgeBase, setKnowledgeBase] = useState('');

  useEffect(() => {
    // if any of the fields change, update the prompt
    handleGeneratePrompt();
  }, [selectedModel, role, tone, requirements, tasks, knowledgeBase, additionalDetails, writingStyle, outputFormat, reminder]);

  const handleGeneratePrompt = () => {
    // Logic to generate the prompt based on user inputs
    // Call API or perform necessary operations

    // Update state with generated prompt
    const generatedPrompt = `${role} ${tone} ${requirements} Think step-by-step: ${tasks} ${knowledgeBase} ${additionalDetails} ${writingStyle} ${outputFormat}`;
    setPrompt(generatedPrompt);
  };

  const handleTestButton = () => {
    onTestPrompt(prompt);
  }

  const handleRoleSelection = (eventKey) => {
    setRole(`Act as ${eventKey}.`);
  }

  const handleSaveConversationStarter = () => {
    // Logic to save the generated prompt as a conversation starter
    // Call API or perform necessary operations

    // Update state with generated prompt
    handleGeneratePrompt();
    onSaveConversationStarter(prompt);
  }

  return (
    <div className="prompt-studio-container">
      <div className="close-icon-container">
        <div className="close-icon" onClick={onClose}><IoCloseSharp size={24}/></div>
      </div>
      <div className="prompt-studio-header">
          <h1>PromptStudio Designer</h1>
      </div>
      <div className="prompt-studio-panel">

        <div className="text-dropdown-group">
          <input className="prompt-studio-field" type="text" value={role} onChange={(e) => setRole(e.target.value)} placeholder="Act as ___" />
          <Dropdown onSelect={handleRoleSelection}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Roles
            </Dropdown.Toggle>
            <Dropdown.Menu>
              
              {roles.map((role, index) => (
                <Dropdown.Item key={index} eventKey={role}>
                  {role}
                </Dropdown.Item>
              ))}
        
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="text-dropdown-group">
          
          <input className="prompt-studio-field" type="text" value={tone} onChange={(e) => setTone(e.target.value)} placeholder="Use a ___ tone" />
          <Dropdown onSelect={(e) => setTone(`Use a ${e} tone.`)}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Tones
            </Dropdown.Toggle>
            <Dropdown.Menu>
              
              {tones.map((tone, index) => (
                <Dropdown.Item key={index} eventKey={tone}>
                  {tone}
                </Dropdown.Item>
              ))}
        
            </Dropdown.Menu>
          </Dropdown>

        </div>
        <div>
          <label>Requirements:</label>
          <textarea className="prompt-studio-field" value={requirements} onChange={(e) => setRequirements(e.target.value)} placeholder="Purpose (be specific)" />
        </div>
        <div>
          <label>Tasks:</label>
          <textarea className="prompt-studio-field" value={tasks} onChange={(e) => setTasks(e.target.value)} placeholder="First, ..." />
        </div>
        {/* writing styles */}
        <div className="text-dropdown-group">
          <input className="prompt-studio-field" type="text" value={writingStyle} onChange={(e) => setWritingStyle(e.target.value)} placeholder="Use a ___ writing style" />
          <Dropdown onSelect={(e) => setWritingStyle(`Use a ${e} writing style.`)}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Writing Styles
            </Dropdown.Toggle>
            <Dropdown.Menu>
              
              {writingStyles.map((writingStyle, index) => (
                <Dropdown.Item key={index} eventKey={writingStyle}>
                  {writingStyle}
                </Dropdown.Item>
              ))}
        
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="text-dropdown-group">
          <input className="prompt-studio-field" type="text" value={outputFormat} onChange={(e) => setOutputFormat(e.target.value)} placeholder="Output format" />
          <Dropdown onSelect={(e) => setOutputFormat(`Output format: ${e}. `)}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Output Formats
            </Dropdown.Toggle>
            <Dropdown.Menu>
              
              {outputFormats.map((outputFormat, index) => (
                <Dropdown.Item key={index} eventKey={outputFormat}>
                  {outputFormat}
                </Dropdown.Item>
              ))}
        
            </Dropdown.Menu>
          </Dropdown>
        </div>


        <div>
          <label>Additional Details:</label>
          <input className="prompt-studio-field" type="text" value={additionalDetails} onChange={(e) => setAdditionalDetails(e.target.value)} />
        </div>

      </div>

      {/* <button onClick={handleGeneratePrompt}>Generate Prompt</button> */}
        <div>
          <h2>Generated Prompt:</h2>
          <p className="prompt">{prompt}</p>
      </div>

      {prompt &&
        <div className="prompt-studio-buttons-container">
          <div className="test-button-container">
            <button className="test-button" onClick={handleTestButton}>Test!</button>
          </div>
          {/* Copy button (copies the prompt) */}
          <div className="copy-button-container1">
            <button className="copy-button1" onClick={() => navigator.clipboard.writeText(prompt)}>Copy</button>
          </div> 
          {/* < div className="save-button-container">
            <button className="save-button" onClick={handleSaveConversationStarter} >Save as a Conversation Starter</button>
          </div> */}
        </div>

      }

      

    </div>
  );
};
