import * as Sentry from '@sentry/react'

const apiUrl = process.env.REACT_APP_API_URL

if (!apiUrl.includes('localhost') && !apiUrl.includes('dev')) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: []
  })
}
