import React from 'react'
import { useState, useCallback, useEffect } from 'react'
import {
  Card,
  CardMedia,
  IconButton,
  Typography,
  Box,
  LinearProgress
} from '@mui/material'
import { CloudDownload } from '@mui/icons-material'
import useMsalStore from '../../hooks/msalStore'
import { toast } from 'react-toastify'

const Base64ImageViewer = ({ fileUrl, altText = 'Markdown image' }) => {
  const [loading, setLoading] = useState(false)
  const [loadingImage, setLoadingImage] = useState(false)
  const [base64String, setBase64String] = useState(null)
  const [fileExtension, setFileExtension] = useState('png')
  const { getMsalConfigurations } = useMsalStore()

  useEffect(() => {
    if (base64String) return

    fetchImage(fileUrl)
  }, [fileUrl, base64String])

  const fetchImage = useCallback(async (url) => {
    setLoadingImage(true)
    const msalConfigurations = await getMsalConfigurations()
    setFileExtension(
      url
        .split('.')
        .pop()
        .replace(/[^a-zA-Z]/g, '')
    )

    try {
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${msalConfigurations.accessToken}`
        }
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.statusCode !== 200)
            toast.error('Failed while trying to download the file')

          setBase64String(response.file)
          setLoadingImage(false)
        })
    } catch (error) {
      setLoadingImage(false)
      console.error('Failed while trying to download the file:', error)
    }
  }, [])

  const handleDownload = useCallback(async () => {
    setLoading(true)

    const byteArray = Uint8Array.from(atob(base64String), (c) =>
      c.charCodeAt(0)
    )

    const blob = new Blob([byteArray], { type: `image/${fileExtension}` })
    const blobUrl = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = blobUrl
    link.download = `${crypto.randomUUID()}.${fileExtension}`
    link.click()

    URL.revokeObjectURL(blobUrl)

    setLoading(false)
  }, [base64String, fileExtension])

  if (!fileUrl)
    return <Typography color="error">None image available</Typography>

  return loadingImage ? (
    <Box sx={{ position: 'relative', width: '100%', margin: '2em 0' }}>
      <Typography
        sx={{
          position: 'absolute',
          top: 0,
          left: '50%',
          color: '#89898d80',
          fontSize: '0.5em',
          transform: 'translate(-50%, -50%)'
        }}
      >
        Loading Image...
      </Typography>
      <LinearProgress color="inherit" />
    </Box>
  ) : (
    <Card
      sx={{
        maxWidth: '100vw',
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative'
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: '100%', height: 'fit-content' }}
        image={`data:image/png;base64,${base64String}`}
        alt={altText}
      />
      <IconButton
        disabled={loading} // Corrigi "loading" para "disabled", pois IconButton não possui uma prop "loading"
        onClick={handleDownload}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          color: '#ccc',
          borderRadius: 0,
          backgroundColor: loading ? '#2b2d33' : 'transparent',
          ':hover': {
            borderColor: '#ccc',
            color: '#fff'
          },
          '&.Mui-disabled': {
            backgroundColor: '#2b2d33'
          },
          '& .MuiCircularProgress-root': {
            color: 'white'
          }
        }}
      >
        <CloudDownload fontSize="large" />
      </IconButton>
    </Card>
  )
}

export default Base64ImageViewer
