import { useState, useEffect, useRef } from 'react'
import './Panel.css'
import { FaHome } from 'react-icons/fa'
import { BsChatLeftText } from 'react-icons/bs'
import Markdown from 'react-markdown'
//import { set } from 'react-hook-form';
import Username from './Username'
import { PiUserSwitchDuotone } from 'react-icons/pi'
import useStore from '../hooks/useStore'

//const savedChats = [ {saved_chat: 'Saved Chat 1', favorite: true}, {saved_chat: 'Tell me a joke', favorite:false}, {saved_chat: 'A very long name for a chat', favorite: false}, {saved_chat: 'Saved Chat 4', favorite: false}, {saved_chat: 'Saved Chat 5', favorite: true}, {saved_chat: 'Saved Chat 6', favorite: false}, {saved_chat: 'Saved Chat 7', favorite: false}, {saved_chat: 'Saved Chat 8', favorite: false}, {saved_chat: 'Saved Chat 9', favorite: false}, {saved_chat: 'Saved Chat 10', favorite: false}, {saved_chat: 'Saved Chat 11', favorite: false}, {saved_chat: 'Saved Chat 12', favorite: false}, {saved_chat: 'Saved Chat 13', favorite: false}, {saved_chat: 'Saved Chat 14', favorite: false}, {saved_chat: 'Saved Chat 15', favorite: false}, {saved_chat: 'Saved Chat 16', favorite: false}, {saved_chat: 'Saved Chat 17', favorite: false}, {saved_chat: 'Saved Chat 18', favorite: false}, {saved_chat: 'Saved Chat 19', favorite: false}, {saved_chat: 'Saved Chat 20', favorite: false}]

function SavedChatButton({ chat }) {
  return <div className="saved-chat">{chat.subject}</div>
}

function TrashIconButton({ index, onDeleteHandler }) {
  const [clicked, setClicked] = useState(null)
  function handleClick(event) {
    event.stopPropagation()
    if (!clicked) {
      setClicked(1)
    } else if (clicked && clicked === 1) {
      // process second-click: set background to red
      setClicked(2)
      // delete the chat
      onDeleteHandler(index.headerIndex, index.chatIndex)
    }
  }
  return (
    <div className={`trash-button-container ${clicked ? 'clicked' : ''}`}>
      <button className="trash-button" tabIndex="-1" onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
        </svg>
      </button>
    </div>
  )
}
function StarButton({
  onStarClick,
  index,
  favorite,
  hoveredIndex,
  savedChats,
  onDeleteHandler
}) {
  const [selected, setSelected] = useState(favorite)
  const handleClick = (event) => {
    //event.stopPropagation();
    const newState = !selected
    setSelected(newState)
    onStarClick(index, newState)
  }
  function FavStarButton() {
    return (
      <button className="star-button" tabIndex="-1">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          width="122.879px"
          height="117.188px"
          viewBox="0 0 122.879 117.188"
          enableBackground="new 0 0 122.879 117.188"
        >
          <g>
            <path d="M64.395,1.969l15.713,36.79l39.853,3.575c1.759,0.152,3.06,1.701,2.907,3.459c-0.073,0.857-0.479,1.604-1.079,2.129 l0.002,0.001L91.641,74.25l8.917,39.021c0.395,1.723-0.683,3.439-2.406,3.834c-0.883,0.203-1.763,0.018-2.466-0.441L61.441,96.191 L27.087,116.73c-1.516,0.906-3.48,0.412-4.387-1.104c-0.441-0.736-0.55-1.58-0.373-2.355h-0.003l8.918-39.021L1.092,47.924 c-1.329-1.163-1.463-3.183-0.301-4.512c0.591-0.676,1.405-1.042,2.235-1.087l39.748-3.566l15.721-36.81 c0.692-1.627,2.572-2.384,4.199-1.692C63.494,0.597,64.084,1.225,64.395,1.969L64.395,1.969z M74.967,43.023L61.441,11.351 L47.914,43.023l-0.004-0.001c-0.448,1.051-1.447,1.826-2.665,1.932l-34.306,3.078l25.819,22.545c0.949,0.74,1.438,1.988,1.152,3.24 l-7.674,33.578l29.506-17.641c0.986-0.617,2.274-0.672,3.342-0.033l29.563,17.674l-7.673-33.578l0.003-0.002 c-0.252-1.109,0.096-2.318,1.012-3.119l25.955-22.664L77.815,44.97C76.607,44.932,75.472,44.208,74.967,43.023L74.967,43.023z" />
          </g>
        </svg>
      </button>
    )
  }
  function FavStarButtonFilled() {
    return (
      <button className="star-button selected" tabIndex="-1">
        <svg
          fill="#ffd401"
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 122.88 117.1"
        >
          <title>star-symbol</title>
          <path d="M64.42,2,80.13,38.7,120,42.26a3.2,3.2,0,0,1,1.82,5.62h0L91.64,74.18l8.9,39A3.19,3.19,0,0,1,98.12,117a3.27,3.27,0,0,1-2.46-.46L61.41,96.1,27.07,116.64a3.18,3.18,0,0,1-4.38-1.09,3.14,3.14,0,0,1-.37-2.38h0l8.91-39L1.09,47.88a3.24,3.24,0,0,1-.32-4.52,3.32,3.32,0,0,1,2.29-1l39.72-3.56L58.49,2a3.24,3.24,0,0,1,5.93,0Z" />
        </svg>
      </button>
    )
  }
  return (
    <div className={`star-button-container ${selected ? 'selected' : ''}`}>
      {hoveredIndex.headerIndex === index.headerIndex &&
        hoveredIndex.chatIndex == index.chatIndex && (
          <TrashIconButton
            index={index}
            savedChats={savedChats}
            onDeleteHandler={onDeleteHandler}
          />
        )}
      <div className="star-button" onClick={handleClick}>
        {selected && <FavStarButtonFilled />}
        {!selected && <FavStarButton />}
      </div>
    </div>
  )
}

function NewChatButton({ darkMode, onNewChat }) {
  return (
    <div
      id="new-chat-button"
      onClick={onNewChat}
      className={`new-chat-button ${darkMode ? 'dark-mode' : ''}`}
    >
      <span>New chat</span>&nbsp;&nbsp;
      <FaHome />
    </div>
  )
}

function Tooltip({ children, text }) {
  return (
    <Markdown>
      <div className="tooltip-container">
        {children}
        <div className="tooltip-text">{text}</div>
      </div>
    </Markdown>
  )
}

function PromptDropdown({ darkMode, prompt, prompts, onSelectPrompt }) {
  const [selectedPrompt, setSelectedPrompt] = useState('')
  const [showTooltip, setShowTooltip] = useState(false)
  const iconRef = useRef(null)
  const appName = useStore((state) => state.appName)

  useEffect(() => {
    // set selectedPrompt to the current prompt
    setSelectedPrompt(prompt)
  }, [prompt])

  const handleChange = (event) => {
    setSelectedPrompt(event.target.value)
    onSelectPrompt(event.target.value)
  }

  // Prepare the tooltip text with the current value and its definition
  const selectedPromptObj = prompts.find(
    (eachPrompt) => eachPrompt.name === selectedPrompt
  )
  const promptDefinition =
    selectedPromptObj?.content || 'No definition available'
  const tooltipText = `Select a system prompt to determine ${appName}'s behavior. It is not visible in the conversation.\n\n ## ${
    selectedPrompt || 'None'
  }\n\n Definition: ${promptDefinition}`

  return (
    <div className={`prompt-dropdown-container ${darkMode ? 'dark-mode' : ''}`}>
      <div
        ref={iconRef}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="prompt-icon-container"
      >
        <BsChatLeftText className="prompt-icon" />
        {showTooltip && (
          <div className="tooltip">
            <Markdown>{tooltipText}</Markdown>
          </div>
        )}
      </div>
      <select
        value={selectedPrompt}
        onChange={handleChange}
        className="prompt-dropdown"
      >
        <option value="">Select prompt</option>
        {prompts.map((prompt) => (
          <option key={prompt.name} value={prompt.name}>
            {prompt.name}
          </option>
        ))}
      </select>
    </div>
  )
}

function PanelContentBody({
  darkMode,
  onNewChat,
  savedChats,
  onSavedChatClick,
  onDeleteHandler,
  onStarClick,
  prompt,
  prompts,
  onPromptSelect,
  aiAssistant,
  aiAssistants,
  onAiAssistantSelect
}) {
  const [chatsList, setChatsList] = useState([])
  const [hoveredIndex, setHoveredIndex] = useState({
    headerIndex: null,
    chatIndex: null
  })
  const [selectedIndex, setSelectedIndex] = useState({
    headerIndex: null,
    chatIndex: null
  })

  const onClickHandler = (headerIndex, chatIndex) => {
    setSelectedIndex({ headerIndex, chatIndex })
    onSavedChatClick(headerIndex, chatIndex)
    //trigger a window resize event to hide the panel (the event is handled in Panel)
    window.dispatchEvent(new Event('resize'))
  }

  const onNewChatHandler = () => {
    setSelectedIndex({ headerIndex: null, chatIndex: null })
    onNewChat()
    window.dispatchEvent(new Event('resize'))
  }

  return (
    <div className="panel-content-body">
      <NewChatButton darkMode={darkMode} onNewChat={onNewChatHandler} />
      {/* <PromptDropdown darkMode={darkMode} prompt={prompt} prompts={prompts} onSelectPrompt={onPromptSelect} /> */}
      {/* <AiAssistantDropdown darkMode={darkMode} aiAssistant={aiAssistant} aiAssistants={aiAssistants} onAiAssistantSelect={onAiAssistantSelect} /> */}
      {savedChats.map((header, headerIndex) => (
        <div key={header.header} className="saved-header-container">
          <div className="saved-header">{header.header}</div>
          {header.chats.map((chat, chatIndex) => (
            <div
              key={chat.file}
              className={`saved-chat-container ${
                selectedIndex.headerIndex === headerIndex &&
                selectedIndex.chatIndex === chatIndex
                  ? 'selected'
                  : ''
              }`}
              onMouseEnter={() => {
                setHoveredIndex({ headerIndex, chatIndex })
              }}
              onMouseLeave={() =>
                setHoveredIndex({ headerIndex: null, chatIndex: null })
              }
              onClick={() => onClickHandler(headerIndex, chatIndex)}
            >
              <SavedChatButton chat={chat} />
              {((hoveredIndex.headerIndex === headerIndex &&
                hoveredIndex.chatIndex === chatIndex) ||
                chat.favorite) && (
                <StarButton
                  onStarClick={onStarClick}
                  index={{ headerIndex, chatIndex }}
                  favorite={chat.favorite}
                  hoveredIndex={hoveredIndex}
                  savedChats={savedChats}
                  onDeleteHandler={onDeleteHandler}
                />
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default function Panel({
  darkMode,
  onNewChat,
  savedChats,
  onSavedChatClick,
  onDeleteHandler,
  onStarClick,
  username,
  userPhoto,
  canImpersonate,
  onImpersonate,
  prompt,
  prompts,
  onPromptSelect,
  aiAssistant,
  aiAssistants,
  onAiAssistantSelect,
  onAzureDevOpsUserPAT,
  onSetHidden,
  panelVisibility
}) {
  const [hidden, setHidden] = useState(false)
  const sidePanelRef = useRef(null)

  useEffect(() => {
    setHidden(panelVisibility)
  }, [panelVisibility])

  useEffect(() => {
    const handleResize = () => {
      // Check the window width and set the default collapse state accordingly
      if (window.innerWidth < 768) {
        onSetHidden(true)
      } else {
        onSetHidden(false)
      }
    }

    handleResize()
    const handleClickOutside = (event) => {
      if (
        window.innerWidth < 768 &&
        sidePanelRef.current &&
        !sidePanelRef.current.contains(event.target) &&
        !hidden
      ) {
        onSetHidden(true)
      }
    }

    document.addEventListener('click', handleClickOutside)

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return (
      () => {
        window.removeEventListener('resize', handleResize)
      },
      () => {
        document.removeEventListener('click', handleClickOutside)
      }
    )
  }, [])

  return (
    <>
      {!hidden && (
        <div
          ref={sidePanelRef}
          className={`saved-chats-panel ${hidden ? 'hidden' : ''} ${
            darkMode ? 'dark-mode' : ''
          }`}
        >
          <div className="panel-header">
            <Username
              username={username}
              photo={userPhoto}
              onAzureDevOpsUserPAT={onAzureDevOpsUserPAT}
              darkmode={darkMode}
              onSetHidden={onSetHidden}
            />{' '}
            {canImpersonate && (
              <PiUserSwitchDuotone
                className="impersonate-icon"
                onClick={onImpersonate}
              />
            )}
          </div>

          <div className="panel-body">
            <div className="panel-content">
              <div className="panel-content-header"></div>
              <PanelContentBody
                darkMode={darkMode}
                onNewChat={onNewChat}
                savedChats={savedChats}
                onSavedChatClick={onSavedChatClick}
                onDeleteHandler={onDeleteHandler}
                onStarClick={onStarClick}
                prompt={prompt}
                prompts={prompts}
                onPromptSelect={onPromptSelect}
                aiAssistant={aiAssistant}
                aiAssistants={aiAssistants}
                onAiAssistantSelect={onAiAssistantSelect}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
