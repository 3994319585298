import { useState, useEffect, useRef } from 'react';
import './MessageInput.css';
import RecordView from './RecordView';
import { PiPaperPlaneRightFill } from 'react-icons/pi';
import { FaRegCircleStop } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { HiOutlineChevronDoubleDown } from "react-icons/hi2";
import useStore from '../hooks/useStore';
import { toast } from 'react-toastify';
import MessageInputImageFile from '../pages/chat/components/MessageInputImageFile'

const commandsJsonData = {
  "commands": [
      // {
      //     "name": "/import",
      //     "description": "Opens a pop-up to import long-term knowledge (PDF, PPTX, DOCX, CSV, TXT or code files)",
      //     "parameters": [
      //         {
      //             "name": "File",
      //             "type": "file",
      //             "description": "The file to import",
      //             "required": true
      //         },
      //         {
      //             "name": "Category",
      //             "type": "string",
      //             "description": "Give it a knowledge category. You can search entire categories with /retrieve-knowledge",
      //             "required": true
      //         },
      //         {
      //           "name": "Subject",
      //           "type": "string",
      //           "description": "What is this file about?",
      //           "maxSize": 300,
      //           "required": true
      //         },
      //         {
      //           "name": "LearnMoreLinks",
      //           "type": "string",
      //           "description": "Links to get more information",
      //           "required": false
      //         }  

      //     ]
      // },
      // {
      //   "name": "/import-website",
      //   "description": "Opens a pop-up to import long-term knowledge from a website's URL",
      //   "parameters": [
      //       {
      //           "name": "URL",
      //           "type": "string",
      //           "description": "Website's URL",
      //           "required": true
      //       },
      //       {
      //           "name": "Category",
      //           "type": "string",
      //           "description": "Give it a knowledge category. You can search entire categories with /retrieve-knowledge",
      //           "required": true
      //       },
      //       {
      //         "name": "Subject",
      //         "type": "string",
      //         "maxSize": 300,
      //         "description": "What is this file about?",
      //         "required": true
      //       }
      //     ]
      // },
      {
        "name": "/file-attach",
        "description": "Attach a file to a chat.",
        "parameters": [
            {
                "name": "File",
                "type": "file",
                "description": "The file to attach to the conversation",
                "required": true
            },
            {
                "name": "purpose",
                "type": "string",
                "enum": ["process numeric data", "process text data"],
                "description": "What is the purpose of the file?",
                "required": true
            }
          ]
      },
      {
        "name": "/python-input",
        "description": "upload a file for processing by a Python script",
        "parameters": [
            {
                "name": "File",
                "type": "file",
                "description": "The file to process",
                "required": true,
            },
        ]
      },
      {
        "name": "/website",
        "description": "Read a website to chat about it in the current conversation",
        "parameters": [
            {
                "name": "URL",
                "type": "string",
                "description": "The website's URL",
                "required": true,
            }
        ]
      },
      {
        "name": "/web-browse-function",
        "description": "Allow the AI to browse the web and chat about what it finds",
      },     
      // {
      //     "name": "/retrieve-knowledge",
      //     "description": "load previously saved long-term knowledge for use in this chat",
      //     "parameters": [
      //         {
      //             "name": "Category",
      //             "type": "string", // change to select in the future
      //             "description": "Select a knowledge category to talk about",
      //             "required": true,
      //         }
      //     ]
      // },
      // {
      //     "name": "/memorize-chat",
      //     "description": "save current conversation as long-term knowledge"
      // },
      {
        "name": "/disconnect",
        "description": "force disconnection for testing purposes"
      },
      {
        "name": "/about-me",
        "description": "Tell the AI about yourself so it can adapt the answers.",
        "parameters": [
            {
                "name": "My Interests",
                "type": "textarea",
                "description": "What are your interests?",
                "required": false,
            },
          ]
      }
  ]
}

function StopIcon({onClickHandler, darkMode, show}) {
  return (
    <div onClick={onClickHandler} >
      <div className={`stop-button-container ${darkMode?'dark-mode':''} ${show?'show':''}`}>
        Interrupt <FaRegCircleStop size={24}  />
      </div>
    </div>
  )
}




function PlusButton({onClickHandler, darkMode, showClip}) {
  // open FileAttachPopup when clicked
  return (
    <div onClick={onClickHandler} >
      <div className={`plus-button-container ${darkMode?'dark-mode':''}`}>
        {<FaPlus size={16} /> }
        {/* {showClip && <FaPaperclip size={16} />} */}
      </div>
    </div>
  )
}


function ScrollToBottom ({onClickHandler, darkMode, show}) {
  return (
    <div onClick={onClickHandler} >
      <div className={`scroll-to-bottom-button-container ${darkMode?'dark-mode':''} ${show?'show':''}`}>
        <HiOutlineChevronDoubleDown size={24}  />
      </div>
    </div>
  )
}

function PaperPlaneIcon({onClickHandler, darkMode}) {
  return (
    <div onClick={onClickHandler} >
      <div className={`paper-plane-button-container ${darkMode?'dark-mode':''}`}> 
        <PiPaperPlaneRightFill size={24}  /> 
        {/* color={darkMode ? "gray" : "#444444"} */}
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" 
          strokeWidth="2">
          <path
            d="M.5 1.163A1 1 0 0 1 1.97.28l12.868 6.837a1 1 0 0 1 0 1.766L1.969 15.72A1 1 0 0 1 .5 14.836V10.33a1 1 0 0 1 .816-.983L8.5 8 1.316 6.653A1 1 0 0 1 .5 5.67V1.163Z"
            fill={darkMode ? "gray" : "#444444"}></path>
        </svg> */}
      </div>
    </div> 
  )
}

function SlashCommands({isVisible, filteredCommands, onClick}) {

  return (<div className={`slash-commands ${isVisible === true ? 'visible' : ''}`}>
    <h3>Available commands</h3>
    {filteredCommands.map((command, i) => (
      <div className="slash-command-item" key={i} onClick={onClick(command.name)}>{command.name} – {command.description}</div>
    ))} 
  </div>
  )
}


function DisclaimerMessage({ input }) {
  const [showDefaultMessage, setShowDefaultMessage] = useState(true);

  useEffect(() => {
    let timer;

      setShowDefaultMessage(false);
      timer = setTimeout(() => {
        setShowDefaultMessage(true);
      }, 2000);
    
    return () => clearTimeout(timer);
  }, [input]);

  return (
    <div className="disclaimer-message">
      {showDefaultMessage ? (
        <p className="default-message">The AI can make mistakes, so be mindful.</p>
      ) : (
        <p className="new-line-message">Use Shift+ENTER for a new line.</p>
      )}
    </div>
  );
}


export function MessageInput({ darkMode, onMessage, onSaveAudio, promptName, isStreaming, onStopMessages, onScrollToBottom, showScrollToBottomButton, isAISpeaking }) {
  const [input, setInput] = useState('');
  const [rows, setRows] = useState(1);
  const appName = useStore((state) => state.appName);

  //const defaultPlaceholder = 'Type a message or "/" to see a list of commands...'
  //const [placeholder, setPlaceholder] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [isSlashCommandsVisible, setIsSlashCommandsVisible] = useState(false);
  const [isCommandsFormVisible, setIsCommandsFormVisible] = useState(false);
  const [filteredCommands, setFilteredCommands] = useState([]); 
  const [smallScreen, setSmallScreen] = useState(false);
  const [disclaimers, setDisclaimers] = useState({long:`${appName} can make mistakes. Please verify important information.`, short: `${appName} can make mistakes, so be mindful.`});
  const [disclaimer, setDisclaimer] = useState(disclaimers.long);
  const [imageUrlArray, setImageUrlArray] = useState([]); // each image in the array will be in the format {url:'', dimensions: {width: 0, height: 0}}
  const [showAttachments, setShowAttachments] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {

    const handleResize = () => {
      // Check the window width and set the default collapse state accordingly
      if (window.innerWidth < 768) {
        //setPlaceholder('Type a message...');
        setSmallScreen(true);
        setDisclaimer(disclaimers.short);
      } else {
        //setPlaceholder(`${defaultPlaceholder}       ${`Prompt: ${promptName}`}`);
        setSmallScreen(false);
        setDisclaimer(disclaimers.long);
      }
    };
    
    handleResize();
      
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
     
      
  }, []);

  const handleInputChange = (event) => {
    setInput(event.target.value);
    //setRows(event.target.value.split('\n').length);
    //event.target.style.height = 'auto';
    
    autoResizeInputTextArea();

    const message = event.target.value;
    const filteredCommands = commandsJsonData.commands.filter(command => command.name.startsWith(message));

    if (event.target.value && event.target.value[0] === '/' && filteredCommands.length > 0) {
      setIsSlashCommandsVisible(true);
      setFilteredCommands(filteredCommands);
    } else {
      setIsSlashCommandsVisible(false);
      setFilteredCommands([]);
    }
  };

  const handleKeyDown = (event) => {
    const message = event.target.value;
    const filteredCommands = commandsJsonData.commands.filter(command => command.name.startsWith(message));
    
    // ESC event
    if (event.keyCode === 27) { //esc
      setIsSlashCommandsVisible(false);
      //setInput('');
      //textareaRef.current.style.height = "auto"; // Reset the textarea height
      return;
    }

    if (event.keyCode === 9 && message.length > 0) {
      event.preventDefault();
      if (filteredCommands.length > 0) {
        setInput(filteredCommands[0].name);
      }
      return;
    }
  
    if (event.key === 'Enter' && !event.shiftKey && !isStreaming) {
      event.preventDefault();
      
      if (input !== '') {
        setInput('');
        setRows(1);
        const singleRowHeight = 24;
        event.target.style.height = `${singleRowHeight}px`;
        
        if (filteredCommands.length > 0 && filteredCommands[0].name === message) { // command is complete
          setIsSlashCommandsVisible(false);
          if (filteredCommands[0].parameters) {
            onMessage(input, 'command', filteredCommands[0]); // open pop up form on App.js
            
            
          } else { 
            //no parameters
            onMessage(input, 'command', filteredCommands[0]);
          }
          
        } else {
          setIsSlashCommandsVisible(false);
          if (filteredCommands.length === 0) {
            onMessage(input, '','', [...imageUrlArray]);
            setImageUrlArray([]);
          }
        }
      }
    }
  };
  
  function autoResizeInputTextArea() {
    const maxHeight = 400;  // px
    const textarea = document.getElementById("message-input-textarea");
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight - 20 + "px"; // 20 is the total top + bottom padding

    if (textarea.scrollHeight > maxHeight) {
      textarea.style.overflowY = "scroll";
      textarea.style.height = maxHeight + "px";
    } else {
      textarea.style.overflowY = "hidden";
    }
  }

  function handlePaperPlaneClick() {
    if (input !== '') {
      setInput('');
      setRows(1);
      const singleRowHeight = 24;
      const textarea = document.getElementById("message-input-textarea");
      textarea.style.height = `${singleRowHeight}px`;
  
      if (input[0] === '/' && filteredCommands.length > 0) { // Slash command is complete
        setIsSlashCommandsVisible(false);
        if (filteredCommands[0].parameters) {
          onMessage(input, 'command', filteredCommands[0]);
        } else { // No parameters
          onMessage(input, 'command', filteredCommands[0], imageUrlArray);
        }
      } else {
        setIsSlashCommandsVisible(false);
        const imagesArray = [...imageUrlArray];
        onMessage(input, '','', imagesArray);
      }
      setImageUrlArray([]);
    }
  }
  
  function handleClickSlashCommand(commandName) {
    return function() {
      setIsSlashCommandsVisible(false);
      setInput("");
      textareaRef.current.focus();
      const myFilteredCommands = commandsJsonData.commands.filter(command => command.name === commandName);
      onMessage(input, 'command', myFilteredCommands[0]);
    }
  }

  const handleImagePaste = (event) => {
    if (imageUrlArray.length >= 2) {
      toast.info("Only two attachments are allowed per message.");
      return;
    }
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const blob = items[i].getAsFile();
        // get image dimensions
        
        const imageUrl = {url: URL.createObjectURL(blob), dimensions: {width: 0, height: 0}, type: blob.type};
        // push to the imageUrlArray
        setImageUrlArray([...imageUrlArray, imageUrl]);
        
      }
    }
  };

  const handlePlusButtonClick = () => {
    const message = '/file-attach';
    const filteredCommands = commandsJsonData.commands.filter(command => command.name.startsWith(message));
    onMessage(input, 'command', filteredCommands[0])
    setShowAttachments(true);
    // add a 📎 to the end of the message
    setInput(input + ' 📎');
  };
  
  return (
    <div>
      <div className={`message-input ${darkMode ? 'dark-mode' : ''}`}>
        <RecordView onSaveAudio={onSaveAudio} isAISpeaking={isAISpeaking} className="record-button" />
        <PlusButton onClickHandler={handlePlusButtonClick} darkMode={darkMode} showClip={showAttachments} />
        <textarea
          id="message-input-textarea"
          ref={textareaRef}
          className={`message-input-textarea ${darkMode ? 'dark-mode' : ''}`}
          //placeholder = {smallScreen ? "Type a message..." : `Type a message or "/" to see a list of commands...       ${`Prompt: ${promptName}`}`}
          placeholder={smallScreen ? "Type a message..." : `Type a message or "/" to see a list of commands...`}
          value={input}
          onInput={handleInputChange}
          onKeyDown={handleKeyDown}
          rows={rows}
          disabled={disabled}
          onPaste={handleImagePaste}
        />
        <div className="images-container" >
          {imageUrlArray.map((image, i) => (
            <div key={i * 10} className="mini-image-container" >
              <MessageInputImageFile 
                key={i} 
                image={image} 
                handleRemove={() => setImageUrlArray(imageUrlArray.filter((_, index) => index !== i))} 
              />
            </div>
          ))}
        </div>

        {/* <div className="scroll-and-interrupt-buttons"> */}
        <StopIcon onClickHandler={onStopMessages} darkMode={darkMode} show={isStreaming} />
        <ScrollToBottom onClickHandler={onScrollToBottom} darkMode={darkMode} show={showScrollToBottomButton} />
        {/* </div> */}
        {!isStreaming && <PaperPlaneIcon onClickHandler={handlePaperPlaneClick} darkMode={darkMode} />}
        <SlashCommands isVisible={isSlashCommandsVisible} filteredCommands={filteredCommands} onClick={handleClickSlashCommand} />
      </div>

      <div className="short-disclaimer">
        <DisclaimerMessage input={input} />
      </div> 
    </div>
  );
}
