import React, { useState, useEffect } from 'react'
import introJs from 'intro.js'
import './Tour.css'
import useStore from '../hooks/useStore'

function Tour({ triggerTourReset, handleTourReset, handleTourDontShowAgain }) {
  const [intro, setIntro] = useState(null)
  const [showIntro, setShowIntro] = useState(false)
  const appName = useStore((state) => state.appName)

  useEffect(() => {
    // remove the cookie that prevents the tour from showing
    document.cookie =
      'introjs-dontShowAgain=false; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    if (!showIntro && triggerTourReset) {
      try {
        intro.setDontShowAgain(false)
        intro.start()
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }, [showIntro, triggerTourReset])

  useEffect(() => {
    const intro = introJs()

    intro.setOptions({
      tooltipClass: 'tour-tooltip',
      steps: [
        {
          // element: "#welcome-lara-logo",
          intro: `Welcome to ${appName}, your TD SYNNEX AI assistant for everyday tasks! This is a welcome tour of the interface. Click 'Next' to continue.`,
          position: 'bottom'
        },
        {
          element: '#guided-tour-button',
          intro:
            'In the future, you can start this tour again by clicking the Tour button in the top right corner.',
          position: 'left'
        },
        // {
        //   element: '#message-input-textarea',
        //   intro: "This textbox is where you interact with LARA. You will type your messages and optionally paste images here when using GPT-4o or Gemini 1.5 Pro models. Models are the AI engines that power LARA.",
        //   position: 'top'
        // },
        {
          element: '#conversation-starters',
          intro:
            'Do you need inspiration? Just click one of the conversation starters to see the AI in action (but wait, not yet).',
          position: 'top'
        },
        {
          element: '#new-chat-button',
          intro: `Click here to start a new chat. This will also take you back to the home screen that you can see right now.`,
          position: 'left'
        },
        {
          element: '#assistant-dropdown',
          intro: `Try a few of the built-in AI assistants. Each one has a different purpose. For example, 'About ${appName}' can explain about use cases and the available features.`,
          position: 'bottom'
        },
        {
          element: '#marketplace-button-1',
          intro:
            'Next, click the Marketplace button to see more AI assistants, use PromptStudio to practice Prompt Engineering, and go pro by creating you own AI Assistants.',
          position: 'left'
        },
        {
          element: 'div.toggle-button-area',
          intro: `Toggle between different AI models to see how they perform. Click the button to switch between models. GPT-4o is the most powerful model available in ${appName}. GPT-4o can handle text and images.`,
          position: 'right'
        },
        {
          intro: `That's it! Enjoy using ${appName}. Check the box "Don't show this again" to skip this tour next time.`
        }
      ]
    })

    setIntro(intro)
    intro
      .setOption('dontShowAgain', true)
      .oncomplete(handleTourReset)
      .onexit(handleTourReset)
      .onbeforeexit(function () {
        if (document.cookie.includes('introjs-dontShowAgain=true')) {
          handleTourDontShowAgain()
        }
      })
  }, [])

  return null
}

export default Tour
