import { create } from "zustand";
import { msalInstance } from "../msalInstance";
import { webAPIRequest } from "../authConfig";

const useMsalStore = create(() => {
  const getMsalConfigurations = async () => {
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length == 0) {
        console.warn("Nenhuma conta ativa encontrada.");
        return;
      }

      const msalResponse = await msalInstance.acquireTokenSilent({
        ...webAPIRequest,
        account: accounts[0],
      });

      return msalResponse;
    } catch (error) {
      console.error("Failed while loading permission file", error);
    }
  };

  return {
    getMsalConfigurations,
  };
});

export default useMsalStore;
