import { SharepointDataIngester } from './SharepointDataIngester';


export function SharepointDataIngesterTest() {
  // generate synthetic data to test the SharepointDataIngester component
  const knowledgeBaseName = "streamone";
  const knowledgeBaseType = "corporate";
  const handleRequestSharepointFilesMetadata = (knowledgeBaseName, knowledgeBaseType) => {
    const fileEntry = 
      {
        folderPath: "testFolderPath",
        filename: "testFilename",
        subject: "testSubject",
        lastModified: "testLastModifiedfff\nv",
        lastModifiedBy: "testLastModifiedBy",
        needsUpdate: "testNeedsUpdate",
        size: "testSize"
      }
    // return an array with 30 file entries
    return Array(30).fill(fileEntry);
  }
  const handleUpdateFileDescription = (knowledgeBaseName, knowledgeBaseType, file, newDescription) => {
    // Updating description for file ${file} in ${knowledgeBaseName} (${knowledgeBaseType}) to: ${newDescription}`);
  }
  const handleIncrementalIngest = (knowledgeBaseName, knowledgeBaseType, filesList) => {
    // Incremental ingest for ${knowledgeBaseName} (${knowledgeBaseType}) with files: ${filesList}`);
  }
  const handleFullIngest = (knowledgeBaseName, knowledgeBaseType, filesList) => {
    // Full ingest for ${knowledgeBaseName} (${knowledgeBaseType}) with files: ${filesList}`);
  }
  const handleGenerateAiFileDescription = (knowledgeBaseName, knowledgeBaseType, file) => {
    // Generating AI file description for file ${file} in ${knowledgeBaseName} (${knowledgeBaseType})`);
  }

  // render the SharepointDataIngester component. Use the full screen for the component.
  return (
      <SharepointDataIngester
          knowledgeBaseName={knowledgeBaseName}
          knowledgeBaseType={knowledgeBaseType}
          handleRequestSharepointFilesMetadata={handleRequestSharepointFilesMetadata}
          handleUpdateFileDescription={handleUpdateFileDescription}
          handleIncrementalIngest={handleIncrementalIngest}
          handleFullIngest={handleFullIngest}
          handleGenerateAiFileDescription={handleGenerateAiFileDescription}
      />
  )

}

