import { set } from 'react-hook-form';
import './SharepointDataIngester.css'
import { useState, useEffect } from "react";
import { FaMagic } from "react-icons/fa";
import { HiReceiptRefund } from 'react-icons/hi2';
import { 
  Button, Container, Form, 
  Grid, Header, Input, TextArea, Icon,
  Select, Confirm, Checkbox,
} from 'semantic-ui-react';


function SubjectInput({ file, handleFileDescriptionChange }) {

  const [originalValue, setOriginalValue] = useState(file.subject);
  const [modifiedValue, setModifiedValue] = useState(file.subject);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    setOriginalValue(file.subject);
    setModifiedValue(file.subject);
    setIsModified(false);
  }, [file, file.subject]);



  const handleInputChange = (e) => {
    setModifiedValue(e.target.value);
    setIsModified(e.target.value !== originalValue);
  };

  const handleSaveClick = () => {
    handleFileDescriptionChange(file, modifiedValue);
    setOriginalValue(modifiedValue);
    setIsModified(false);
  };

  return (
    <div className="subject-input">

      <Input style={{ width: "calc(100% - 9em)" }}
        type="text"
        value={modifiedValue}
        onChange={handleInputChange}
        placeholder="Enter a description"
        action={isModified ? {
          color: 'teal',
          labelPosition: 'right',
          icon: 'save',
          content: 'Save',
          onClick: handleSaveClick,
        } : null}
      />
    </div>
  );
}


// SharepointDataIngester is a component that ingests data from SharePoint.
// It shows the complete list of files in scope for data ingestion, plus the changes detected in Sharepoint.
// The user can review and adjust the descriptions of each file ("subject" field) before ingestion. By default, the subject is equal to the file name, without the extension.
// The user can also click on a "generate description" button to automatically generate a description for each file based on its content.
// Good file descriptions are important for the AI to understand the context of the files and provide better insights.
 
// The data is shown in a table, similar to a spreadsheet, where the description column can be edited.

export function SharepointDataIngester({
  assistantId,
  assistantName,
  knowledgeBaseType,
  filesList,

  onGetSavedSpIngestionPlans, ingesterPlan, ingesterPlansList,

  handleUpdateFileDescription,
  handleIncrementalIngest,
  handleFullIngest,
  onSelectIngestionPlan,
  onClose,
  onMagicFill,
}) {
  const [selectedIngesterPlan, setSelectedIngesterPlan] = useState(ingesterPlan);
  const [myFilesList, setMyFilesList] = useState(filesList);
  const [ingesting, setIngesting] = useState(false);
  
  useEffect(() => {
    setMyFilesList(filesList);
  }, [filesList, handleUpdateFileDescription]);

  const handleIncrementalIngestClick = () => {
    handleIncrementalIngest(assistantName, knowledgeBaseType, filesList);
    setIngesting(true);

  }

  const handleSelectPlan = (plan) => {
    setSelectedIngesterPlan(plan);
    // call onSelectIngestionPlan so the parent component can send a socket.emit message to retrieve the files
    onSelectIngestionPlan(plan);
  }
  const handleFullIngestClick = () => {
    handleFullIngest(assistantName, knowledgeBaseType, filesList);
  }

  const handleFileDescriptionChange = (file, newDescription) => {
    const updatedFile = { ...file, subject: newDescription };
    const updatedFilesList = myFilesList.map((f) =>
      f.filename === updatedFile.filename ? updatedFile : f
    );
    setMyFilesList(updatedFilesList);
    handleUpdateFileDescription(assistantId, [{fileId:file.id, description: newDescription}]);
  };
  

  const handleGenerateDescriptionClick = (file) => {
    handleGenerateAiFileDescription(assistantName, knowledgeBaseType, file);
  }

  const handleMagicFill = () => {

    onMagicFill();

  }




  // The table will show the following columns: folder path, filename, subject, last modified, last modified by, needs update, size, and actions.
  // The "needs update" column will alert the user if the file has been modified in Sharepoint since the last ingestion.
  // The "actions" column will show a "generate description" button and an "ingest" button.

  // generate a schema for the table
  const tableSchema = [
    // { name: "folderPath", label: "Folder Path", description: "The Sharepoint path of the folder where the file is located." },
    { name: "filename", label: "Filename", description: "The name of the file, with a hyperlink to the original file." },
    { name: "subject", label: "Subject", description: "The description or subject of the file. This is the key field that will make the search more robust, so write up to a short paragraph about the content." },
    { name: "lastModified", label: "Last Modified/By", description: "The date and time when the file was last modified, along with the user who made the modification. This allows the user to judge if the file was properly reviewed by the right people before proceeding with the data ingestion and load into the AI's knowledge base." },
    { name: "needsUpdate", label: "Needs Update", description: "Indicates whether the file will be included in the incremental update of the AI's knowledge base." },
    { name: "size", label: "Size", description: "The size of the file in bytes." },
    // { name: "actions", label: "Actions", description: "Actions that can be performed on the file, such as generating a description using AI. Generating descriptions of files using the AI may take a few moments per file." },
  ];
    
  return (
    <div className="sharepoint-data-ingester">
      <h1>Sharepoint Data Ingester</h1>
      <div>
        <div>Target Assistant: <strong>{assistantName} ({knowledgeBaseType} KB)</strong></div>
        <div>
          <div>
            <div className="ingester-plans-container">
            Open Ingestion Plan:
              <select onChange={(e) => handleSelectPlan(e.target.value)}>
                <option value="">Select an existing ingestion plan...</option>
                {ingesterPlansList && ingesterPlansList.map((plan, index) => (
                  <option key={index} value={plan}>
                    {plan}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="start-ingestion-buttons" >
            { selectedIngesterPlan.length > 0 &&
              <Button primary
                type="button" 
                disabled={ingesting}
                onClick={handleIncrementalIngestClick}>Start Incremental Ingest</Button>

            }
            {/* <button onClick={handleFullIngestClick}>Full Ingest</button> */}
            { ingesting && <div>Incremental ingestion in progress (please be patient)...</div>}
            <Button type="button" onClick={onClose} >Close</Button>
          </div>
        </div>
      </div>
      { selectedIngesterPlan.length > 0 &&
        <table>
          <thead>
            <tr>
              {tableSchema.map((column) => (
                column.name === "subject" ? (
                  <th key={column.name}  >{column.label}
                  <div className="magic-subjects"><Button type="button" primary icon labelPosition='right' onClick={handleMagicFill}>Fill in the blanks<Icon name="magic"></Icon></Button></div></th>
                ) : (
                  <th key={column.name}>{column.label}</th>
                )
              ))}
            </tr>
          </thead>
          <tbody>
          {myFilesList.map((file, index) => (
            <tr className="data-ingester-row" key={index}>
              {tableSchema.map((column) => (
                <td key={`${column.name}-${index}`}>
                  {column.name === "subject" ? (
                    file.needsUpdate === "not supported" || file.needsUpdate === "deleted" ? (
                      <div>{file.needsUpdate}</div>
                    ) : (
                      // show the file[subject] in an input field
                      <SubjectInput file={file} handleFileDescriptionChange={handleFileDescriptionChange} />
                    )
                    
                  ) : column.name === "filename" ? (
                    // show the file[filename] with a hyperlink to file[webUrl]
                    <a href={file["webUrl"]} target="_blank" rel="noreferrer">
                      {file[column.name]}
                    </a>
                  ) : column.name === "size" ? (
                    // align right
                    <div style={{ textAlign: "right" }}>
                      {file[column.name]}
                    </div>
                  ) :
                    (
                    file[column.name]
                  )}
                </td>
              ))}
            </tr>
          ))}

          </tbody>
        </table>        
      }

    </div>
  );
}




