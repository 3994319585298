import * as Sentry from '@sentry/react'
import logo from './images/AI3.png'
import logoMaeAssist from './images/mae_assist.jpg'
import qrCodeImage from './images/lara.tdsynnex.com-qrcode-new.png'
import './App.css'
import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import Panel from './Panel'
import ToggleButton from './ToggleButton'
import io from 'socket.io-client'
import { MessageInput } from './MessageInput'
import { MessagesArea } from './MessagesArea'
import CommandsForm from './CommandsForm'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import ReactCanvasConfetti from 'react-canvas-confetti'
import { Buffer } from 'buffer'
import { ConversationStarters } from './ConversationStarters'
import { PageLayout } from './PageLayout'
import { webAPIRequest } from '../authConfig'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react'
import { MemoryAndMore } from './MemoryAndMore'
//import { set } from 'react-hook-form';
import { TermsOfUse } from './TermsOfUse'
import { FaCartPlus } from 'react-icons/fa6'
import { MdOutlineLightMode } from 'react-icons/md' // <MdOutlineLightMode />
import { MdNightlight } from 'react-icons/md' // <MdNightlight />
import { BiSolidConversation } from 'react-icons/bi'
import { Marketplace } from './Marketplace'
import { PromptStudio } from './PromptStudio'
// import { KnowledgeManager } from './KnowledgeManager';
import { FaShareSquare } from 'react-icons/fa'
import { FaWandMagicSparkles } from 'react-icons/fa6'
import { AiAssistant } from './AiAssistant'
import ErrorBoundary from './ErrorBoundary'
import AudioPlayer from './AudioPlayer'
import { AiAssistantDropdown } from './AiAssistantDropdown'
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useLocation
} from 'react-router-dom'
import Resizer from 'react-image-file-resizer'
import { Tooltip } from 'react-tooltip'
import { MyBarChart } from './UserGraphs'
import Tour from './Tour'
import { Confirm } from 'semantic-ui-react'
import useStore from '../hooks/useStore'
import { initializeMSAL } from '../msalInstance'
import NotificationBar from '../components/UI/NotificationBar'
import { MapsUgcOutlined, TourOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { GoSidebarExpand } from 'react-icons/go'

const canvasStyles = {
  // confetti styles
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 1000
}

function Images({ images }) {
  return (
    <div className="images-container">
      {images.map((image, index) => {
        const base64Image = `data:image/png;base64,${Buffer.from(
          image
        ).toString('base64')}`
        return (
          <div key={index} className="website-image">
            <img
              src={base64Image}
              alt="Website Image"
              className="website-image"
            />
          </div>
        )
      })}
    </div>
  )
}

function NewChatWelcomeScreen({
  onClick,
  onToggleHandler,
  onClickMarketplace,
  connected,
  activeProducts,
  onClickPromptStudio,
  onClickKnowledgeManager,
  onClickAiAssistant,
  defaultModel,
  aiAssistant,
  aiAssistants,
  onAiAssistantSelect,
  darkMode,
  welcomeScreenMessage
}) {
  const [showQRCode, setShowQRCode] = useState(false)
  const appName = useStore((state) => state.appName)

  const handleQRCodeClose = () => {
    setShowQRCode(false)
  }

  return (
    <div className="new-chat-welcome-screen">
      {connected && (
        <div className="marketplace-button-container">
          {/* button that says Marketplace and shows the FaCartPlus icon  */}
          <button
            id="marketplace-button-1"
            onClick={onClickMarketplace}
            className="marketplace-button"
          >
            <span className="marketplace-button-text">Marketplace </span>
            <FaCartPlus className="marketplace-button-icon" size={15} />
          </button>
          <button onClick={onClickPromptStudio} className="marketplace-button">
            <span className="marketplace-button-text">PromptStudio </span>
            <BiSolidConversation
              className="marketplace-button-icon"
              size={15}
            />
          </button>
          {/* <button onClick={onClickKnowledgeManager} className="marketplace-button">
            <span className="marketplace-button-text">Knowledge Manager </span><PiBooksDuotone className="marketplace-button-icon" size={15} />
          </button>  */}
          <button onClick={onClickAiAssistant} className="marketplace-button">
            <span className="marketplace-button-text">AI Assistants </span>
            <FaWandMagicSparkles
              className="marketplace-button-icon"
              size={15}
            />
          </button>
        </div>
      )}

      <img
        src={appName === 'Mae Assist' ? logoMaeAssist : logo}
        id="welcome-lara-logo"
        className="App-logo"
        alt="LARA logo"
      />
      <br></br>
      <p>
        {appName}
        <br></br>
        {appName === 'Mae Assist'
          ? 'Powered by Azure OpenAI'
          : 'Language-Aware Responsive AI'}
        {appName !== 'Mae Assist' && (
          <FaShareSquare
            className="share-button"
            onClick={() => {
              setShowQRCode(true)
            }}
          />
        )}
      </p>
      {welcomeScreenMessage && (
        <div className="welcome-screen-message">{welcomeScreenMessage}</div>
      )}
      {/* <div className="application-type-text">Proof-of-Concept (POC) app.</div> */}
      {showQRCode && <QrCode className="qr-code" onClose={handleQRCodeClose} />}
      <ToggleButton
        onToggleHandler={onToggleHandler}
        defaultModel={defaultModel}
      />

      <button onClick={onClick} className="dark-mode-toggle">
        <MdOutlineLightMode /> / <MdNightlight />
      </button>
      <Tooltip
        anchorSelect=".dark-mode-toggle"
        place="right"
        style={{ zIndex: 12, backgroundColor: '#1F728B' }}
      >
        Click to toggle dark/light mode
      </Tooltip>
    </div> 
  )
}

function QrCode({ onClose }) {
  const containerRef = useRef(null)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_LARA_URL).then(
      () => {
        alert('URL copied to clipboard!')
      },
      (err) => {
        console.error('Could not copy text: ', err)
      }
    )
  }

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        onClose()
      }
    }

    window.addEventListener('keydown', handleEsc)
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      window.removeEventListener('keydown', handleEsc)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose])

  return (
    <div className="overlay" tabIndex="-1">
      <div className="qr-code-container" ref={containerRef}>
        <div>
          <img src={qrCodeImage} alt="qr code" className="qr-code" />
        </div>
        <a href={process.env.REACT_APP_LARA_URL} className="lara-url">
          {process.env.REACT_APP_LARA_URL}
        </a>
        <div className="qr-code-close-button-container">
          <button className="qr-code-close-button" onClick={onClose}>
            Close
          </button>
          <button className="qr-code-copy-button" onClick={copyToClipboard}>
            Copy
          </button>
        </div>
      </div>
    </div>
  )
}

function GuidedTourButton({ onGuidedTourClick }) {
  const [smallScreen, setSmallScreen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      // Check the window width and set the default collapse state accordingly
      if (window.innerWidth < 768) {
        setSmallScreen(true)
      } else {
        setSmallScreen(false)
      }
    }
    handleResize()

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div
      id="guided-tour-button"
      // style={{ right: smallScreen ? '35px' : '10px' }}
    >
      <IconButton onClick={() => onGuidedTourClick(true)} title="Guided Tour">
        <TourOutlined style={{ color: '#FFF' }} />
      </IconButton>
    </div>
  )
}

function ActiveChatPanel({
  commandJSON,
  commandParametersHandler,
  onClick,
  darkMode,
  isNewChat,
  onMessage,
  messages,
  onToggleHandler,
  onThumbsFeedback,
  commandFormKeyDownHandler,
  prompts,
  promptName,
  isLoading,
  handleSpeak,
  onSaveAudio,
  onChangePrompt,
  aboutMe,
  onConversationStarterClick,
  onClickMarketplace,
  connected,
  activeProducts,
  onClickPromptStudio,
  onStopMessages,
  isStreaming,
  onScrollToBottomClick,
  onScrollHandler,
  showScrollToBottomButton,
  onClickKnowledgeManager,
  onClickAiAssistant,
  conversationStarters,
  defaultModel,
  aiAssistant,
  aiAssistants,
  last10AssistantsArray,
  onAiAssistantSelect,
  userPhoto,
  firstName,
  lastName,
  isAISpeaking,
  setTourResetTrigger,
  handleUserMessageDelete,
  welcomeScreenMessage,
  handleNewChat,
  onSetHidden,
  panelVisibility
}) {
  return (
    <div className="active-chat-panel-container">
      {connected && <NotificationBar />}
      <div className="one-line-top-toolbar">
        <div className="container__app-bar--actions">
          <GuidedTourButton onGuidedTourClick={setTourResetTrigger} />

          {panelVisibility && (
            <div id="new-chat-button">
              <IconButton title="New Chat" onClick={() => handleNewChat()}>
                <MapsUgcOutlined sx={{ color: '#fff' }} />
              </IconButton>
            </div>
          )}

          {panelVisibility && (
            <IconButton
              title="Open Side Menu"
              onClick={() => onSetHidden(false)}
            >
              <GoSidebarExpand style={{ color: '#fff' }} />
            </IconButton>
          )}
        </div>
        <div className="messages-area-ai-assistant-dropdown">
          <AiAssistantDropdown
            darkMode={darkMode}
            aiAssistant={aiAssistant}
            aiAssistants={aiAssistants}
            onAiAssistantSelect={onAiAssistantSelect}
            last10AssistantsArray={last10AssistantsArray}
            showLabel={false}
          />
        </div>
      </div>
      {isNewChat && (
        <div className="welcome-and-chats-container">
          <NewChatWelcomeScreen
            onClick={onClick}
            onToggleHandler={onToggleHandler}
            onClickMarketplace={onClickMarketplace}
            connected={connected}
            activeProducts={activeProducts}
            onClickPromptStudio={onClickPromptStudio}
            activeAiAssistantName={aiAssistant?.name}
            onClickKnowledgeManager={onClickKnowledgeManager}
            onClickAiAssistant={onClickAiAssistant}
            defaultModel={defaultModel}
            aiAssistant={aiAssistant}
            aiAssistants={aiAssistants}
            onAiAssistantSelect={onAiAssistantSelect}
            darkMode={darkMode}
            welcomeScreenMessage={welcomeScreenMessage}
          />
          <ConversationStarters
            onConversationStarterClick={onConversationStarterClick}
            darkMode={darkMode}
            activeProducts={activeProducts}
            assistantConversationStarters={conversationStarters}
          />
          {commandJSON && (
            <CommandsForm
              commandJSON={commandJSON}
              commandParametersHandler={commandParametersHandler}
              commandFormKeyDownHandler={commandFormKeyDownHandler}
              prompts={prompts}
              aboutMe={aboutMe}
            />
          )}
        </div>
      )}
      {!isNewChat && (
        <div className="welcome-and-chats-container">
          <MessagesArea
            darkMode={darkMode}
            messages={messages}
            onThumbsFeedback={onThumbsFeedback}
            isLoading={isLoading}
            handleSpeak={handleSpeak}
            onScroll={onScrollHandler}
            userPhoto={userPhoto}
            firstName={firstName}
            lastName={lastName}
            handleUserMessageDelete={handleUserMessageDelete}
          />
          {commandJSON && (
            <CommandsForm
              commandJSON={commandJSON}
              commandParametersHandler={commandParametersHandler}
              commandFormKeyDownHandler={commandFormKeyDownHandler}
              prompts={prompts}
              aboutMe={aboutMe}
              initIsContentAtBottom={!showScrollToBottomButton}
            />
          )}
        </div>
      )}

      <MessageInput
        id="message-input"
        darkMode={darkMode}
        onMessage={onMessage}
        onSaveAudio={onSaveAudio}
        promptName={promptName}
        isStreaming={isStreaming}
        onStopMessages={onStopMessages}
        onScrollToBottom={onScrollToBottomClick}
        showScrollToBottomButton={showScrollToBottomButton}
        isAISpeaking={isAISpeaking}
      />
    </div>
  )
}
/*

  ##     ### ##   ### ##   
  ###     ##  ##   ##  ##  
 ## ##    ##  ##   ##  ##  
 ##  ##   ##  ##   ##  ##  
 ## ###   ## ##    ## ##   
 ##  ##   ##       ##      
###  ##  ####     ####     
                         

*/

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Lara />} />

        {/* <Route path="/dictionary" element={<Dictionary />} /> <Route path="/definition" element={<Definition />}
            />
            <Route path="/definition" element={<Definition />} />
            < Route path="/customers" element={<Customers />} /> */}
        <Route path="/usergraphs" element={<MyBarChart />} />
      </Routes>
    </BrowserRouter>
  )
}

function Lara() {
  const [isNewChat, _setIsNewChat] = useState(true)
  const [messages, _setMessages] = useState([])
  //const [message, setMessage] = useState("");
  const [lastMessageType, _setLastMessageType] = useState('')
  const [savedChats, _setSavedChats] = useState([])
  const [showCommandsPopup, setShowCommandsPopup] = useState(false)
  const [commandJSON, setCommandJSON] = useState(null) //
  //const [shouldFireConfetti, setShouldFireConfetti] = useState(false);
  const [AIModel, setAIModel] = useState('gpt-4o-mini')
  //const [memoryFiles, setMemoryFiles] = useState([]);
  const [assistantKBMetadata, setAssistantKBMetadata] = useState([])
  const [selectedFileChunksCount, setSelectedFileChunksCount] = useState(0)
  const [tokenCount, setTokenCount] = useState(0)
  const [prompts, setPrompts] = useState([])
  const [prompt, setPrompt] = useState('')
  const [promptName, setPromptName] = useState('')
  const [images, setImages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [userFirstName, setUserFirstName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [connected, setConnected] = useState(false)
  const [aboutMe, setAboutMe] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(true)
  const [showMarketPlace, setShowMarketPlace] = useState(false)
  const { instance, accounts } = useMsal()
  const [activeProducts, setActiveProducts] = useState(new Set())
  const [showPromptStudio, setShowPromptStudio] = useState(false)
  const [isStreaming, setIsStreaming] = useState(false)
  const [showKnowledgeManager, setShowKnowledgeManager] = useState(false)
  const [isContentAtBottom, setIsContentAtBottom] = useState(true)
  const [stats, setStats] = useState({ sessionsCount: 1, usersCount: 1 })
  const [sharepointFilesAndFolderChain, setSharepointFilesAndFolderChain] =
    useState({ files: [], folderChain: [] })
  const [sharepointTenantName, setSharepointTenantName] = useState('')
  const [sharepointSitePath, setSharepointSitePath] = useState('')
  const [sharepointIngesterPlan, setSharepointIngesterPlan] = useState({})
  const [sharepointIngesterPlansList, setSharepointIngesterPlansList] =
    useState([])
  const [showAiAssistant, setShowAiAssistant] = useState(false)
  const [aiAssistants, setAiAssistants] = useState([])
  const [
    aiSubscribedMarketplaceAssistants,
    setAiSubscribedMarketplaceAssistants
  ] = useState([])
  const [marketplaceAssistants, setMarketplaceAssistants] = useState([])
  const [aiAssistant, setAiAssistant] = useState(undefined)
  const [userRoles, setUserRoles] = useState([])
  const [canImpersonateTestUser, setCanImpersonateTestUser] = useState(false)
  const [last10AssistantsArray, setLast10AssistantsArray] = useState([])
  const [query, setQuery] = useState(useQuery())
  const [userPhoto, setUserPhoto] = useState(null)
  const [voiceMode, setVoiceMode] = useState('normal')
  const [isAISpeaking, setIsAISpeaking] = useState(false)
  const [tourResetTrigger, setTourResetTrigger] = useState(false)
  const [showDeleteMessageConfirmPopup, setShowDeleteMessageConfirmPopup] =
    useState(false)
  const [messageToDelete, setMessageToDelete] = useState(null)
  const [welcomeScreenMessage, setWelcomeScreenMessage] = useState(null)
  const [panelVisibility, setPanelVisibility] = useState(true)

  const { setAppName, loadPermissions } = useStore((state) => state)

  const myIsNewChatRef = useRef(isNewChat)
  const setIsNewChat = (data) => {
    myIsNewChatRef.current = data
    _setIsNewChat(data)
  }

  // we must use refs because we need to access the current value of these variables inside the socket event handlers
  const myLastMessageTypeRef = useRef(lastMessageType)
  const setLastMessageType = (data) => {
    myLastMessageTypeRef.current = data
    _setLastMessageType(data)
  }

  // we must use refs because we need to access the current value of these variables inside the socket event handlers
  const myMessagesRef = useRef(messages)
  const setMessages = (data) => {
    myMessagesRef.current = data
    _setMessages(data)
  }

  // we must use refs because we need to access the current value of these variables inside the socket event handlers
  const mySavedChatsRef = useRef(savedChats)
  const setSavedChats = (data) => {
    mySavedChatsRef.current = data
    _setSavedChats(data)
  }

  const handleTourReset = () => {
    setTourResetTrigger(false) // this function is called by the Tour component after it processes the reset to show the tour again
  }

  const handleTourDontShowAgain = () => {
    try {
      socket.current.emit('user-tour');
    }catch (error) {
      toast.error("Error: Could not disable the tour. Please try again later.");
    }
  }

  function scrollToBottom(force) {
    const element = document.getElementById("messages-container");
    if (element) {
      if (force) {
        element.scrollTo({
          top: element.scrollHeight,
          behavior: 'smooth'
        })
        return
      }
      const visibleHeight = element.clientHeight
      const totalHeight = element.scrollHeight
      const scrollOffset = element.scrollTop
      const bottomOffset = totalHeight - scrollOffset - visibleHeight
      if (bottomOffset < 200) {
        // only scroll if the bottom part of the element is within 200 pixels of being visible
        element.scrollTo({
          top: element.scrollHeight,
          behavior: 'smooth'
        })
      }
    }
  }

  function debounce(func, wait) {
    // Implement a debounce mechanism to limit how often the function is called within a certain time frame.
    let timeout
    return function (...args) {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }

  const debouncedScrollToBottom = debounce(scrollToBottom, 200)

  const socket = useRef(null);
  const isAlreadyStarted = useRef(false);

  useEffect(() => {
    //only run this once. How: https://stackoverflow.com/questions/53120972/how-to-call-loading-function-with-react-useeffect-only-once
    // connect to the Socket.IO server
    let username = ''
    if (accounts.length > 0 && !isAlreadyStarted.current) {
      isAlreadyStarted.current = true
      initializeMSAL().then(() => {
        instance
          .acquireTokenSilent({
            ...webAPIRequest,
            account: accounts[0]
          })
          .then((response) => {
            Sentry.setUser({
              name: response.account.name,
              username: response.account.username
            })
            // mae assist static permission file load
            loadPermissions().then((permissionResponse) => {
              const userNamePermission = permissionResponse.find(
                (item) =>
                  item.toLowerCase() === response.account.username.toLowerCase()
              )
              setAppName(userNamePermission ? 'Mae Assist' : 'LARA')
            })

            socket.current = io(process.env.REACT_APP_API_URL, {
              reconnection: true,
              reconnectionDelay: 10000,
              reconnectionDelayMax: 5000, // try to reconnect for 10 minutes
              reconnectionAttempts: Infinity,
              transportOptions: {
                polling: {
                  extraHeaders: {
                    Authorization: `Bearer ${response.accessToken}`
                  }
                }
              }
            })
            const userRoles = response.idTokenClaims.roles
            const userIdToken = response.idToken

            socket.current.on('connect_error', (err) => {
              Sentry.captureException(err);

              setConnected(false);

              if (
                err.toString() === 'Error: Invalid or expired token' ||
                err.toString() === 'Error: Authentication error: invalid jwt'
              ) {
                setTimeout(async () => await instance.loginRedirect(), 2000);
              }
            })

            socket.current.on('user', (data) => {
              setConnected(true)
              setUserFirstName(data.firstName)
              setUserLastName(data.lastName)

              if (typeof data.acceptedTerms === 'string') {
                data.acceptedTerms = data.acceptedTerms.toLowerCase() === 'true'
              }

              if (!data.tourDisabled && data.acceptedTerms) {
                // timeout added because the tour is not displayed if the state is set immediately
                setTimeout(() => setTourResetTrigger(true), 2000);
              }

              socket.current.emit('get-stats')
            })

            socket.current.on('dark-mode', (data) => {
              setDarkMode(data.darkMode)
            })

            socket.current.on('connect', () => {
              //console.log("connected, trying to join");
              // sometimes idToken and roles are being sent empty. Why? A: because the token is not ready yet. How to fix? A: use the token from the response of acquireTokenSilent
              socket.current.emit('join', {
                idToken: userIdToken,
                roles: userRoles
              })
              socket.current.emit('getSavedChatHistories', username)
            })

            // socket.current.on('prompts', (data) => {
            //   setPrompts(data);
            // });

            socket.current.on('about-me', (data) => {
              setAboutMe(data)
              // add a defaultValue
            })

            // socket.current.on('prompt', (data) => {
            //   setPrompt(data.message.content);
            //   setPromptName(data.promptName);
            // });

            socket.current.on("display-chat-history", (data) => {
              // destringify the data
              data = JSON.parse(data)
              setSavedChats(data)
            })

            // listen for incoming messages from the server
            socket.current.on("disconnect", () => {
              setConnected(false);
              //instance.logoutRedirect({postLogoutRedirectUri: "/"});
              instance.acquireTokenSilent({
                ...webAPIRequest,
                account: accounts[0]
              })
            })

            socket.current.on('message', (data) => {
              const newMessages = [
                ...myMessagesRef.current,
                { type: 'user', text: data.message, messageId: data.messageId }
              ]
              setMessages(newMessages)
              setLastMessageType('message')
              debouncedScrollToBottom()
            })

            socket.current.on('toast-success', (data) => {
              toast.success(data.message)
              //fire(); // fire the confetti
            })

            socket.current.on('toast-error', (data) => {
              toast.error(data.message)
            })

            socket.current.on('toast-info', (data) => {
              toast.info(data.message)
            })

            socket.current.on('website-screenshot', (data) => {
              const newImages = [...images, data.screenshot]
              setImages(newImages)
            })

            socket.current.on('tokens', (data) => {
              setTokenCount(data)
            })

            socket.current.on('web-loading-start', (data) => {
              // replace "<<<loading>>>" with a loading animation
              setIsLoading(true)
            })

            socket.current.on('web-loading-stop', (data) => {
              setIsLoading(false)
            })

            socket.current.on('ai-message-chunk', (data) => {
              setIsNewChat(false)
              // if the last message received was an "ai-message-chunk" then append the new chunk to the last message
              if (myLastMessageTypeRef.current !== 'ai-message-chunk') {
                // first chunk
                const newMessages = [
                  ...myMessagesRef.current,
                  {
                    type: 'ai',
                    text: data.message,
                    messageId: data.messageId,
                    feedback: data.feedback
                  }
                ]
                setMessages(newMessages)
              } else {
                const allmessages = [...myMessagesRef.current]
                const lastMessage = allmessages.pop()
                if (lastMessage?.messageId < data.messageId) {
                  // messageId negative means it is a tool execution message; since AI generated messages and tools can be mixed together, we need to consider the largest AI-generated number
                  lastMessage.messageId = data.messageId
                }
                if (lastMessage && lastMessage.text) {
                  lastMessage.text += data.message
                  allmessages.push(lastMessage)
                } else {
                  // lastMessage or .text is undefined. lastMessage
                }
                setMessages(allmessages)
              }
              setLastMessageType('ai-message-chunk')
              debouncedScrollToBottom()
            })

            socket.current.on('slash-command-response', (data) => {
              const toastType = data.type || toast.TYPE.SUCCESS

              if (toastType == toast.TYPE.ERROR) {
                toast.error(data.message)
              } else {
                toast.success(data.message)
                fire()
              }
            })

            // socket.current.on("knowledge-metadata", (data) => {
            //   setMemoryFiles(data);
            // });

            socket.current.on('speak-response', (data) => {
              const audioBlob = new Blob([data], { type: 'audio/mp3' })
              const reader = new FileReader()
              reader.onload = () => {
                const audioUrl = reader.result
                const audio = new Audio(audioUrl)
                audio.play()
              }
              reader.readAsDataURL(audioBlob)
            })

            socket.current.on('transcribed-message', ({ messageText }) => {
              handleUserMessage(messageText, 'message')
            })

            socket.current.on('accepted-terms-value', (data) => {
              // check if data.acceptedTerms is a string, then try converting it to boolen
              if (typeof data.acceptedTerms === 'string') {
                data.acceptedTerms = data.acceptedTerms.toLowerCase() === 'true'
              }
              setAcceptedTerms(data.acceptedTerms)
            })

            // Listen for the pptxBuffer event from the server-side
            socket.current.on("pptx-buffer", (data) => {
              // Create a Blob from the buffer
              const blob = new Blob([data.buffer], {
                type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
              })

              // Create a URL for the Blob
              const url = URL.createObjectURL(blob)

              // Create a link element to download the pptx file
              const link = document.createElement('a')
              link.href = url
              link.download = `${data.filename}.pptx`

              // Simulate a click on the link to trigger the download
              link.click()

              // Clean up the URL object
              URL.revokeObjectURL(url)
            })

            // Add a listener for the "activeProducts" event
            socket.current.on("active-products", (data) => {
              setActiveProducts(new Set(data));
            });

            socket.current.on('stop-messages-confirmed', () => {
              setIsStreaming(false)
            })

            socket.current.on('end-of-messages', () => {
              setIsStreaming(false)
            })

            // socket.current.on("assistant-kb-metadata", (data) => {
            //   setAssistantKBMetadata(data);
            // });

            // socket.current.on("delete-knowledge-file-success", (filename) => {
            //   // create a copy of assistantKBMetadata and remove the deleted file
            //   const newKBMetadata = [...assistantKBMetadata];
            //   const index = newKBMetadata.findIndex(file => file.filename === filename);
            //   if (index === -1) {
            //     return;
            //   }
            //   newKBMetadata.splice(index, 1);
            //   //sort by the Category field
            //   newKBMetadata.sort((a, b) => {
            //     if (a.Category === b.Category) {
            //       return a.originalFilename.localeCompare(b.originalFilename);
            //     }
            //     return a.Category.localeCompare(b.Category);
            //   });

            //   setAssistantKBMetadata(newKBMetadata);

            // });

            socket.current.on('stats', (data) => {
              setStats(data)
            })

            // socket.current.on("assistant-kb-file-chunks-count", ({filename,count}) => {
            //   setSelectedFileChunksCount(count);
            // });

            socket.current.on(
              'sharepoint-files',
              ({ filesAndFolderChain, tenantName, sitePath }) => {
                setSharepointFilesAndFolderChain(filesAndFolderChain)
                setSharepointTenantName(tenantName)
                setSharepointSitePath(sitePath)
              }
            )

            socket.current.on('open-sharepoint-file-url', ({ fileUrl }) => {
              // fetch the URL using axios
              window.open(fileUrl, '_blank')
              // setSidePanelFileUrl(fileUrl);
            })

            socket.current.on(
              'sharepoint-saved-ingester-plans-list',
              (data) => {
                setSharepointIngesterPlansList(data)
              }
            )

            socket.current.on(
              'sharepoint-ingester-plan',
              ({ ingesterPlan }) => {
                console.log('sharepoint-ingester-plan received', ingesterPlan)
                setSharepointFilesAndFolderChain(
                  ingesterPlan.filesAndFolderChain
                )
                setSharepointIngesterPlan(ingesterPlan)
                setSharepointTenantName(ingesterPlan.sharepointTenantName)
                setSharepointSitePath(ingesterPlan.sharepointSitePath)
                //if sharepointIngesterPlansList is undefined or empty, set it to [ingesterPlan.name]
                if (
                  !sharepointIngesterPlansList ||
                  sharepointIngesterPlansList.length === 0
                ) {
                  setSharepointIngesterPlansList([ingesterPlan.name])
                } else if (
                  !sharepointIngesterPlansList.includes(ingesterPlan.name)
                ) {
                  setSharepointIngesterPlansList([
                    ...sharepointIngesterPlansList,
                    ingesterPlan.name
                  ])
                }
              }
            )

            socket.current.on('ai-assistants', ({ aiAssistants }) => {
              // sort the list of aiAssistants by name
              aiAssistants.sort((a, b) => a.name.localeCompare(b.name))

              // Código foi movido da AIAssistant pois estava entrando em loop por
              // está alterando um state que era recebido por parametro do componente.
              // TODO
              // Esse lista de assistantes já deveria vir do serviço sem as informações de 'apiKeys',
              // assim que possível migrar essa lógica para API
              for (let i = 0; i < aiAssistants.length; i++) {
                if (
                  !aiAssistants[i].isOwner &&
                  aiAssistants[i].shares?.apiKeys
                ) {
                  delete aiAssistants[i].shares.apiKeys
                }
              }

              setAiAssistants(aiAssistants);
              if (query &&  query.get("assistant")) {
                const tryAiAssistant = query.get("assistant");
                if (tryAiAssistant.length === 50) {
                  socket.current.emit('ai-assistant-select', {
                    assistantId: tryAiAssistant
                  })
                  setQuery(null)
                }
              }
            })

            socket.current.on('ai-assistant', ({ aiAssistant }) => {
              setAiAssistant(aiAssistant)
            })

            socket.current.on('user-roles', ({ userRoles }) => {
              // console.log("user-roles event received", userRoles);
              setUserRoles(userRoles)
            })

            socket.current.on(
              'marketplace-ai-assistants',
              ({ marketplaceAssistants }) => {
                // sort the list of aiAssistants by name
                // marketplaceAssistants.sort((a, b) => a.name.localeCompare(b.name));
                setMarketplaceAssistants(marketplaceAssistants)
              }
            )

            socket.current.on(
              'marketplace-active-ai-assistants',
              ({ activeAssistantIds }) => {
                console.log('marketplace-active-ai-assistants event received')
                setAiSubscribedMarketplaceAssistants(activeAssistantIds)
                // update AiAssistants with the marketplaceAssistants that are found in activeAssistantIds
                const newAiAssistants = aiAssistants.map((assistant) => {
                  if (activeAssistantIds.includes(assistant.id)) {
                    assistant.marketplaceSubscribed = true
                  } else {
                    assistant.marketplaceSubscribed = false
                  }
                  return assistant
                })
              }
            )

            socket.current.on('switch-to-ai-assistant', ({ assistantId }) => {
              // readChat in the server sends this event for the client to switch to the AI assistant used in the saved chat.
              socket.current.emit('ai-assistant-select', { assistantId })
            })

            socket.current.on(
              'can-impersonate-test-user',
              ({ canImpersonateTestUser }) => {
                setCanImpersonateTestUser(canImpersonateTestUser)
                // send request to load chat history
                socket.current.emit('getSavedChatHistories', username)
                // send a New Chat event
                handleNewChat()
              }
            )

            socket.current.on('fireworks', () => {
              fire()
            })

            // // Claude3 code:
            // // const audioContext = new AudioContext();
            // const audioContext = new AudioContext(); // prioritize audio continuity over low latency
            // audioContextRef.current = audioContext;
            // nextTime.current = 0;

            // socket.current.on('audioChunk', ({chunk, index}) => {
            //   audioContextRef.current.decodeAudioData(chunk, (buffer) => {
            //     audioBufferQueueRef.current.push(buffer);
            //     // if (audioBufferQueueRef.current.length > MAX_BUFFER_SIZE) {
            //     //   audioBufferQueueRef.current.shift(); // Remove oldest buffer if queue gets too long
            //     // }
            //     // playBufferedAudio2();
            //   });
            // });

            // socket.current.on('audioEnd', () => {
            //   // audioSourceNodeRef.current.stop();
            //   // audioSourceNodeRef.current.disconnect();
            //   // nextTime.current = 0;
            //   playBufferedAudio2();

            // });

            // async function playBufferedAudio2() {

            //   while (audioBufferQueueRef.current.length > 0) {
            //     const buffer = audioBufferQueueRef.current.shift();
            //     const source = audioContextRef.current.createBufferSource();
            //     source.buffer = buffer;
            //     source.connect(audioContextRef.current.destination);
            //     if (nextTime.current === 0)
            //       nextTime.current = audioContextRef.current.currentTime + 2;  /// add 50ms latency to work well across systems - tune this if you like
            //     isPlayingRef.current = true;
            //     source.start(nextTime.current);
            //     nextTime.current += source.buffer.duration; // Make the next buffer wait the length of the last buffer before being played
            //     source.onended = function () {
            //       // disconnect the source
            //       // source.disconnect();
            //       // nextTime.current = 0;

            //     }
            //   };
            // }

            // const playBufferedAudio = () => {
            //   if (!isPlayingRef.current) {
            //     isPlayingRef.current = true;
            //     const audioSource = audioContext.createBufferSource();
            //     audioSourceNodeRef.current = audioSource;
            //     audioSource.buffer = audioBufferQueueRef.current.shift();
            //     audioSource.connect(audioContext.destination);
            //     audioSource.onended = () => {
            //       isPlayingRef.current = false;
            //       audioSourceNodeRef.current = null;
            //       playBufferedAudio();
            //       audioSource.disconnect();
            //     };
            //     audioSource.start();
            //   }
            // };

            // // stackoverflow fix for choppy audio

            // window.AudioContext = window.AudioContext || window.webkitAudioContext;
            // let context = new AudioContext();
            // let delayTime = 0;
            // let init = 0;
            // let audioStack = [];
            // let nextTime = 0;

            // socket.current.on('audioChunk', ({chunk, index}) => {
            //   context.decodeAudioData(chunk, (buffer) => {
            //     audioStack.push(buffer);
            //     if ((init!=0) || (audioStack.length > 10)) { // make sure we put at least 10 chunks in the buffer before starting
            //         init++;
            //         scheduleBuffers();
            //     }
            //   }, function(err) {
            //   });
            // });

            // function scheduleBuffers() {
            //   while ( audioStack.length) {
            //       const buffer = audioStack.shift();
            //       const source    = context.createBufferSource();
            //       source.buffer = buffer;
            //       source.connect(context.destination);
            //       if (nextTime == 0)
            //           nextTime = context.currentTime + 0.1;  /// add 50ms latency to work well across systems - tune this if you like
            //       source.start(nextTime);
            //       nextTime+=source.buffer.duration; // Make the next buffer wait the length of the last buffer before being played
            //       source.onended = function() {
            //         // disconnect the source
            //         source.disconnect();

            //       }

            //     };
            // }

            // const audioPlayer = audioPlayerRef.current.audio.current;

            // let audioChunks = [];
            // let totalByteLength = 0;

            // socket.current.on('audioChunk', (chunk) => {
            //   // Push the received chunk to the audioChunks array
            //   audioChunks.push(chunk);
            //   totalByteLength += chunk.byteLength;
            //   // each audioChunk is an ArrayBuffer
            //   // Check if we have enough chunks to create a Blob
            //   if (totalByteLength >= 4096) {
            //     // Concatenate all the chunks into one ArrayBuffer
            //     const concatenatedChunks = new Uint8Array(totalByteLength);
            //     let offset = 0;
            //     for (let i = 0; i < audioChunks.length; i++) {
            //       concatenatedChunks.set(new Uint8Array(audioChunks[i]), offset);
            //       offset += audioChunks[i].byteLength;
            //     }

            //     // const audioBlob = new Blob([data], { type: 'audio/mp3' });

            //     // Convert the concatenated chunks to a Blob object
            //     const blob = new Blob([concatenatedChunks], { type: 'audio/mp3' });

            //     // // Create a URL for the Blob object
            //     // const url = URL.createObjectURL(blob);

            //     // // Update the src of the audio player
            //     // audioPlayer.src = url;

            //     const reader = new FileReader();
            //     reader.onload = () => {
            //       const audioUrl = reader.result;
            //       const audio = new Audio(audioUrl); // what is this Audio() function? A: The Audio() function creates a new HTMLAudioElement object. It is used to play audio files in the browser.
            // is HTMLAudioElement the same as AudioContext? A: No, they are different. AudioContext is a built-in object in the Web Audio API. It is the base class for all audio nodes in the Web Audio API. It is used for creating, managing, and manipulating audio nodes and the audio routing graph. HTMLAudioElement is an object that represents an <audio> element in the DOM. It allows you to play audio files in the browser.
            // an audio routing graph is a collection of audio nodes connected together to process and route audio signals. Audio nodes are objects that represent audio sources, audio destinations, and audio processing modules. They can be connected together to create complex audio processing chains.
            // and audio node is an object that represents an audio source, an audio destination, or an audio processing module. Audio nodes can be connected together to create complex audio processing chains. There are several types of audio nodes, such as AudioBufferSourceNode, OscillatorNode, GainNode, DelayNode, and ConvolverNode.
            // an audio source is an object that represents an audio source in the Web Audio API. It can be used to play audio files, generate audio signals, or stream audio data from a network. Audio sources are connected to audio destinations using audio nodes to create audio processing chains.
            // an audio destination is an object that represents an audio output in the Web Audio API. It can be used to route audio signals to the speakers or headphones of the user's device. Audio destinations are connected to audio sources using audio nodes to create audio processing chains.
            // an audio processing module is an object that processes audio signals in the Web Audio API. It can be used to apply effects, filters, or other audio processing algorithms to audio data. Audio processing modules are connected to audio sources and destinations using audio nodes to create audio processing chains.
            // the Web Audio API is a powerful JavaScript API for creating, manipulating, and processing audio in the browser. It provides a set of audio nodes that can be connected together to create complex audio processing chains. The Web Audio API can be used to play audio files, generate audio signals, apply effects and filters to audio data, and create interactive audio applications.
            //       audio.play().then(() => {
            //         audioChunks = [];
            //         totalByteLength = 0;
            //       });
            //     };
            //     reader.readAsDataURL(blob);

            //     // Clear the audioChunks array for the next set of chunks
            //     // audioChunks = [];
            //     // totalByteLength = 0;
            //   }
            // });

            socket.current.on('sign-image-urls', (data) => {
              // data is [{match, replace}]
              // replace the image URLs in the messages
              const newMessages = myMessagesRef.current.map(message => {
                const newMessage = { ...message };
                data.forEach(({ match, replace }) => {
                  newMessage.text = newMessage.text.replace(match, replace)
                })
                return newMessage
              })
              setMessages(newMessages)
            })

            socket.current.on('knowledge-sources', (data) => {
              // setKnowledgeSources(data);
            })

            socket.current.on('set-ai-model', (data) => {
              setAIModel(data)
            })

            socket.current.on('user-photo', (data) => {
              // convert data from buffer to image blob
              const blob = new Blob([data], { type: 'image/png' })
              setUserPhoto(URL.createObjectURL(blob))
            })

            socket.current.on('ai-assistants-last10', (data) => {
              setLast10AssistantsArray(data.last10Assistants)
            })

            socket.current.on('welcome-screen-message', (data) => {
              setWelcomeScreenMessage(data.message)
            })

            return () => {
              socket.current.off('connect_error')
              socket.current.off('user')
              // socket.current.off("prompts");
              // socket.current.off("prompt");
              socket.current.off('website-screenshot')
              socket.current.off('tokens')
              socket.current.off('web-loading-start')
              socket.current.off('web-loading-stop')
              // socket.current.off("speak-response");
              socket.current.off('transcribed-message')
              socket.current.off('message')
              socket.current.off('ai-message-chunk')
              socket.current.off('connect')
              socket.current.off('disconnect')
              socket.current.off('toast-success')
              socket.current.off('toast-error')
              socket.current.off('toast-info')
              socket.current.off('slash-command-response')
              socket.current.off('display-chat-history')
              socket.current.off('accepted-terms-value')
              socket.current.off('pptx-buffer')
              socket.current.off('activeProducts')
              socket.current.off('stop-messages-confirmed')
              socket.current.off('end-of-messages')
              // socket.current.off("assistant-kb-metadata");
              // socket.current.off("delete-knowledge-file-success");
              socket.current.off('stats')
              // socket.current.off("assistant-kb-file-chunks-count");
              socket.current.off('sharepoint-files')
              socket.current.off('open-sharepoint-file-url')
              socket.current.off('sharepoint-ingester-plans-list')
              socket.current.off('sharepoint-ingester-plan')
              socket.current.off('ai-assistants')
              socket.current.off('user-roles')
              socket.current.off('marketplace-ai-assistants')
              socket.current.off('marketplace-active-ai-assistants')
              socket.current.off('can-impersonate-test-user')
              socket.current.off('fireworks')
              socket.current.off('switch-to-ai-assistant')
              // socket.current.off('audioChunk');
              socket.current.off('sign-image-urls')
              socket.current.off('knowledge-sources')
              socket.current.off('set-ai-model')
              socket.current.off('user-photo')
              socket.current.off('ai-assistants-last10')
              socket.current.off('welcome-screen-message')

              // URL.revokeObjectURL(audioPlayer.src);

              // audioContextRef.current.close();
              // if (audioSourceRef.current) {
              //   audioSourceRef.current.stop();
              // }
              socket.disconnect()

              // // stack overflow
              // context.close();
              // audioStack = [];
              // init = 0;
              // nextTime = 0;
            }
          })
          .catch(async (error) => {
            Sentry.captureException(error);
            await instance.loginRedirect()
          })
      })
    }
  }, [accounts]) // empty array as second argument to useEffect means only run once

  useEffect(() => {
    // whenever KM is opened, ask the server to send the latest knowledge metadata
    if (showKnowledgeManager === true) {
      socket.current.emit('get-assistant-kb-metadata')
    }
  }, [showKnowledgeManager])

  function handleChangePrompt(promptName) {
    socket.current.emit('prompt', { promptName });
  }

  async function handleUserMessage(message, type, commandJSON, imageUrlArray) {
    setIsNewChat(false);
    if (type === 'command') {
      // set the default value if CommandJSON.name === '/about-me'
      if (commandJSON.name === '/about-me') {
        commandJSON.parameters[0].value = aboutMe
      }
      if (commandJSON.parameters) {
        // show the popup to ask for the command parameters
        setShowCommandsPopup(true)
        setCommandJSON(commandJSON)
        // Done! the emit to the server will be handled in another handler, after the form popup is submitted
      } else {
        socket.current.emit('slash-command', { commandJSON, data: null })
      }
    } else {
      // no pop up needed, just send the command to the server
      setCommandJSON(null)
      setIsStreaming(true)

      if (imageUrlArray !== undefined && imageUrlArray.length > 0) {
        // each imageUrl has an url key that points to a blob. I need to transmit the content of these blobs to the server
        
        let imageBlobs = [];
        let imageUris = [];
        
        for (const imageUrl of imageUrlArray) {
          const res = await fetch(imageUrl.url)
          const blob = await res.blob()
          const uri = await new Promise((resolve) => {
            Resizer.imageFileResizer(
              blob, // The blob
              4000, // maxWidth
              4000, // maxHeight
              'PNG', // format
              100, // quality
              0, // rotation
              (resizedUri) => {
                resolve(resizedUri)
              },
              'base64' // outputType
            )
          })
          imageUris.push(uri)
        }
        const promises = imageUris.map(async imageUri => {
          const response = await fetch(imageUri);
          let blob = await response.blob();
          imageBlobs.push({blob, type: 'image/png'}); 
        });
        Promise.all(promises).then(() => {
          socket.current.emit("message", { message: message, imageBlobs });
        });
        
      } else {
        socket.current.emit('message', { message: message })
      }
    }
  }

  // /*----------------------------------*/
  // /* slash-command upload file button PROVAVELMENTE NÃO VOU USAR */
  // /*----------------------------------*/
  // function uploadFile(file) {
  //   return new Promise((resolve, reject) => {

  //       // validations
  //       const allowedTypes = ['application/pdf', 'text/plain'];
  //       if (!allowedTypes.includes(file.type)) {
  //           return(`<p>The file type is not allowed.</p>`);
  //       }
  //       const threshold = 70 * 1024 * 1024; // 1MB
  //       if (file.size > threshold) {
  //           return(`<p>The file size is too large.</p>`);
  //       }

  //       // send the file to the server
  //       const formData = new FormData();
  //       formData.append("file", file);
  //       try {
  //           const xhr = new XMLHttpRequest();
  //           xhr.open("POST", process.env.REACT_APP_API_URL + "/upload");
  //           xhr.send(formData);

  //           // Handle the response
  //           xhr.onload = function () {
  //               if (xhr.status === 200) {

  //               } else {
  //                   alert("File upload failed.");
  //               }
  //           };
  //           resolve();
  //       } catch (error) {
  //           reject(error);
  //       }
  //   });
  // }

  // // Handle starting the audio context on user interaction,
  // // as some browsers require user action to start audio playback.
  // const startAudioContext = async () => {
  //   if (audioContextRef.current && audioContextRef.current.state !== 'running') {
  //     await audioContextRef.current.resume();

  //     // Play any queued chunks
  //     while (queue.current.length > 0) {
  //       const chunk = queue.current.shift();
  //       const audioBuffer = await audioContextRef.current.decodeAudioData(chunk);
  //       const source = audioContextRef.current.createBufferSource();
  //       source.buffer = audioBuffer;
  //       source.connect(audioContextRef.current.destination);
  //       source.start();
  //     }
  //   }
  // };

  // Generate a unique file name
  function generateFileName(originalName) {
    const timestamp = Date.now()
    const randomString = Math.random().toString(36).substring(2, 8)
    //sanitize the original name to avoid hacking. Limit the name to 20 characters
    const extension = originalName.split('.').pop()
    let fileWithoutExtension = originalName.substring(
      0,
      originalName.length - extension.length - 1
    )
    fileWithoutExtension = fileWithoutExtension
      .replace(/[^a-z0-9]/gi, '_')
      .toLowerCase()
    return `${timestamp}_${randomString}_${fileWithoutExtension}.${extension}`
  }

  function handleCommandPopupClick(data) {
    // handle the popup's submit button
    setShowCommandsPopup(false)
    const fileParameter = data.parameters.find(
      (param) => param.value instanceof File
    )
    if (fileParameter) {
      const formData = new FormData();
      formData.append('file', fileParameter.value); // hard coded for /import
      const newFileName = generateFileName(fileParameter.value.name);
      formData.append('newFileName', newFileName);
      toast.info("Upload started", { autoClose: 1000 });
      instance
        .acquireTokenSilent({
          ...webAPIRequest,
          account: accounts[0]
        })
        .then((response) => {
          axios
            .post(process.env.REACT_APP_API_URL + '/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${response.accessToken}`
              }
            })
            .then((response) => {
              // toast: see https://fkhadra.github.io/react-toastify/introduction
              if (response.status === 200) {
                toast.success(response.data, { autoClose: 1500 })
                // eliminate the file from data.parameters
                const newParameters = data.parameters.filter(
                  (param) => !(param.value instanceof File)
                )
                // add the new file name to the parameters
                newParameters.push({ name: 'filename', value: newFileName })
                newParameters.push({
                  name: 'originalFilename',
                  value: fileParameter.value.name
                })
                socket.current.emit('slash-command', {
                  commandJSON,
                  parameters: newParameters
                })
                return
                //socket.current.emit("slash-command", {commandJSON, newFileName,});
              }
            })
            .catch((error) => {
              toast.error(error?.response?.data, { autoClose: 1500 })
            })
        })
    } else {
      // no file to upload
      socket.current.emit('slash-command', {
        commandJSON,
        parameters: data.parameters
      })
    }
    setCommandJSON(null)
  }

  function commandFormKeyDownHandler(event) {
    // ESC
    if (event.keyCode === 27) {
      setShowCommandsPopup(false)
      setCommandJSON(null)
    }
  }

  function handleNewChat() {
    try {
      setIsNewChat(true);
      setMessages([]);
      setCommandJSON(null);
      setIsContentAtBottom(true);
      setAIModel(aiAssistant.defaultModel);
      if (socket.current.disconnected) {
        socket.current.connect()
      }
      socket.current.emit('new-chat', {})

      document.getElementById('message-input-textarea').focus()
    } catch (error) {
      // Handle the error here, e.g. display an error message or log the error
      if (!connected) { 
        // "Cannot handle new chat: not connected"
        return; 
      }
    }
  }

  function handleSavedChatClick(headerIndex, chatIndex) {
    try {
      setMessages([]);
      setIsContentAtBottom(true);
      // check if socket is disconnected
      if (socket.current.disconnected) {
        socket.current.connect()
      }
      socket.current.emit('readChatHistory', savedChats[headerIndex].chats[chatIndex].file)
      // if not smartphone screen, focus on the message input
      if (window.innerWidth >= 768) {
        document.getElementById('message-input-textarea').focus()
      }
    } catch (error) {
      // Handle the error here
      if (!connected) { 
        // "Cannot read chat from server: not connected
        return; 
      }
      console.error('An error occurred:', error);
      // Optionally, you can show an error message to the user or perform any other error handling actions
    }
  }

  const [darkMode, setDarkMode] = useState(true)
  function handleDarkModeToggle() {
    socket.current.emit('dark-mode', { darkMode: !darkMode })
    setDarkMode(!darkMode)
  }

  function handleDeleteSavedChat(headerIndex, chatIndex) {
    const header = savedChats[headerIndex];
    const chatToDelete = header.chats[chatIndex];
    socket.current.emit('deleteChatHistory', chatToDelete.file);
    const newSavedChats = [...savedChats];
    if (header.chats.length === 1) {
      // if this is the last chat in the header, delete the entire header
      newSavedChats.splice(headerIndex, 1)
    } else if (chatIndex === header.chats.length - 1) {
      // if this is the last chat in the header, splicing it will cause an error, so just pop it off
      header.chats.pop()
    } else {
      // otherwise, just delete the chat
      header.chats.splice(chatIndex, 1)
    }
    setSavedChats(newSavedChats)
  }

  function handleStarClick(index, favorite) {
    const { headerIndex, chatIndex } = index
    const newSavedChats = [...savedChats]
    newSavedChats[headerIndex].chats[chatIndex] = {
      ...newSavedChats[headerIndex].chats[chatIndex],
      favorite
    }
    const file = savedChats[headerIndex].chats[chatIndex].file
    if (socket.current.disconnected) {
      socket.current.connect()
    }
    socket.current.emit(favorite ? 'addFavorite' : 'removeFavorite', file)
  }

  function handleToggleGPTModel(model) {
    socket.current.emit('setAIModel', model)
    setAIModel(model)
  }

  function handleThumbsFeedback({ sentiment, comment, messageId }) {
    socket.current.emit('feedback', { sentiment, comment, messageId })
    const newMessages = messages.map((message) => {
      if (message.messageId === messageId) {
        return {
          ...message,
          feedback: { sentiment, comment }
        }
      }
      return message
    })

    setMessages(newMessages)
  }

  const refAnimationInstance = useRef(null)

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance
  }, [])

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current?.({
      ...opts,
      origin: { y: 0.7 },
      particleCount: Math.floor(200 * particleRatio)
    })
  }, [])

  function fire() {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    })

    makeShot(0.2, {
      spread: 60
    })

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    })
  }

  // const handleMemoryFileDelete = (file) => {
  //   // exclude file
  //   const files = memoryFiles.filter(f => f.filename !== file[0].filename);
  //   setMemoryFiles(files);
  //   socket.current.emit('delete-knowledge-file', file[0].originalFilename);

  // }

  const handleSpeak = (text) => {
    let synthText = text.replace(/`/g, '')
    // eliminate hyperlinks
    synthText = synthText.replace(/\[.*?\]\(.*?\)/g, '')
    // replace TD SYNNEX with td synnex
    synthText = synthText.replace(/TD SYNNEX/g, 'td synnex')
    const limit = 5000
    if (synthText.length > limit) {
      toast.error('text too long for speech')
      synthText = synthText.slice(0, limit)
    }
    socket.current.emit('speak', synthText)
  }

  const handleAudioSave = (audioForm) => {
    // read the access token from the session

    instance
      .acquireTokenSilent({
        ...webAPIRequest,
        account: accounts[0]
      })
      .then((response) => {
        //get the access token from the session
        axios
          .post(process.env.REACT_APP_API_URL + '/upload-voice', audioForm, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${response.accessToken}`
            }
          })
          .then((response) => {
            // toast: see https://fkhadra.github.io/react-toastify/introduction
            if (response.status === 200) {
              //toast.success(response.data, {autoClose: 1500});
              socket.current.emit("voice-message", { filename: audioForm.get('file').name });
              return;
              //socket.current.emit("slash-command", {commandJSON, newFileName,});
            }
            toast.error(response.data, { autoClose: 1500 })
          })
      })
  }

  function handleConversationStarterClick(conversationStarterObject) {
    if (conversationStarterObject.promptName) {
      handleChangePrompt(conversationStarterObject.promptName)
    }
    if (conversationStarterObject.model) {
      handleToggleGPTModel(conversationStarterObject.model)
      // socket.current.emit('setAIModel', conversationStarterObject.model)
      // setAIModel(conversationStarterObject.model);
    }

    handleUserMessage(conversationStarterObject.text)
  }

  function handleTermsOfUseClick() {
    socket.current.emit('terms-of-use-accepted');
    setAcceptedTerms(true);

    // timeout added because the tour is not displayed if the state is set immediately
    setTimeout(() => setTourResetTrigger(true), 2000);
  }

  function handleClickMarketplace() {
    setShowMarketPlace(true)
    socket.current.emit('get-marketplace-ai-assistants')
  }

  function handleProductPurchase(productId, isPurchase) {
    socket.current.emit('purchase-product', { productId, isPurchase });
  }

  function handleClickPromptStudio() {
    setShowPromptStudio(!showPromptStudio)
  }

  function handleStopMessages() {
    socket.current.emit('stop-messages')
  }

  // mock KnowledgeManager data, for testing only
  const files = [
    {
      Category: 'rh-brasil',
      Subject: 'politica recrutamento',
      LearnMoreLinks: 'https://tdsynnex.com',
      filename:
        '1701398855901_69bonq_politica_recrutamento_e_selec_a_o_brasil___revisada_e_ok.docx',
      originalFilename:
        'Politica Recrutamento e Seleção Brasil - Revisada e ok.docx',
      username: 'T084031B@tdsynnex.com'
    },
    {
      Category: 'easyvista',
      Subject: 'ticket categories',
      filename: '1701441961085_jrykmd_catalog_export_ai.txt',
      originalFilename: 'Catalog Export AI.txt',
      username: 'T084031B@tdsynnex.com'
    },
    {
      Category: 'teste-pre-sales-cloud',
      Subject:
        'Este arquivo contém a lista de licenças Microsoft com informações sobre o part number, SKU e valor, além das descrições.',
      filename: '1702058277870_olz40a_licenciamento_ms.txt',
      originalFilename: 'Licenciamento MS.txt',
      username: 'T084031B@tdsynnex.com'
    },
    {
      Category: 'teste-pre-sales-windows-server-licensing-guide',
      Subject: 'Guia de licenciamento do Windows Server 2022',
      LearnMoreLinks:
        'https://www.microsoft.com/licensing/docs/view/Windows-Server',
      filename:
        '1702061240697_qgrwat_licensing_guide_plt_windows_server_2022_oct2022.pdf',
      originalFilename: 'Licensing_guide_PLT_Windows_Server_2022_Oct2022.pdf',
      username: 'T084031B@tdsynnex.com'
    }
  ]

  const deleteKnowledgeHandler = (file) => {
    // Handle delete file logic here
    socket.current.emit('delete-knowledge-file', file.filename);
  };

  const deleteOrphanedBlobHandler = (blob) => {
    // Handle delete orphaned blob logic here
  };

  const deleteOrphanedIndexesHandler = (indexes) => {
    // Handle delete orphaned indexes logic here
  };

  const handleClickKnowledgeManager = () => {
    setShowKnowledgeManager(true)
  }

  const handleScrollToBottom = () => {
    scrollToBottom(true)
  }

  const onScrollHandler = (isContentAtBottom) => {
    setIsContentAtBottom(isContentAtBottom)
  }

  const handleFilenameChunksCount = (filename) => {
    socket.current.emit('get-assistant-kb-file-chunks-count', { filename });
  }

  const handleSharepointBrowse = (url) => {
    socket.current.emit('sharepoint-browse', { url })
  }

  const handleSharepointFileAction = (action, file) => {
    socket.current.emit('sharepoint-file-action', { action, file });
  }

  const onSaveSpIngestionPlan = (action, ingestionPlan) => {
    if (action === 'save') {
      socket.current.emit('save-sharepoint-ingester-plan', {
        ingestionPlan: ingestionPlan
      })
    } else if (action === 'delete') {
      socket.current.emit('delete-sharepoint-ingester-plan', {
        ingestionPlanName: ingestionPlan
      })
    }
  }

  const onOpenSpIngestionPlan = (ingestionPlanName) => {
    socket.current.emit('open-sharepoint-ingester-plan', {
      ingestionPlanName: ingestionPlanName
    })
  }

  const onGetSavedSpIngestionPlans = () => {
    socket.current.emit('get-sharepoint-saved-ingester-plans-list');
  }

  const onOpenSharepointDataIngester = (ingesterPlan) => {
    socket.current.emit('get-sharepoint-data-ingester-delta', { planName: ingesterPlan.name });
  }

  const handleClickAiAssistant = () => {
    setShowAiAssistant(!showAiAssistant)
  }

  const handleChangeAiAssistant = ({ action, assistant }) => {
    // action may be add, update, delete
    socket.current.emit('ai-assistant-action', { action, assistant });
  }

  const handleAiAssistantUploadButton = (command, assistantId) => {
    socket.current.emit('ai-assistant-select', { assistantId })
    setCommandJSON(command)
  }

  const handleAiAssistantSelect = (assistant) => { // user selected an Assistant in Panel.js
    socket.current.emit('ai-assistant-select', { assistantId: assistant.id });
    try {
      document.getElementById('message-input-textarea').focus()
    } catch (error) {
      // do nothing if error
    }
  }

  const handleMarketplaceAssistantSubscribe = (assistantId, subscribed) => {
    if (subscribed) {
      socket.current.emit('activate-marketplace-assistant', { assistantId })
      // add an element to the array (treat as set)
      setAiSubscribedMarketplaceAssistants(
        aiSubscribedMarketplaceAssistants.concat(assistantId)
      )
    } else {
      socket.current.emit('deactivate-marketplace-assistant', { assistantId })
      //remove an element from the array
      setAiSubscribedMarketplaceAssistants(
        aiSubscribedMarketplaceAssistants.filter((id) => id !== assistantId)
      )
    }
  }

  const handleImpersonateTestUser = () => {
    // must have role "ImpersonateTestUser": true in the user profile
    setUserPhoto(null);
    socket.current.emit('toggle-impersonate-test-user');
  }

  const handleAzureDevOpsUserPAT = (pat) => {
    socket.current.emit('azure-devops-pat', { pat })
  }

  const handleAudioPlaybackStatus = (speaking) => {
    setIsAISpeaking(speaking);
  } 

  const handleVoiceModeClick = () => {
    if (voiceMode === 'continuous') {
      setVoiceMode('normal')
    } else {
      setVoiceMode('continuous')
    }
  }

  const handleCloseAssistant = () => {
    setShowAiAssistant(false)
    socket.current.emit('ai-assistant-select', { assistantId: aiAssistant.id }) // force a reload of the assistant on the server
  }

  const handleUserMessageDelete = (message) => {
    // opens the confirmation popup
    if (message?.messageId === undefined || message?.messageId < 3 ) { return }
    setMessageToDelete(message);
    setShowDeleteMessageConfirmPopup(true); // show confirmation popup
  }

  const confirmDeleteMessage = () => {
    // locate the message in the messages array and remove it and all subsequent messages
    const messageIndex = messages.findIndex(
      (msg) => msg.messageId === messageToDelete.messageId
    )
    const newMessages = messages.slice(0, messageIndex)
    setMessages(newMessages)
    socket.current.emit('delete-message-confirmed', {
      messageId: messageToDelete.messageId
    })
    setShowDeleteMessageConfirmPopup(false)
  }

  return (
    <ErrorBoundary>
      <PageLayout>
        <div className="App">
          <AuthenticatedTemplate>
            {/* <AudioPlayer ref={audioPlayerRef} autoPlay controls /> */}
            <TermsOfUse
              userFullName={`${userFirstName} ${userLastName}`}
              onTermsOfUseClick={handleTermsOfUseClick}
              acceptedTerms={acceptedTerms}
            />

            <header className={`App-header ${darkMode ? 'dark-mode' : ''}`}>
              {!connected && (
                <div
                  className={`connecting-message-bar ${
                    connected ? '' : 'visible'
                  }`}
                >
                  Connecting...
                </div>
              )}

              {/* {connected && showKnowledgeManager &&
                  <div>
                    <h1>Knowledge Manager</h1>
                    <KnowledgeManager
                      files={assistantKBMetadata}
                      selectedFileChunksCount={selectedFileChunksCount}
                      deleteKnowledgeHandler={deleteKnowledgeHandler}
                      deleteOrphanedBlobHandler={deleteOrphanedBlobHandler}
                      deleteOrphanedIndexesHandler={deleteOrphanedIndexesHandler}
                      handleClose={() => setShowKnowledgeManager(false)}
                      handleFilenameChunksCount={handleFilenameChunksCount}
                      onBrowseSharepoint={handleSharepointBrowse}
                      sharepointFilesAndFolderChain={sharepointFilesAndFolderChain}
                      sharepointTenantName={sharepointTenantName}
                      sharepointSitePath={sharepointSitePath}
                      onFileAction={handleSharepointFileAction}
                      onSaveSpIngestionPlan={onSaveSpIngestionPlan}
                      onOpenSpIngestionPlan={onOpenSpIngestionPlan}
                      onGetSavedSpIngestionPlans={onGetSavedSpIngestionPlans}
                      sharepointIngesterPlan={sharepointIngesterPlan}
                      sharepointIngesterPlansList={sharepointIngesterPlansList}
                    />
                    <ToastContainer
                      position="bottom-right"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="colored"
                    />
                  </div>
                } */}
              {showAiAssistant && (
                <AiAssistant
                  onClose={handleCloseAssistant}
                  aiAssistantsList={aiAssistants}
                  activeAiAssistant={aiAssistant}
                  socket={socket.current}
                  onChangeAssistant={handleChangeAiAssistant}
                  onClickUploadButton={handleAiAssistantUploadButton}
                  userRoles={userRoles}
                  // sharepoint stuff
                  onBrowseSharepoint={handleSharepointBrowse}
                  filesAndFolderChain={sharepointFilesAndFolderChain}
                  sharepointTenantName={sharepointTenantName}
                  sharepointSitePath={sharepointSitePath}
                  onFileAction={handleSharepointFileAction}
                  onSaveSpIngestionPlan={onSaveSpIngestionPlan}
                  onOpenSpIngestionPlan={onOpenSpIngestionPlan}
                  onGetSavedSpIngestionPlans={onGetSavedSpIngestionPlans}
                  ingesterPlan={sharepointIngesterPlan}
                  ingesterPlansList={sharepointIngesterPlansList}
                />
              )}
              {showAiAssistant && commandJSON && (
                <CommandsForm
                  commandJSON={commandJSON}
                  commandParametersHandler={handleCommandPopupClick}
                  commandFormKeyDownHandler={commandFormKeyDownHandler}
                  prompts={prompts}
                  aboutMe={aboutMe}
                />
              )}

              {!showAiAssistant && (
                <>
                  {showMarketPlace && (
                    <Marketplace
                      onClose={() => setShowMarketPlace(false)}
                      onProductBuy={handleProductPurchase}
                      activeProducts={activeProducts}
                      marketplaceAssistants={marketplaceAssistants}
                      activeAssistants={aiSubscribedMarketplaceAssistants}
                      onAssistantSubscribe={handleMarketplaceAssistantSubscribe}
                    />
                  )}
                  {!showMarketPlace && !showKnowledgeManager && (
                    <>
                        <Tour 
                          triggerTourReset={tourResetTrigger} 
                          handleTourReset={handleTourReset}
                          handleTourDontShowAgain={handleTourDontShowAgain}
                        />
                      {true && (
                        <ActiveChatPanel
                          commandJSON={commandJSON}
                          commandParametersHandler={handleCommandPopupClick}
                          commandFormKeyDownHandler={commandFormKeyDownHandler}
                          darkMode={darkMode}
                          onClick={handleDarkModeToggle}
                          isNewChat={isNewChat}
                          onMessage={handleUserMessage}
                          messages={messages}
                          onToggleHandler={handleToggleGPTModel}
                          onThumbsFeedback={handleThumbsFeedback}
                          prompts={prompts}
                          promptName={promptName}
                          isLoading={isLoading}
                          handleSpeak={handleSpeak}
                          onSaveAudio={handleAudioSave}
                          onChangePrompt={handleChangePrompt}
                          aboutMe={aboutMe}
                          onConversationStarterClick={
                            handleConversationStarterClick
                          }
                          onClickMarketplace={handleClickMarketplace}
                          connected={connected}
                          activeProducts={activeProducts}
                          onClickPromptStudio={handleClickPromptStudio}
                          isStreaming={isStreaming}
                          onStopMessages={handleStopMessages}
                          showScrollToBottomButton={!isContentAtBottom}
                          onScrollToBottomClick={handleScrollToBottom}
                          onScrollHandler={onScrollHandler}
                          onClickKnowledgeManager={handleClickKnowledgeManager}
                          onClickAiAssistant={handleClickAiAssistant}
                          conversationStarters={
                            aiAssistant?.conversationStarters
                          }
                          defaultModel={aiAssistant?.defaultModel}
                          aiAssistant={aiAssistant}
                          aiAssistants={aiAssistants}
                          onAiAssistantSelect={handleAiAssistantSelect}
                          last10AssistantsArray={last10AssistantsArray}
                          userPhoto={userPhoto}
                          firstName={userFirstName}
                          lastName={userLastName}
                          welcomeScreenMessage={welcomeScreenMessage}
                          setTourResetTrigger={setTourResetTrigger}
                          isAISpeaking={
                            voiceMode === 'normal' ? true : isAISpeaking
                          }
                          handleUserMessageDelete={handleUserMessageDelete}
                          handleNewChat={handleNewChat}
                          onSetHidden={(isPanelVisible) =>
                            setPanelVisibility(isPanelVisible)
                          }
                          panelVisibility={panelVisibility}
                        />
                      )}
                      {showPromptStudio && (
                        <PromptStudio
                          onClose={() => setShowPromptStudio(false)}
                          onTestPrompt={handleUserMessage}
                          onPromptChange={handleChangePrompt}
                          activeProducts={activeProducts}
                        />
                      )}
                      {/* {!showPromptStudio && */}
                      <Panel
                        darkMode={darkMode}
                        onNewChat={handleNewChat}
                        savedChats={savedChats}
                        onSavedChatClick={handleSavedChatClick}
                        onDeleteHandler={handleDeleteSavedChat}
                        onStarClick={handleStarClick}
                        username={
                          userFirstName
                            ? `${userFirstName} ${userLastName}`
                            : undefined
                        }
                        userPhoto={userPhoto}
                        canImpersonate={canImpersonateTestUser}
                        onImpersonate={handleImpersonateTestUser}
                        aiAssistant={aiAssistant}
                        aiAssistants={aiAssistants}
                        onAiAssistantSelect={handleAiAssistantSelect}
                        onAzureDevOpsUserPAT={handleAzureDevOpsUserPAT}
                        onSetHidden={(isPanelVisible) =>
                          setPanelVisibility(isPanelVisible)
                        }
                        panelVisibility={panelVisibility}
                      />
                      {/* } */}
                      {/* <RecordView onSaveAudio={handleAudioSave}/> */}
                    </>
                  )}
                </>
              )}
            </header>
            <ToastContainer
              position="bottom-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
              className="custom-toast"
            />
            <ReactCanvasConfetti
              refConfetti={getInstance}
              style={canvasStyles}
            />
            <MemoryAndMore
              AIModel={AIModel}
              tokenCount={tokenCount}
              prompt={prompt}
              userFirstName={userFirstName}
              onClickKnowledgeManager={handleClickKnowledgeManager}
              stats={stats}
            />
            {/* <Images images={images} onImageClick={handleImageClick}/> */}
            <AudioPlayer
              socket={socket.current}
              onAudioPlaybackStatus={handleAudioPlaybackStatus}
            />
            <Tooltip
              anchorSelect=".voice-mode-button"
              place="top"
              style={{ zIndex: 12 }}
            >
              Click to toggle the continuous voice chat mode<br></br>
              It will work best with assistants that use the `speak` tool,
              <br />
              Causing the Microphone to turn on immediately after the <br />
              AI stops speaking. Use the Mic button for finer control.
            </Tooltip>
            <div
              className={`voice-mode-button ${voiceMode}`}
              onClick={handleVoiceModeClick}
            >
              🎧
            </div>
            <Confirm
              content={
                'Are you sure? This action will delete the user message and all messages underneath it.'
              }
              open={showDeleteMessageConfirmPopup}
              onCancel={() => setShowDeleteMessageConfirmPopup(false)}
              onConfirm={confirmDeleteMessage}
            />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <header
              className={`App-header ${darkMode ? 'dark-mode' : ''}`}
            ></header>
          </UnauthenticatedTemplate>
        </div>
      </PageLayout>
    </ErrorBoundary>
  )
}

export default App
