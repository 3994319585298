import React, { useEffect, useState } from 'react'
import GenericModal from '../UI/GenericModal'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { Tooltip } from "react-tooltip"
import { v4 as uuidv4 } from "uuid"
import { toast } from 'react-toastify'
import { Input, Form, Button } from "semantic-ui-react"
import { getFutureDate } from '../../utils/Utils'
import api from '../../plugins/api'

const AddExternalAccessForm = ({ open, onClose, assistantData }) => {
  const futureDate = getFutureDate(6)
  const [tooltipContent, setTooltipContent] = useState("Copy to clipboard")
  const [tooltipColor, setTooltipColor] = useState("#000")

  const [isModalOpen, setModalOpen] = useState(open)
  const [externalAccess, setExternalAccess] = useState({
    apiKey: uuidv4(),
    expirationDate: futureDate,
    clientName: ""
  })

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  const handleCloseModal = () => {
    setModalOpen(false)

    if (onClose)
      onClose()
  }

  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setTooltipContent("Copied!")
        setTooltipColor("green")
        setTimeout(() => {
          setTooltipContent("Copy to clipboard")
          setTooltipColor("#000")
        }, 2000)
      })
      .catch((err) => {
        console.error("Failed to copy: ", err)
      })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setExternalAccess((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const submitHandler = (event) => {
    event.preventDefault()
    const body = {
      ...externalAccess,
      assistantId: assistantData.id
    }
    
    const isValid = Object.values(externalAccess).every(value => value.trim() !== '')

    if (!isValid) {
      toast.info("External access management requires that all fields must be filled")
      return
    }

    api.post(`v1/assistant/${assistantData.id}/apikey`, body)
      .then(() => {
        toast.success('External Access successfully created!')
        handleCloseModal()
      })
      .catch(error => toast.error(error.message))
  }

  return (
    <GenericModal
      open={isModalOpen}
      onClose={handleCloseModal}
      actions={[
        { label: 'Cancel', onClick: handleCloseModal },
        { label: 'Create', onClick: submitHandler, color: 'blue' }
      ]}
      title="Assistant API Key Management"
      size="small">
      <Form.Field>
        <label htmlFor="clientName" style={{ marginBottom: '1px', marginTop: '0' }}>Client Name</label>
        <Input
          type="text"
          id="clientName"
          name="clientName"
          value={externalAccess.clientName}
          onChange={handleChange}
          required
          style={{ border: '1px solid #ccc', width: '100%' }}
        />
      </Form.Field>

      <Form.Field>
        <label htmlFor="apiKey" style={{ marginBottom: '1px' }}>API Key</label>
        <Input
          type="text"
          id="apiKey"
          name="apiKey"
          value={externalAccess.apiKey}
          style={{ border: '1px solid #ccc', width: '92%' }} 
          action
        >
          <input />
          <Button
            type="button"
            onClick={() => handleCopyToClipboard(externalAccess.apiKey)}
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={tooltipContent}
            style={{ marginLeft: '0', border: '1px solid #ccc' }}
          >
            <Tooltip
              id="copy-tooltip"
              style={{ backgroundColor: tooltipColor }}
            />
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        </Input>
      </Form.Field>

      <Form.Field>
        <label htmlFor="expirationDate" style={{ marginBottom: '1px' }}>Expiration Date</label>
        <Input
          type="date"
          id="expirationDate"
          name="expirationDate"
          value={externalAccess.expirationDate}
          onChange={handleChange}
          required
          style={{ border: '1px solid #ccc', width: '100%' }}
        />
      </Form.Field>
    </GenericModal>
  )
}

export default AddExternalAccessForm
